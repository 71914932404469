import React, { Fragment } from 'react';
import { CFDefaultValueType, FIELD_TYPE_DATETIME, isObject } from '../../bf-hooks';
import {
  ContainerFieldItem,
  IBFProductVariable,
  ITariffDataStep,
  IProductInvoice,
  IBFInsuredObjectItem,
} from 'Services/widgets/interfaces';
import { ICFFactorType } from '../../factor-service';
import { ProductFieldTypeEntity } from 'Services/widgets/enums';
import { FactorElement } from './FactorElement';
import { BaseElement } from './BaseElement';
import { TooltipPopupCore } from '../ui-components/TooltipPopupCore';
import { JsonObject } from '@cover42/protobuf-util';
import { JSONItemUIType } from '../../booking-funnel/enum';
import { ProductPanel } from './ProductPanel';
import { HiddenField } from './fields/HiddenField';
import { EmploymentStatus } from './fields/EmploymentStatus';

export interface FieldPanelProps {
  formData: ITariffDataStep;
  factors: ICFFactorType[];
  insuredObjects: IBFInsuredObjectItem[];
  variable: IBFProductVariable;
  stepItem?: ContainerFieldItem;
  invoiceData?: IProductInvoice[];
  multiInsuredObjects?: IBFInsuredObjectItem[];
}

export const FieldPanel: React.FC<FieldPanelProps> = ( {
  formData, factors, insuredObjects, variable, stepItem, invoiceData, multiInsuredObjects,
} ) => {
  const [ showTooltip, setShowTooltip ] = React.useState<boolean>( false );
  const [ bfTooltip, setBfTooltip ] = React.useState<string>( '' );

  const getFieldValue = ( field: IBFProductVariable ): CFDefaultValueType => {
    if ( formData ) {
      const nameField = `${field.name}_${field.groupName ? field.groupName : field.insuredObjectId}`;
      return formData[nameField];
    }

    if ( variable.typeId === FIELD_TYPE_DATETIME && isObject( variable.defaultValue ) ) {
      const objectDate = variable.defaultValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    } else {
      return variable.defaultValue;
    }

    return '';
  };

  const onShowTooltip = React.useCallback ( ( isShow: boolean, tooltip: string ): void => {
    setShowTooltip( isShow );
    setBfTooltip( tooltip );
  }, [] );

  const isProductType: boolean = stepItem?.uiType === JSONItemUIType.ProductType;
  const isJobStatus: boolean = stepItem?.uiType === JSONItemUIType.JobStatusType;

  return (
    <Fragment>
      { !isProductType && variable.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
        <Fragment>
          { variable.isHidden ? (
            <HiddenField
              valueField={ getFieldValue( variable ) }
              variable={ variable }
            />
          ) : (
            <Fragment>
              { isJobStatus ? (
                <EmploymentStatus
                  valField={ getFieldValue( variable ) }
                  variable={ variable }
                  factor={ factors.find( ( f ) => f.id === variable.typeId )! }
                  showTooltip={ onShowTooltip }
                />
              ) : (
                <FactorElement
                  valField={ getFieldValue( variable ) }
                  variable={ variable }
                  factor={ factors.find( ( f ) => f.id === variable.typeId )! }
                  showTooltip={ onShowTooltip }
                  stepItem={ stepItem }
                />
              ) }
            </Fragment>
          ) }
        </Fragment>
      ) }
      { isProductType && variable.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
        <ProductPanel
          valField={ getFieldValue( variable ) }
          variable={ variable }
          factor={ factors.find( ( f ) => f.id === variable.typeId )! }
          showTooltip={ onShowTooltip }
          stepItem={ stepItem }
          invoiceData={ invoiceData }
          tariffData={ formData }
        />
      ) }
      { variable.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
        <BaseElement
          valField={ getFieldValue( variable ) }
          variable={ variable }
          showTooltip={ onShowTooltip }
          stepItem={ stepItem }
          formData={ formData }
          insuredObjects={ insuredObjects }
          multiInsuredObjects={ multiInsuredObjects }
        />
      ) }
      { showTooltip && bfTooltip && (
        <TooltipPopupCore
          tooltipInfo={ bfTooltip }
          onClose={ () => onShowTooltip( false, '' ) }
        />
      ) }
    </Fragment>
  );
};
