import React, { Fragment, ReactElement } from 'react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Card, Accordion } from 'react-bootstrap';
import { FieldType, PaymentMethodName, ProductFieldTypeEntity } from 'Services/widgets/enums';
import { JSONItemUIType } from 'App/components/widgets/booking-funnel/enum';
import { evalFunction } from 'App/components/widgets/booking-funnel/functions';
import { ContainerFieldItem, IBFProductVariable, ILeadData, SummaryLabel } from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { FIELD_TYPE_BOOL, FIELD_TYPE_DATETIME, FIELD_TYPE_NUMBER, isObject } from 'App/components/widgets/bf-hooks';
import { convertValueToNumber, currencyFormatter, defaultCurrency } from '../../../core-hooks';
import { defaultDateFormat } from 'App/ui-utils';
import { useLocale } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { paymentDataCoreKey } from '../../../CoreBookingFunnel';
import { StepInfoItem } from '../../../interfaces';
import { CoreLabel } from '../CoreLabel';
import { PositionLabel } from 'Services/widgets/enums';
import { CoreViewDynamicField } from './CoreViewDynamicField';

export interface CorePaymentInfoProps {
  isOpened: boolean;
  currentStep: StepInfoItem;
  productData: IDataFactorsAndVariables;
  lead: ILeadData;
  labels?: SummaryLabel[];
}

export const CorePaymentInfo: React.FC<CorePaymentInfoProps> = (
  { isOpened, currentStep, productData, lead, labels },
) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const locale = useLocale();
  const { factors, variables } = productData;
  const [ isCollapsed, setCollapsed ] = React.useState<boolean>( isOpened );
  const paymentInfo = lead[paymentDataCoreKey]!;

  const paymentDataStep = currentStep?.steps.find( ( item ) => item.name === paymentDataCoreKey );

  const collapseToggleHandler = React.useCallback( () => {
    setCollapsed( !isCollapsed );
  }, [ isCollapsed ] );

  const getValueFactorField = React.useCallback( (
    variable: IBFProductVariable, containerField: ContainerFieldItem,
  ): ReactElement | string => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = paymentInfo[fieldName];

    if ( selectedVal && containerField && containerField.uiType === JSONItemUIType.MultiSelect ) {
      const selectedValues: string[] = typeof selectedVal === 'string' ? JSON.parse( selectedVal ) : null;

      if ( selectedValues ) {
        const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;
        if ( findFactor ) {
          const factorList = findFactor?.values.filter( ( f ) => selectedValues.includes( String( f.key ) ) );

          return factorList.map( ( item ) => {
            return item.name;
          } ).join( ', ' );
        }
      }

      if ( typeof selectedVal === 'object' ) {
        return selectedVal.map( ( item ) => {
          return item.name;
        } ).join( ', ' );
      }
    }

    if ( isObject( selectedVal ) && selectedVal['name'] ) {
      return selectedVal['name'];
    }

    if ( selectedVal ) {
      const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;
      if ( findFactor ) {
        const factorInfo = findFactor?.values.find( ( f ) => f.key === selectedVal )!;
        return factorInfo?.name;
      }
    }

    return '-';
  }, [ factors, paymentInfo ] );

  const getValueBaseField = React.useCallback( (
    variable: IBFProductVariable, containerField: ContainerFieldItem,
  ) => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = paymentInfo[fieldName];

    if ( variable.typeId === FIELD_TYPE_BOOL && selectedVal ) {
      return selectedVal ? t( 'bookingFunnel.tariff.yes' ) : t( 'bookingFunnel.tariff.no' );
    }

    if ( variable.typeId === FIELD_TYPE_NUMBER && selectedVal ) {
      const isCurrency: boolean = containerField?.uiType === JSONItemUIType.Currency ? true : false;
      if ( isCurrency ) {
        const converValue = convertValueToNumber( selectedVal );
        return `${ currencyFormatter( converValue, defaultCurrency, locale, false ) } Euro`;
      }

      return selectedVal;
    }

    if ( variable.typeId === FIELD_TYPE_DATETIME && selectedVal ) {
      return dayjs( selectedVal ).format( defaultDateFormat );
    }

    const resVal: string = selectedVal ? selectedVal.toString() : '';
    return !isEmpty( resVal ) ? resVal : '-';
  }, [ locale, paymentInfo, t ] );

  const topLabel
    = labels?.find( ( label ) => label.stepName === paymentDataStep?.name && label.position === PositionLabel.Top );
  const bottomLabel
    = labels?.find( ( label ) => label.stepName === paymentDataStep?.name && label.position === PositionLabel.Bottom );

  return (
    <Fragment>
      { paymentInfo !== null && (
        <Accordion defaultActiveKey={ isOpened ? paymentDataStep?.name! : '' }>
          <Card className="p-0 m-0">
            <Accordion.Toggle
              className="py-2 px-3"
              as={ Card.Header }
              variant="link"
              eventKey={ paymentDataStep?.name! }
              onClick={ collapseToggleHandler }
            >
              <span className="align-middle bf-title-text">{ currentStep?.navigationTitle }</span>
              <i className={ `material-icons chevron align-middle${ isCollapsed ? ' bf-open' : ' bf-close' }` }>
                { isCollapsed ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }
              </i>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={ paymentDataStep?.name! }>
              <Card.Body className="px-3">
                { topLabel && (
                  <CoreLabel stepItem={ topLabel as unknown as ContainerFieldItem } className="" />
                ) }
                { paymentDataStep && paymentDataStep.containers.map( ( container, containerIdx ) => (
                  <Fragment key={ containerIdx }>
                    { evalFunction( lead || [], container.visibleCriteria ) && (
                      <Fragment>
                        { container.items && container.items.map( ( item, idx ) => (
                          <Fragment key={ idx }>
                            { evalFunction( lead || [], item.visibleCriteria ) && (
                              <Fragment>
                                { item.type && item.type === FieldType.PaymentSettings && (
                                  <Fragment>
                                    <div className="details-section mx-4">
                                      <p className="bf-selected-val f-14">
                                        { t( 'bookingFunnel.paymentData.paymentMethod' ) }
                                      </p>
                                      <p>{ paymentInfo?.paymentMethod === PaymentMethodName.Sepa ?
                                        t( 'bookingFunnel.paymentData.sepaMandate' ) :
                                        t( 'bookingFunnel.paymentData.bankTransfer' ) }
                                      </p>
                                    </div>
                                    { paymentInfo?.paymentMethod !== PaymentMethodName.Invoice && (
                                      <div className="details-section mx-4">
                                        <p className="bf-selected-val f-14">
                                          { t( 'bookingFunnel.paymentData.accountName' ) }
                                        </p>
                                        <p>
                                          { `${paymentInfo['accountFirstName']} ${paymentInfo['accountLastName']}` }
                                        </p>
                                      </div>
                                    ) }
                                    { paymentInfo['accountIban'] && (
                                      <div className="details-section mx-4">
                                        <p className="bf-selected-val f-14">
                                          { t( 'bookingFunnel.paymentData.accountIban' ) }
                                        </p>
                                        <p>
                                          { paymentInfo['accountIban'] }
                                        </p>
                                      </div>
                                    ) }
                                  </Fragment>
                                ) }
                                { item.type && item.type === FieldType.StateVariable && (
                                  <CoreViewDynamicField
                                    lead={ lead }
                                    stepItem={ item }
                                  />
                                ) }
                                { item.type && item.type === FieldType.Policy && variables.filter(
                                  ( v ) => `${v.name}_${v.groupName}` === `${item.fieldName}_${item.insuredObjectName}`
                                  && !v.isHidden,
                                )
                                  .map( ( variable, cIdx ) => (
                                    <Fragment key={ cIdx }>
                                      <div className="details-section mx-4">
                                        <p className="bf-selected-val f-14">{ variable.label }</p>
                                        { variable.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
                                          <p>{ getValueBaseField( variable, item ) }</p>
                                        ) }
                                        { variable.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
                                          <p>{ getValueFactorField( variable, item ) }</p>
                                        ) }
                                      </div>
                                    </Fragment>
                                  ) ) }
                              </Fragment>
                            ) }
                          </Fragment>
                        ) ) }
                      </Fragment>
                    ) }
                  </Fragment>
                ) ) }
                { bottomLabel && (
                  <CoreLabel stepItem={ bottomLabel as unknown as ContainerFieldItem } className="" />
                ) }
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ) }
    </Fragment>
  );
};
