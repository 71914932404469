import React, { Fragment } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem } from 'Services/widgets/interfaces';
import { AsteriskRequiredField } from 'App/components/widgets/AsteriskRequiredField';
import { Title } from 'Services/widgets/enums';
import { isCheckedByField, renderClassNameBox } from 'App/components/widgets/dynamic-core/core-hooks';

export interface TitleFieldProps {
  fieldValue: string;
  fieldName: string;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
}

export const TitleField: React.FC<TitleFieldProps> = ( { fieldValue, fieldName, stepItem, isDisabled } ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control, setValue } = useFormContext();

  const handleChange = ( e: React.ChangeEvent<HTMLSelectElement>, name: string ) => {
    const element = e.target as HTMLSelectElement;
    const value = element.value;

    setValue( name, value, { shouldValidate: true } );
  };

  const fieldLabel = React.useMemo<string>( () => {
    if ( stepItem && stepItem.label ) {
      return stepItem.label;
    }

    return t( 'bookingFunnel.funk.registration.nameTitleField' );
  }, [ stepItem, t ] );

  return (
    <Fragment>
      <Form.Label className="dynamic-main-label">
        { fieldLabel }
        {
          isCheckedByField( 'showAsterisk', stepItem! ) && isCheckedByField( 'isRequired', stepItem! ) &&
          ( <AsteriskRequiredField /> )
        }
      </Form.Label>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ { required: isCheckedByField( 'showAsterisk', stepItem! ) } }
        defaultValue={ fieldValue || '' }
        render={ ( props ) => (
          <FormGroup className={ `${renderClassNameBox( stepItem ) }` } controlId={ props.name }>
            <div
              className={ `${props.value ?
                'date-container' : 'date-container form-select-empty' }` }
            >
              <Form.Control
                as="select"
                className="form-select"
                value={ props.value }
                onChange={ ( e: React.ChangeEvent<HTMLSelectElement> ) => handleChange( e, props.name ) }
                placeholder={ t( 'bookingFunnel.funk.registration.nameTitleField' ) }
                isInvalid={ errors[props.name] !== undefined }
                disabled={ isDisabled }
              >
                { Object.keys( Title ).map( ( key, idx ) =>
                  <option
                    key={ idx }
                    //When value is empty i.e. Title.none, the value really stored is the innerHTML
                    value={ Title[key] }
                  >
                    { t( `bookingFunnel.personalDataFunk.honorifics.${key}` ) }
                  </option> ) }
              </Form.Control>
            </div>
            <Form.Control
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              <Fragment>
                { t( 'base:forms.messages.fieldRequired', { fieldLabel } ) }
              </Fragment>
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
