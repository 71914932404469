import React from 'react';
import AsyncPaginate from 'react-select-async-paginate';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { DropdownIndicatorTagForBg, NullIndicator } from 'App/components/utils/react-select/indicators';
import { useAppDefaultStyles } from 'Services/stylings';
import { useAppLogger } from 'Services/logger';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { ContainerFieldItem, ILeadData, ITariffDataStep } from 'Services/widgets/interfaces';
import {
  encodeFilterValue,
  evalInScope,
  FilterInput,
  FilterInputTypeEnum,
  parseInputs,
  parseMappings,
} from './list-hooks';
import { JsonObject, JsonValue } from '@cover42/protobuf-util';
import { useWidgetService } from 'Services/widget';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { PolicyEditData } from '../../../PolicyEdit';
import { TooltipCore } from '../TooltipCore';
import { blankLink } from 'config';
import { formatFormData, isCheckedByField, renderClassNameBox } from '../../../core-hooks';
import jsonpath from 'jsonpath';
import { Separators } from 'Services/widgets/enums';
import { isEmpty, pickBy } from 'lodash';
import { getFieldDiff } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';

export interface CoreFactorSelectProps {
  stepItem: ContainerFieldItem;
  leadData: ILeadData;
  formData: ITariffDataStep;
  productData: IDataFactorsAndVariables | PolicyEditData;
  isDisabled?: boolean;
}

type FactorOption = {
  [key: string]: JsonValue | JSX.Element;
};

interface EmptyOptions {
  options: never[];
  hasMore: boolean;
  additional: {
    page: number;
  };
}

const bgColorSelected = '#ECF5FA';

const emptyOptions: EmptyOptions = {
  options: [],
  hasMore: false,
  additional: {
    page: 1,
  },
};

export const CoreFactorSelect: React.FC<CoreFactorSelectProps> = (
  { stepItem, leadData, formData, productData, isDisabled },
) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const service = useWidgetService();
  const { formState, setValue, control, errors, watch, getValues } = useFormContext();
  const defaulStyles = useAppDefaultStyles();
  const logger = useAppLogger();
  const { showAlert, hideAlert } = useAppAlertService();
  const { factorName, input, filter, row: optionLabel, pageSize, mapping, tooltip } = stepItem;

  const [ showTooltip, setShowTooltip ] = React.useState<boolean>( false );
  const [ bfTooltip, setBfTooltip ] = React.useState<string>( '' );
  const [ targetLink, setTargetLink ] = React.useState<React.ReactInstance | undefined>( undefined );
  const [ isReloadOptions, setReloadOptions ] = React.useState<boolean>( false );
  const previousFormData = React.useRef<ITariffDataStep>( {} );

  const inputs: FilterInput[] = parseInputs( input ?? '' );
  const mappings = parseMappings( mapping ?? '' );
  const controlName = `${stepItem.id}${inputs[0] && inputs[0].columnName ? `-${inputs[0].columnName}`: ''}`;

  const getFieldsTypeVariable = React.useCallback( ( ): string[] => {
    const fields: string[] = [];

    for ( const { columnName, inputType } of inputs ) {
      if ( inputType === FilterInputTypeEnum.Var ) {
        const [ part1, part2 ] = columnName.split( '.' );
        const fieldName = ( part2 ? `${part2}_${part1}` : part1 );
        fields.push( fieldName );
      }
    }

    return fields;
  }, [ inputs ] );

  const onShowTooltip = React.useCallback ( (
    isShow: boolean, tooltipHtml: string, target?: React.ReactInstance,
  ): void => {
    setShowTooltip( isShow );
    setBfTooltip( tooltipHtml );
    if ( target ) {
      setTargetLink( target );
    }
  }, [] );

  const evalExpr = React.useCallback( ( template: string, ctx: object ): string => {
    const code = template;
    try {
      const s = evalInScope( code, ctx );

      return s;
    } catch ( e ) {
      logger.error( `Error evaluating [${code}] with context: ${e}` );

      return '';
    }
  }, [ logger ] );

  const getFactorValue = React.useCallback(
    ( factor?: FactorOption ): any => factor,
    [] );

  const getFactorLabel = React.useCallback(
    ( row: JsonObject | undefined ) => {
      if ( !row ) {
        return '';
      }

      const label = evalExpr( optionLabel!, { row } );

      return label;
    }, [ evalExpr, optionLabel ] );

  const formatFactorLabel = React.useCallback(
    ( row: JsonObject | undefined, searchQuery: string ) => {
      const label = getFactorLabel( row );

      if ( !searchQuery || !label ) {
        return label;
      }

      const parts: JSX.Element[] = [];
      let pos = 0;
      const searchLen = searchQuery.length;
      do {
        const index = label.toUpperCase().indexOf( searchQuery.toUpperCase(), pos );
        if ( index >= 0 ) {
          if ( index > 0 ) {
            parts.push( <span key={ `span-${pos}` }>{ label.substring( pos, index ) }</span> );
          }
          parts.push( <b key={ `b-${pos}` }>{ label.substring( index, index + searchLen ) }</b> );

          pos = index + searchLen;
        } else {
          parts.push( <span key={ `span-${pos}` }>{ label.substring( pos ) }</span> );
          break;
        }
      } while ( true );

      return (
        <React.Fragment>
          { parts }
        </React.Fragment>
      );
    }, [ getFactorLabel ] );

  const foundValueByVariable = React.useCallback( ( variable: string ): string => {
    const splitVariable = variable.split( Separators.Dot );

    if ( splitVariable && splitVariable.length > 1 ) {
      const pathToField = `${splitVariable[1]}_${splitVariable[0]}`;
      let storeValue = jsonpath.query( leadData || {}, `$..${pathToField}` )[0];

      if ( typeof storeValue === 'object' && storeValue.hasOwnProperty( 'key' ) ) {
        storeValue = storeValue.key;
      }

      return storeValue;
    }

    return '';
  }, [ leadData ] );

  const composeFilters = React.useCallback( ( map: object ): string => {
    const filterObj = { ...map };
    let filterScheme = filter!;

    for ( const { columnName, inputType } of inputs ) {
      if ( inputType === FilterInputTypeEnum.Var ) {
        const value = foundValueByVariable( columnName );
        const splitColumnName = columnName.split( Separators.Dot );

        if ( splitColumnName && splitColumnName.length > 1 ) {
          if ( !filterObj[splitColumnName[1]] ) {
            filterObj[splitColumnName[1]] = value || '';
          }
          filterScheme = filterScheme.replaceAll( `${splitColumnName[0]}.`, 'input.' );
        }
      } else {
        if ( !filterObj[columnName] ) {
          filterObj[columnName] = '';
        }
      }
    }

    return evalExpr( filterScheme, { input: filterObj } );
  }, [ evalExpr, filter, foundValueByVariable, inputs ] );

  const convertFactorToOption = React.useCallback(
    ( row: JsonObject, seachQuery: string ): FactorOption => ( {
      ...row,
      label: getFactorLabel( row ),
      labelExt: formatFactorLabel( row, seachQuery ),
    } ), [ formatFactorLabel, getFactorLabel ] );

  const initialValue = ( formData && formData[controlName] ? formData[controlName] : undefined );
  const defaultValue = ( initialValue ? convertFactorToOption( initialValue, '' ) : undefined );

  const [ selectedFactor, setSelectedFactor ] = React.useState<FactorOption | undefined>( defaultValue );

  const composeFormDataToFilterMap = React.useCallback( ( searchVal: string ): JsonObject => {
    const map = {};
    for ( const i of inputs ) {
      if ( !!searchVal ) {
        map[i.columnName] = encodeFilterValue( searchVal );
      }
    }

    return map;
  }, [ inputs ] );

  const loadOptions = React.useCallback( async ( searchQuery: string, loadedOptions: unknown[], { page } ) => {
    try {
      const query = ( searchQuery ?? '' ).trim();

      hideAlert();

      const filterMap = composeFormDataToFilterMap( query );
      const filters = composeFilters( filterMap );

      const productSlug = productData.productSlug!;
      const response = await service.filterNamedRange( {
        productSlug,
        name: factorName!,
        filters,
        pageToken: `${page}`,
        pageSize,
      } );

      const { items } = response;

      return {
        options: items ? items.map( ( i ) => convertFactorToOption( i, searchQuery ) ) : [],
        hasMore: items ? items.length >= 1 : false,
        additional: {
          page: page + 1,
        },
      };
    } catch ( e ) {
      logger.error( e );
      showAlert( {
        message: t( 'base:forms.messages.error' ),
        type: 'danger',
      } );

      return emptyOptions;
    }
  }, [ composeFilters, composeFormDataToFilterMap, convertFactorToOption, factorName,
    hideAlert, logger, pageSize, productData.productSlug, service, showAlert, t ] );

  const loadEmptyOptions = React.useCallback( async () => {
    return emptyOptions;
  }, [] );

  const setMappedFields = React.useCallback( ( row: FactorOption ): void => {
    if ( isDisabled ) {
      return;
    }

    for ( const { source, target } of mappings ) {
      const value = evalExpr( source, { row } );
      const [ part1, part2 ] = target.split( '.' );
      const fieldName = ( part2 ? `${part2}_${part1}` : part1 );

      setValue( fieldName, value ? value : undefined, { shouldValidate: true } );
    }
  }, [ evalExpr, isDisabled, mappings, setValue ] );

  const restoreControleValue = React.useCallback( (): void => {
    for ( const { target } of mappings ) {
      const currentControl = getValues( controlName );
      if ( currentControl ) {
        return;
      }

      const [ part1, part2 ] = target.split( '.' );
      const fieldName = ( part2 ? `${part2}_${part1}` : part1 );
      const factorValue = pickBy( watch( fieldName ), ( f ) => f !== undefined );

      if ( factorValue && typeof factorValue === 'object' && !isEmpty( factorValue ) ) {
        const factorItem = {
          ...factorValue,
          label: factorValue.name,
          labelExt: factorValue.name,
        } as FactorOption;

        setSelectedFactor( factorItem );
        setValue( controlName, factorItem, { shouldValidate: true } );
      }
    }
  }, [ controlName, getValues, mappings, setValue, watch ] );

  const handleSelect = React.useCallback( ( item: ValueType<FactorOption> ) => {
    const factor = item as FactorOption;

    setSelectedFactor( factor );
    setValue( controlName, factor, { shouldValidate: true } );
    setMappedFields( factor );
  }, [ controlName, setMappedFields, setValue ] );

  const customStyles = React.useMemo( () => {
    return {
      option: ( styles, { isFocused, isSelected } ) => {
        return {
          ...styles,
          backgroundColor: isFocused | isSelected ? bgColorSelected : null,
          color: defaulStyles.textColor,
        };
      },
    };
  }, [ defaulStyles.textColor ] );

  React.useEffect( () => {
    let isMounted = true;

    const refreshFormValues = async ( ) => {
      try {
        const variableFields = getFieldsTypeVariable();
        if ( variableFields.length === 0 ) {
          return;
        }

        const allFields = pickBy( watch( variableFields ), ( f ) => f !== undefined );
        const formValues = formatFormData( allFields );

        if ( isEmpty( previousFormData.current ) ) {
          previousFormData.current = formValues;
          // Needed small pause for rebuild data
          setTimeout( () => {
            restoreControleValue();
          }, 500 );
          return;
        }

        const changedField = getFieldDiff( formValues, previousFormData.current );

        if ( changedField && isMounted ) {
          previousFormData.current = formValues;

          if ( variableFields.length > 0 && variableFields.includes( changedField ) ) {
            setSelectedFactor( undefined );
            setValue( controlName, '', { shouldValidate: true } );
            setMappedFields( {} );
            setReloadOptions( true );

            setTimeout( () => {
              setReloadOptions( false );
            }, 500 );
          }
        }

      } catch( e ) {
        logger.error( `Error in data update: ${e}` );
      }
    };

    refreshFormValues();

    return () => {
      isMounted = false;
    };
  }, [ controlName, formState.isValidating, getFieldsTypeVariable, getValues, logger,
    restoreControleValue, setMappedFields, setValue, watch ] );

  return (
    <div>
      { inputs[0] && inputs[0].label && (
        <Form.Label id={ `${controlName}-label` } className={ tooltip ? 'tooltip-label' : '' }>
          { inputs[0].label }
          { tooltip && (
            <a
              className="tooltip-info"
              href={ blankLink }
              role='button'
              onClick={ ( e ) => {
                e.preventDefault();
                onShowTooltip( true, tooltip!, e.target as unknown as React.ReactInstance );
              } }
            >
              { t( 'bookingFunnel.tooltipHelp' ) }
            </a>
          ) }
        </Form.Label>
      ) }
      <Controller
        id={ controlName }
        name={ controlName }
        control={ control }
        rules={ { required: isDisabled ? false : isCheckedByField( 'isRequired', stepItem! ) } }
        defaultValue={ defaultValue || '' }
        render={ ( { name } ) => (
          <FormGroup
            controlId={ name }
            className={ `${ renderClassNameBox( stepItem ) }${errors[name] ? ' core-input-error' : '' }` }
          >
            { isReloadOptions && (
              <AsyncPaginate
                name={ name }
                id={ `${name}-search-empty-input` }
                className="select-values-list"
                classNamePrefix="select-item"
                placeholder={ t( 'selectValues.selectValue' ) }
                defaultValue={ undefined }
                value={ undefined }
                components={ {
                  DropdownIndicator: DropdownIndicatorTagForBg,
                  IndicatorSeparator: NullIndicator,
                } }
                loadOptions={ loadEmptyOptions }
                blurInputOnSelect={ true }
                isMulti={ false }
                isClearable={ true }
                isSearchable={ true }
                isLoading={ true }
                isDisabled={ true }
                noOptionsMessage={ () => t( 'base:noOptions' ) }
                loadingMessage={ () => t( 'bookingFunnel.sLoadingRecords' ) }
              />
            ) }
            { !isReloadOptions && (
              <AsyncPaginate
                name={ name }
                id={ `${name}-search-input` }
                className="select-values-list"
                classNamePrefix="select-item"
                placeholder={ t( 'selectValues.selectValue' ) }
                defaultValue={ selectedFactor }
                value={ selectedFactor }
                components={ {
                  DropdownIndicator: DropdownIndicatorTagForBg,
                  IndicatorSeparator: NullIndicator,
                } }
                cacheOptions
                loadOptions={ loadOptions }
                getOptionValue={ ( option ) => getFactorValue( option ) }
                formatOptionLabel={ ( option ) => option.labelExt }
                blurInputOnSelect={ true }
                isMulti={ false }
                isClearable={ true }
                isSearchable={ true }
                escapeClearsValue
                menuPlacement="bottom"
                onChange={ handleSelect }
                styles={ customStyles }
                maxMenuHeight={ 200 }
                isDisabled={ isDisabled }
                additional={ {
                  page: 1,
                } }
                noOptionsMessage={ () => t( 'base:noOptions' ) }
                loadingMessage={ () => t( 'bookingFunnel.sLoadingRecords' ) }
                debounceTimeout={ 500 }
              />
            ) }
            <Form.Control
              type="hidden"
              isInvalid={ errors[name] !== undefined }
            />
            { inputs[0] && inputs[0].label && (
              <FormControl.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired', {
                  fieldLabel: inputs[0].label,
                } ) }
              </FormControl.Feedback>
            ) }
          </FormGroup>
        ) }
      />
      { showTooltip && bfTooltip && (
        <TooltipCore
          tooltipInfo={ bfTooltip }
          onClose={ () => onShowTooltip( false, '' ) }
          targetLink={ targetLink }
        />
      ) }
    </div>
  );
};
