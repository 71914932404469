import React, { Fragment } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

export interface HiddenFieldProps {
  fieldValue: string;
  fieldName: string;
  isRequired?: boolean;
}

export const HiddenField: React.FC<HiddenFieldProps> = ( { fieldValue, fieldName, isRequired } ) => {
  const { control } = useFormContext();

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ { required: typeof isRequired === 'boolean' ? isRequired : false } }
        defaultValue={ fieldValue || '' }
        render={ ( props ) => (
          <FormGroup controlId={ props.name }>
            <Form.Control
              { ...props }
              type="hidden"
            />
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
