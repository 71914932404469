import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner, useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { useBookingFunnelActions, IDataFactorsAndVariables } from '../../BookingFunnel';
import {
  ILeadData,
  IPolicyObject,
  IPremiumCalculationStateGM,
  IProductDataForInvoice,
  ITariffDataStep,
  SubStepItem,
} from 'Services/widgets/interfaces';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useWidgetService } from 'Services/widget';
import leftArrowIconUrl from 'assets/bf-images/left-arrow.svg';
import { ViewTariffResult } from '../../ui/ViewTariffResult';
import { isEmpty } from 'lodash';
import {
  getFieldDiff,
  isEnabledPremiumCalculation,
  isIgnoreOnRecalculationField,
  getPremiumShowsType,
  prepareDataForTariff,
  prepareFormData,
  renderPrice,
  useLocale,
} from '../../booking-funnel-hooks';
import { FieldPanal } from '../../tariff-elements/FieldPanal';
import { BFStep } from 'App/components/widgets/booking-funnel/enum';
import { evalFunction } from '../../functions';
import { oneTimePayment } from 'App/components/widgets/bf-hooks';

export interface PolicySubStep2Props {
  stepItems: SubStepItem;
  isNotDataSubStep1: boolean;
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
}

export const keyPolicyFormDataStep2 = 'subStep2TariffData';

export const PolicySubStep2: React.FC<PolicySubStep2Props> = ( props ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const locale = useLocale();
  const { productCode, stepItems, lead, productData, isNotDataSubStep1 } = props;
  const { fieldsDefaultValues } = productData.configuration || {};
  const variables = productData.variables.filter( ( v ) => !v.isHidden );
  const policyFormData = lead[keyPolicyFormDataStep2];
  const actions = useBookingFunnelActions();
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const { showAlert } = useAppAlertService();
  const service = useWidgetService();
  const previousTariffData = React.useRef<ITariffDataStep>( {} );
  const [ isLoading, setLoading ] = React.useState<boolean>( false );
  const [ tariffData, setTariffData ] = React.useState<IPremiumCalculationStateGM | null>( null );
  const [ , setReloadTariff ] = React.useState<number>( dayjs().valueOf() );
  const [ leadData, setLeadData ] = React.useState<ILeadData>( lead );
  const enabledPremiumCalculation = isEnabledPremiumCalculation( stepItems );
  const premiumShowsType = getPremiumShowsType( stepItems );

  const formContext = useForm( {
    mode: 'onChange',
  } );

  const { formState, getValues, errors } = formContext;

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    const dataTariff = prepareFormData( variables!, formData );
    const result = await service.savedInfo( keyPolicyFormDataStep2, dataTariff );
    if ( result.errorCode === 0 ) {
      actions.goToStep( 'personal-data', true );
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, service, showAlert, t, variables ] );

  React.useEffect( () => {
    if ( !enabledPremiumCalculation ) {
      return;
    }

    let isMounted = true;

    const loadTariff = async () => {
      try {
        setLoading( true );
        const subStep2TariffData = getValues();
        const tariff = { ...lead.subStep1TariffData, ...subStep2TariffData };
        const preparedData = prepareDataForTariff( tariff, productData.variables, productData.insuredObjects, false );

        const sendData: IPolicyObject[] = [ ...preparedData ];

        const payloadInvoice: IProductDataForInvoice = {
          policyObjects: sendData,
        };

        const result = await service.getProductInvoice( productCode, payloadInvoice );

        if ( result ) {
          setTariffData( {
            premiumData: result,
            error: null,
          } );
          const { quote } = await service.getLead();

          // if billing frequency is a year
          const frequency = dayjs( result.billingIntervalTo ).diff( dayjs( result.billingIntervalFrom ), 'year' );

          if ( ( frequency > 0.9 && frequency < 1.1 ) &&
              ( ( !quote ) || ( quote && quote.grossAmount !== result.grossAmount ) ) ) {
            await service.savedInfo( 'quote', result );
            await actions.reloadLead();
            setLeadData( {
              ...leadData,
              quote: result,
            },
            );
          }

        } else {
          setTariffData( {
            premiumData: null,
            error: t ( 'base:messages.noData' ),
          } );
        }
        setLoading( false );
      } catch( e ) {
        if ( e instanceof Error ) {
          if ( isMounted ) {
            setTariffData( {
              premiumData: null,
              error: `Error in premium calculation. The reason is: ${e.message}`,
            } );
            setLoading( false );
          }
        } else {
          throw e;
        }
      }
    };

    loadTariff();

    return () => {
      isMounted = false;
    };
  }, [ actions, enabledPremiumCalculation, getValues, lead.subStep1TariffData,
    leadData, productCode, productData.insuredObjects, productData.variables, service, t ] );

  React.useEffect( () => {
    if ( !formState.isValid ) {
      setLoading( false );
      return;
    }

    const formValues = getValues();
    let isIgnoreOnRecalculation = true;

    const saveFormValues = async () => {
      try {
        setLeadData( {
          ...lead,
          [keyPolicyFormDataStep2]: previousTariffData.current,
        } );

        if ( !isEmpty( errors ) ) {
          return;
        }

        const result = await service.savedInfo( keyPolicyFormDataStep2, previousTariffData.current );

        if ( result.errorCode === 0 && !isIgnoreOnRecalculation ) {
          setReloadTariff( dayjs().valueOf() );
        }
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };

    if ( isEmpty( previousTariffData.current ) ) {
      previousTariffData.current = formValues;
      setLeadData( {
        ...lead,
        [keyPolicyFormDataStep2]: previousTariffData.current,
      } );
      return;
    }

    const changedField = getFieldDiff( formValues, previousTariffData.current );

    if ( changedField ) {
      isIgnoreOnRecalculation = isIgnoreOnRecalculationField( changedField, stepItems );
      previousTariffData.current = formValues;

      saveFormValues();
    }
  }, [ formState.isValid, formState.isValidating, getValues, service, showAlert, t, lead, stepItems, errors ] );

  if ( isNotDataSubStep1 ) {
    actions.goToSubStep( 'subStep1', false );
  }

  if ( productData.variables === null ) {
    return <LoadingSpinner />;
  }

  return (
    <FormProvider { ...formContext }>
      <div id="tariff-sub-step2" className="mt-4">
        { isLoading && loadingOnFullContainer }
        <Row className="sticky-top">
          <Col md={ 12 }>
            <button
              id={ `${BFStep.TariffSubStep2}_back` }
              type="button"
              className="btn btn-link m-0 p-0 text-c-black f-16 back-button"
              onClick={ () => actions.goToSubStep( 'subStep1', false ) }
            >
              <img src={ leftArrowIconUrl } alt="Back" />
              <span className="back-text pl-3 align-middle">
                { t( 'bookingFunnel.previousBtn' ) }
              </span>
            </button>
          </Col>
        </Row>
        <Row className="max-w-450">
          <Col md={ 12 } className="head-tab text-center px-0">
            <h1 className="f-26 text-c-black">{ t( 'bookingFunnel.tariff.subStep2Title' ) }</h1>
          </Col>
          <Col md={ 12 } className="head-tab mt-4 mb-5 px-0 text-center">
            <h2 className="f-18 text-c-black">{ t( 'bookingFunnel.tariff.subStep2SubTitle' ) }</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5 px-0" md={ 12 }>
            <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
              <Row className="bf-custom-p-clear col-md-12 m-0 justify-content-center p-0-mob">
                { enabledPremiumCalculation && productData.isCustomLayout && (
                  <Col md={ 12 } className="mb-4">
                    <ViewTariffResult
                      tariffData={ tariffData! }
                      isCustomLayout={ productData.isCustomLayout }
                      productCode={ productCode }
                      premiumShowsType={ premiumShowsType }
                    />
                  </Col>
                ) }
                { stepItems && stepItems.containers.map( ( container, index ) => (
                  <Col key={ index } className={ `${container.name}` } md={ productData.isCustomLayout ? 12 : 5 }>
                    { evalFunction( leadData || [], container.visibleCriteria ) && (
                      <Fragment>
                        { container.title && (
                          <Col sm={ 12 } className="mb-2">
                            <div
                              className="container-title d-inline-block"
                              dangerouslySetInnerHTML={ { __html: `${container.title}` } }
                            >
                            </div>
                          </Col>
                        ) }
                        { container.items && container.items.map( ( item, itemIdx ) => (
                          <Fragment key={ itemIdx }>
                            { variables.filter(
                              ( v ) => `${v.name}_${v.groupName}` === `${item.fieldName}_${item.insuredObjectName}`,
                            )
                              .map( ( variable, idx ) => (
                                <Col key={ idx } sm={ 12 } className="mb-4 px-0">
                                  <FieldPanal
                                    formData={ policyFormData! }
                                    factors={ productData.factors }
                                    variable={ variable }
                                    stepItem={ item }
                                    fieldDefaultValue={
                                      fieldsDefaultValues && fieldsDefaultValues.find(
                                        ( f ) =>
                                          f.insuredObjectName.toLowerCase() === variable.groupName?.toLowerCase() &&
                                        f.productFieldName.toLowerCase() === variable.name.toLowerCase() )
                                    }
                                  />
                                </Col>
                              ) ) }
                          </Fragment>
                        ) ) }
                      </Fragment>
                    ) }
                  </Col>
                ) ) }
                { enabledPremiumCalculation && !productData.isCustomLayout && (
                  <Col md={ 5 } className="mb-4">
                    <ViewTariffResult
                      tariffData={ tariffData! }
                      productCode={ productCode }
                      premiumShowsType={ premiumShowsType }
                    />
                  </Col>
                ) }
              </Row>
              <Row className="bf-custom-m-nav max-w-450 justify-content-center align-items-center mt-5">
                <Button
                  id={ `${BFStep.TariffSubStep2}_next` }
                  type="submit"
                  variant="primary"
                  disabled={ formState.isSubmitting }
                  className="col-sm-12 ml-4 mr-4 bg-btn-primary"
                >
                  <span>
                    { t( 'bookingFunnel.nextBtn' ) }
                    { tariffData?.premiumData !== null && tariffData?.premiumData.grossAmount && (
                      <span className="ml-1 mb-0 f-18">
                        { renderPrice(
                          tariffData?.premiumData.grossAmount,
                          productCode, tariffData?.premiumData.currency,
                          locale,
                        ) }
                        { tariffData?.premiumData.invoiceItems[0].unit !== oneTimePayment && (
                          <sup> / { t( `bookingFunnel.timeOptions.${tariffData?.premiumData.invoiceItems[0].unit}` ) }
                          </sup>
                        ) }
                      </span>
                    ) }
                  </span>
                </Button>
                { productData.isCustomLayout && (
                  <button
                    id={ `${BFStep.TariffSubStep2}_back` }
                    type="button"
                    className="btn btn-link mt-2 mb-2 mx-0 p-0 text-c-black f-16 custom-back-button"
                    onClick={ () => actions.goToSubStep( 'subStep1', false ) }
                  >
                    <span className="back-text align-middle">
                      { t( 'bookingFunnel.previousBtn' ) }
                    </span>
                  </button>
                ) }
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </FormProvider>
  );
};
