import React, { Fragment } from 'react';
import * as qs from 'query-string';
import rightArrowIconUrl from 'assets/bf-images/funk/right_arrow.svg';
import rightArrowGreenIconUrl from 'assets/bf-images/funk/right_arrow_green.svg';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ILeadData,
  IScreenType,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useWidgetService } from 'Services/widget';
import { isEmpty } from 'lodash';
import {
  ActiveStepCore,
  ActiveSubStepCore,
  RecalculationTariff,
  useCoreActions,
} from '../DynamicCore';
import { IDataFactorsAndVariables } from '../../booking-funnel/BookingFunnel';
import { SavedDataPopup } from '../ui-components/SavedDataPopup';
import { TariffInfo, keyInvoiceResult } from '../ui-components/TariffInfo';
import { FunkBFStep } from 'App/components/widgets/funk-reanovo/enums';
import { MobileNavCore } from '../ui-components/MobileNavCore';
import { TooltipPopupCore } from '../ui-components/TooltipPopupCore';
import {
  formatFormData,
  keyMultiInsuredObjects,
  generateRules,
  isEnabledPremiumCalculation,
  addressTypes,
  getInfoMessage,
} from '../core-hooks';
import { evalFunction } from '../../booking-funnel/functions';
import { FieldPanel } from '../ui-elements/FieldPanel';
import { getFieldDiff, isIgnoreOnRecalculationField } from '../../booking-funnel/booking-funnel-hooks';
import { FieldsFunkCore } from '../enums';
import { AddressFields } from 'Services/widgets/enums';
import { AddressPanel } from '../ui-elements/AddressPanel';
import { StepInfoItem } from '../interfaces';
import { DownloadLink } from '../ui-elements/DownloadLink';
import { JSONItemUIType } from '../../booking-funnel/enum';
import { AutoRenderObjectPanel } from '../ui-elements/AutoRenderObjectPanel';

export interface PolicyStepProps {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  screenType: IScreenType;
  stepData: StepInfoItem;
  recalculationTariff: RecalculationTariff;
  policyStepNames: string[];
}

const systemFields: string[] = [
  AddressFields.Country,
  AddressFields.City,
  AddressFields.Street,
  AddressFields.HouseNumber,
  AddressFields.Zip,
  AddressFields.IsAddressValid,
  FieldsFunkCore.FullAddress,
  'objectsCount',
];

export const PolicyStep: React.FC<PolicyStepProps> = ( dataProps ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { productCode, lead, productData, screenType, recalculationTariff, stepData, policyStepNames } = dataProps;

  const actions = useCoreActions();
  const { showAlert } = useAppAlertService();
  const service = useWidgetService();
  const previousIsReloadData = React.useRef<boolean>( true );
  const previousResultData = React.useRef<ITariffDataStep>( {} );
  const isIgnoreOnRecalculation = React.useRef<boolean>( false );
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );
  const [ messageError, setMessageError ] = React.useState<string>( '' );
  const [ isShowErrorPopup, setShowErrorPopup ] = React.useState<boolean>( false );
  const [ leadData, setLeadData ] = React.useState<ILeadData>( lead );
  const [ isRejected, setIsRejected ] = React.useState<boolean>( false );

  const keyPolicyStep = stepData.nameStep;
  const currentStep = stepData && stepData.steps.find(
    ( stepItem ) => !stepItem.visibleCriteria || evalFunction( leadData || [], stepItem.visibleCriteria ),
  );

  const formContext = useForm( {
    mode: 'onChange',
    shouldUnregister: true,
  } );

  const { formState, errors, getValues } = formContext;

  const getContainerParam = React.useCallback( ( ): string | undefined => {
    const parsedUrl = qs.parse( window.location.href, { decode: false } );
    return parsedUrl['containerGroup'];
  }, [] );

  const containerName = getContainerParam();

  const isCheckedProduct = React.useCallback( (): boolean => {
    return formState.isValid;
  }, [ formState.isValid ] );

  const rejectionPopup = React.useCallback( async (): Promise<void> => {
    if ( stepData && stepData.steps ) {
      const leadStore: ILeadData = await service.getLead();
      const rules = generateRules( stepData.steps );

      actions.setRejected( false );
      setIsRejected( false );

      if ( rules ) {
        rules.forEach( ( item ) => {
          const rejectionData = evalFunction( leadStore || [], item );
          if ( rejectionData && rejectionData['rejectionMessage'] ) {
            setIsRejected( true );
            setMessageError( rejectionData['rejectionMessage'] );
            setShowErrorPopup( true );
            actions.setRejected( true );

            return;
          }
        } );
      }
    }
  }, [ actions, service, stepData ] );

  const infoPopup = React.useCallback( async ( fieldInfo: string ): Promise<void> => {
    if ( !fieldInfo ) {
      return;
    }

    if ( currentStep && currentStep.containers ) {
      const leadStore: ILeadData = await service.getLead();
      const infoMessage = getInfoMessage( fieldInfo, currentStep.containers, leadStore );

      if ( infoMessage ) {
        setMessageError( infoMessage );
        setShowErrorPopup( true );
      }

    }
  }, [ currentStep, service ] );

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    rejectionPopup();

    if ( isRejected ) {
      return;
    }

    if ( !leadData[keyPolicyStep] ) {
      return;
    }
    const savedFormData = formatFormData( formData );

    const result = await service.savedInfo( keyPolicyStep, savedFormData );

    if ( result.errorCode === 0 && !isRejected ) {
      actions.goToStep( stepData.nextStep!, true );
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, isRejected, keyPolicyStep, leadData, rejectionPopup, service, showAlert, stepData.nextStep, t ] );

  React.useEffect( () => {
    let isMounted = true;

    const formValues = formatFormData( getValues() );

    if ( isEmpty( previousResultData.current ) ) {
      previousResultData.current = formValues;

      const isEditLead = lead['isEditLead'];
      if ( isEmpty( lead[keyPolicyStep] ) || isEditLead ) {
        setLeadData( {
          ...lead,
          [keyPolicyStep]: { ...lead[keyPolicyStep], ...previousResultData.current },
        } );
      }

      rejectionPopup();

      return;
    }

    const saveFormValues = async ( fieldInfo ) => {
      try {
        const isValid = await formState.isValid;
        if ( isValid && !isIgnoreOnRecalculation.current ) {
          await service.savedInfo( keyInvoiceResult, null );

          if ( policyStepNames.indexOf( keyPolicyStep ) === 0 ) {
            policyStepNames.forEach( async ( policyKey, idx ) => {
              if ( idx > 0 ) {
                await service.savedInfo( policyKey, null );
              }
            } );
            await service.savedInfo( keyMultiInsuredObjects, null );
          }
        }

        await service.savedInfo( keyPolicyStep, previousResultData.current );

        rejectionPopup();
        infoPopup( fieldInfo );
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };

    const changedField = getFieldDiff( formValues, previousResultData.current );

    if ( changedField && isMounted ) {
      previousResultData.current = formValues;

      setLeadData( {
        ...lead,
        [keyPolicyStep]: previousResultData.current,
      } );

      const isSystemField = systemFields.some( ( item ) => changedField.startsWith( item ) );
      if ( isSystemField ) {
        isIgnoreOnRecalculation.current = true;
      } else {
        isIgnoreOnRecalculation.current = isIgnoreOnRecalculationField( changedField, currentStep! );
      }

      saveFormValues( changedField );
    }

    return () => {
      isMounted = false;
    };
  }, [ formState.isValidating, service, showAlert, t, lead, getValues, formState.isValid,
    rejectionPopup, keyPolicyStep, currentStep, policyStepNames, infoPopup ] );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    if ( !isCheckedProduct() ) {
      setMessageError( t( 'bookingFunnel.funkReanovo.tariffResult.errorSave' ) );
      setShowErrorPopup( true );
      return;
    }

    if ( !formState.isValid ) {
      setMessageError( t( 'bookingFunnel.funkReanovo.validFormError' ) );
      setShowErrorPopup( true );
      return;
    }

    if ( isRejected ) {
      rejectionPopup();

      return;
    }

    previousIsReloadData.current = false;

    actions.reloadLead();
    setShowSavedStep( isShow );
  }, [ actions, formState.isValid, isCheckedProduct, isRejected, rejectionPopup, t ] );

  const onCloseErrorPopup = React.useCallback ( async ( ): Promise<void> => {
    setShowErrorPopup( false );
    setMessageError( '' );
  }, [] );

  const onCloseSavedPopup = React.useCallback ( ( ): void => {
    previousIsReloadData.current = true;
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( ( ): void => {
    previousIsReloadData.current = true;
    setShowSavedStep( false );
    actions.goToStep( stepData.nextStep!, true );
  }, [ actions, stepData.nextStep ] );

  const onShowError = React.useCallback ( ( ): void => {
    setMessageError( t( 'bookingFunnel.funkReanovo.validFormError' ) );
    setShowErrorPopup( true );
  }, [ t ] );

  const goBackSummary = React.useCallback( (): void => {
    if ( isRejected ) {
      return;
    }

    if ( !isEmpty( errors ) ) {
      onShowError();
      return;
    }

    if ( actions.goToSummaryStep ) {
      actions.goToSummaryStep(
        ActiveStepCore.Summary,
        ActiveSubStepCore.SummarySubStep1,
        true,
      );
    }
  }, [ actions, errors, isRejected, onShowError ] );

  if ( stepData.previousStep && !lead[stepData.previousStep] ) {
    actions.goToStep( policyStepNames[0], false );
  }

  const enabledPremiumCalculation = isEnabledPremiumCalculation( stepData, leadData );

  return (
    <FormProvider { ...formContext }>
      <div id={ stepData.nameStep } className="policy-step">
        { enabledPremiumCalculation && (
          <TariffInfo
            lead={ lead }
            productData={ productData }
            productCode={ productCode }
            screenType={ screenType }
            isIgnoreOnRecalculation={ isIgnoreOnRecalculation.current }
            isShowSavedInfo={ false }
            showSavePopup={ isEmpty( containerName ) ? onSaved : undefined }
            recalculationTariff={ recalculationTariff }
            subSteps={ stepData.steps }
            keyStep={ keyPolicyStep }
            policyStepNames={ policyStepNames }
          />
        ) }
        <Row className="mt-5 mx-0 bf-custom-padding">
          <Col className="p-0" md={ 12 }>
            <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
              <Row>
                { currentStep && (
                  <Fragment>
                    { currentStep.stepTitle && (
                      <div className="max-w-792"
                        dangerouslySetInnerHTML={ { __html: `${currentStep.stepTitle}` } }
                      >
                      </div>
                    ) }
                    { currentStep.containers.map( ( container, index ) => (
                      <Col
                        hidden={ !isEmpty( containerName ) && containerName !== container.groupName }
                        id={ `${ container.groupName ? container.groupName : index }-box` }
                        md={ 12 }
                        className={ `${container.name}${ stepData.previousStep ? ' max-w-600' : '' }` }
                        key={ index }
                      >
                        { evalFunction( leadData || [], container.visibleCriteria ) && (
                          <Fragment>
                            { container.title && (
                              <Col sm={ 12 } className="title-box">
                                <div
                                  className="container-title"
                                  dangerouslySetInnerHTML={ { __html: `${container.title}` } }
                                >
                                </div>
                              </Col>
                            ) }
                            { container.items && container.items.map( ( item, itemIdx ) => (
                              <Fragment key={ itemIdx }>
                                { !item.fieldName && item.isMulti && addressTypes.includes( item.uiType! ) && (
                                  <Col sm={ 12 } className="p-0 mb-4">
                                    <AddressPanel
                                      stepItem={ item }
                                      formData={ leadData[keyPolicyStep]! }
                                      insuredObjects={ productData.insuredObjects }
                                      multiInsuredObjects={ leadData[keyMultiInsuredObjects] }
                                    />
                                  </Col>
                                ) }
                                { !item.fieldName && item.isMulti &&
                                item.uiType! === JSONItemUIType.AutoRenderObject && (
                                  <Col sm={ 12 } className="p-0 mb-4">
                                    <AutoRenderObjectPanel
                                      stepItem={ item }
                                      lead={ lead }
                                      factors={ productData.factors }
                                      formData={ leadData[keyPolicyStep]! }
                                      insuredObjects={ productData.insuredObjects }
                                      multiInsuredObjects={ leadData[keyMultiInsuredObjects] }
                                    />
                                  </Col>
                                ) }
                                { productData.variables.filter(
                                  ( v ) => `${v.name}_${v.groupName}` === `${item.fieldName}_${item.insuredObjectName}`,
                                )
                                  .map( ( variable, idx ) => (
                                    <Col key={ idx } sm={ 12 } className="p-0 mb-4">
                                      <FieldPanel
                                        formData={ leadData[keyPolicyStep]! }
                                        factors={ productData.factors }
                                        insuredObjects={ productData.insuredObjects }
                                        variable={ variable }
                                        stepItem={ item }
                                        multiInsuredObjects={ leadData[keyMultiInsuredObjects] }
                                      />
                                    </Col>
                                  ) ) }
                              </Fragment>
                            ) ) }
                          </Fragment>
                        ) }
                      </Col>
                    ) ) }
                    { !isEmpty( containerName ) && (
                      <Col sm={ 12 } className="dynamic-btn-panel d-flex justify-content-center px-0">
                        <Button
                          id={ `${FunkBFStep.SummaryStep}_back` }
                          type="button"
                          variant="primary"
                          className={ `mr-0 mb-0 bg-btn-primary${isRejected ? ' box-disabled' : ''}` }
                          onClick={ goBackSummary }
                        >
                          { t( 'bookingFunnel.funkMesse.summary.backSummaryPage' ) }
                        </Button>
                      </Col>
                    ) }
                    { currentStep.downloadLinks && (
                      <DownloadLink
                        downloadLinks={ currentStep.downloadLinks }
                      />
                    ) }
                    { currentStep.requiredText && (
                      <Col sm={ 12 } className="mb-2 px-0 max-w-645">
                        <p className="f-12">{ currentStep.requiredText }</p>
                      </Col>
                    ) }
                  </Fragment>
                ) }
              </Row>
              <div
                className={ `max-w-667 ${!isEmpty( containerName ) ? 'box-disabled' : ''}` }
              >
                <MobileNavCore lead={ lead } />
              </div>
              <div
                className={ `max-w-667 ${!isEmpty( containerName ) ? 'box-disabled' : ''}` }
              >
                <div
                  className={
                    `dynamic-btn-panel d-flex 
                    ${stepData.previousStep ? 'justify-content-space-between' : 'justify-content-end' }`
                  }
                >
                  { stepData.previousStep && (
                    <Button
                      id={ `${stepData.previousStep}_back` }
                      type="button"
                      variant="link"
                      className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
                      onClick={ () => actions.goToStep( stepData.previousStep!, true ) }
                    >
                      { t( 'bookingFunnel.previousBtn' ) }
                    </Button>
                  ) }
                  <Button
                    id={ `${stepData.nextStep}_next` }
                    type="submit"
                    variant={ !isCheckedProduct() ? 'outline-primary' : 'primary' }
                    disabled={ formState.isSubmitting }
                    className="mr-0 mb-0 bg-btn-primary"
                  >
                    { t( 'bookingFunnel.nextBtn' ) }
                    <img
                      src={ !isCheckedProduct() ? rightArrowGreenIconUrl : rightArrowIconUrl }
                      alt={ t( 'bookingFunnel.nextBtn' ) }
                    />
                  </Button>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
      { isShowErrorPopup && messageError && (
        <TooltipPopupCore
          tooltipInfo={ messageError }
          onClose={ () => onCloseErrorPopup( ) }
        />
      ) }
      { isShowSavedStep && (
        <SavedDataPopup
          currentStep={ keyPolicyStep }
          productCode={ productCode }
          lead={ leadData }
          productData={ productData! }
          screenType={ screenType }
          onClose={ () => onCloseSavedPopup( ) }
          goNextStep={ goNextStep }
          policyStepNames={ policyStepNames }
        />
      ) }
    </FormProvider>
  );
};
