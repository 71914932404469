import React from 'react';
import { AddressTypeOption } from '../AddressSelectElement';

export interface AddressSelectValuesOptionProps {
  values: AddressTypeOption;
}

export const AddressSelectValuesOptionComponent: React.FC<AddressSelectValuesOptionProps> = ( { values } ) => {
  return (
    <span className="d-inline-flex align-items-center">
      { values.label }
    </span>
  );
};
