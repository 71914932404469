import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory, History } from 'history';
import { WidgetProps } from './types';
import { WidgetProxy } from './WidgetProxy';
import { WidgetService } from 'Services/widget';
import { WidgetServiceProvider } from 'App/components/utils/providers/WidgetProvider';

export const history: History = createBrowserHistory();

export const WidgetContainer: React.FC<WidgetProps> = ( props ) => {
  return (
    <Router history={ history } >
      <WidgetProxy config={ props.config }>
        <WidgetServiceProvider useClass={ WidgetService }>
          { props.children }
        </WidgetServiceProvider>
      </WidgetProxy>
    </Router>
  );
};
