import React from 'react';
import { IndicatorComponentType } from 'react-select';
import downArrowIconUrl from 'assets/bf-images/down-arrow.svg';

export const NullIndicator: IndicatorComponentType<any> = () => {
  return null;
};

export const DropdownIndicatorImage: IndicatorComponentType<any> = () => {
  return ( <img src={ downArrowIconUrl } alt="Arrow" /> );
};

export const DropdownIndicatorTagForBg: IndicatorComponentType<any> = () => {
  return ( <span className="bg-dropdown-indicator"></span> );
};
