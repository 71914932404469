import React from 'react';
import { PartnersApi } from '@emilgroup/partner-sdk';
import { publicApiUrl } from 'App/utils';
import { PartnerRolesApi } from '@emilgroup/insurance-sdk';

export const usePartnersApi = () => {
  const ref = React.useRef<PartnersApi>( new PartnersApi( undefined, publicApiUrl ) );
  return ref.current;
};

export const usePartnerRolesApi = () => {
  const ref = React.useRef<PartnerRolesApi>( new PartnerRolesApi( undefined, publicApiUrl ) );
  return ref.current;
};
