export interface ParseLicensePlate {
  regionCode: string;
  districtCode: string;
  number: string;
  suffix: string;
}

const splitStringByLength = ( str, lengths ): string[] => {
  let result: string[] = [];
  let startIndex = 0;

  lengths.forEach( ( length ) => {
    const part = str.slice( startIndex, startIndex + length ).replaceAll( ' ', '' );
    result.push( part );
    startIndex += length;
  } );

  return result;
};

export const parseLicensePlate = ( value: string ): ParseLicensePlate | null => {
  const lengths = [ 3, 2, 4, 1 ];
  const result = splitStringByLength( value, lengths );

  if ( result ) {
    const regionCode = result[0];
    const districtCode = result[1];
    const number = result[2];
    const suffix = result[3] || '';

    return {
      regionCode,
      districtCode,
      number,
      suffix,
    };
  }

  return null;
};
