import React, { Fragment } from 'react';
import addButtonIconUrl from 'assets/bf-images/funk/add_button.svg';
import deleteButtonIconUrl from 'assets/bf-images/funk/delete_icon.svg';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ContainerFieldItem,
  ContainerItem,
  CriteriaItem,
  IBFInsuredObjectItem,
  ILeadData,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { cloneDeep } from 'lodash';
import { useWidgetService } from 'Services/widget';
import { keyMultiInsuredObjects, renderContainerItemClassName, renderGridStyles } from '../../core-hooks';
import { FieldType, LayoutLevel } from 'Services/widgets/enums';
import { evalFunction } from 'App/components/widgets/booking-funnel/functions';
import { CoreFieldPanel } from './CoreFieldPanel';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { LayoutTypes } from '../../enums';
import { PolicyEditData } from '../../PolicyEdit';
import { useCoreActions } from '../../DynamicCore';

export interface CoreMultiInsuredObjectProps {
  leadData: ILeadData;
  productData: IDataFactorsAndVariables | PolicyEditData;
  stepItem?: ContainerFieldItem;
  formData: ITariffDataStep;
  currentStepId: string;
  container?: ContainerItem;
  nameStep: string;
  isDisabled?: boolean;
}

export const CoreMultiInsuredObject: React.FC<CoreMultiInsuredObjectProps> = ( {
  leadData,
  productData,
  stepItem,
  formData,
  currentStepId,
  container,
  nameStep,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const service = useWidgetService();
  const actions = useCoreActions();
  const { insuredObjectName: nameCloneObject, isDisabledAdding, isDisabledDeleting } = stepItem || {};
  const foundCloneObject = productData.insuredObjects.find( ( object ) => object.name === nameCloneObject );
  const minInsuredObjectsCount = foundCloneObject?.minInsuredObjectsCount
    ? foundCloneObject?.minInsuredObjectsCount : 0;
  const maxInsuredObjectsCount = foundCloneObject?.maxInsuredObjectsCount
    ? foundCloneObject?.maxInsuredObjectsCount : 0;
  const multiInsuredObjects = leadData[keyMultiInsuredObjects];
  const savedMultiInsuredObjects = multiInsuredObjects?.filter(
    ( o ) => o.name.startsWith( `${nameCloneObject}_` )
  );

  const [ multiInsuredObjectsFields, setMultiInsuredObjectsFields ] = React.useState<IBFInsuredObjectItem[]>(
    savedMultiInsuredObjects || [] );
  const fieldName = 'objectsCount';
  const objectsCount: number = multiInsuredObjectsFields.length;

  const { control, errors, setValue } = useFormContext();

  const defaultLayoutData = productData.stepsConfig && productData.stepsConfig.layout
    ? productData.stepsConfig.layout[LayoutTypes.Default] : null;

  const updatedInsuredObjectName = React.useCallback (
    ( criteria: CriteriaItem, insuredObjectName: string,
    ): CriteriaItem => {
      const newCriteria = cloneDeep( criteria );
      const newArgs: CriteriaItem[] = [];

      newCriteria.args.forEach( ( arg ) => {
        let newValue = arg.value;

        if( foundCloneObject && typeof newValue === 'string' && newValue.endsWith( foundCloneObject.name ) ) {
          newValue = newValue.replace( `_${foundCloneObject.name}`, `_${insuredObjectName}` );
        }

        const newItemArg = {
          ...arg,
          value: newValue,
        };

        newArgs.push( newItemArg );
      } );

      newCriteria.args = newArgs;

      return newCriteria;
    }, [ foundCloneObject ] );

  const isDisabledField = React.useCallback ( ( item: ContainerFieldItem ): boolean => {
    if ( item.disableCriteria ) {
      const disableCriteria = updatedInsuredObjectName( item.disableCriteria, item.insuredObjectName );
      return evalFunction( leadData || [], disableCriteria ) as boolean;
    }

    return false;
  }, [ leadData, updatedInsuredObjectName ] );

  const getDefaultValue = ( ): boolean => {
    return true;
  };

  const isValidateValue = React.useCallback ( ( value: string ): string | boolean => {
    const converValue = value ? Number( value ) : 0;

    if ( converValue < minInsuredObjectsCount ) {
      const errMessage = t( 'bookingFunnel.numberMin',
        { fieldLabel: stepItem?.label, minValue: minInsuredObjectsCount },
      );
      return errMessage;
    }

    return true;
  }, [ minInsuredObjectsCount, stepItem, t ] );

  const addInsuredObject = React.useCallback( async (): Promise<void> => {
    if ( !foundCloneObject ) {
      return;
    }

    const indexObject: number = multiInsuredObjectsFields.length + 1;
    let nameObject = `${foundCloneObject?.name}_${indexObject}`;

    const foundItem = multiInsuredObjectsFields.find( ( item ) => item.name === nameObject );

    if ( foundItem ) {
      nameObject = `${foundCloneObject?.name}_${indexObject+2}`;
    }

    if ( !foundCloneObject.productFields ) {
      return;
    }

    const newObject = {
      ...foundCloneObject,
      isMultiInsuredObject: false,
      minInsuredObjectsCount: null,
      maxInsuredObjectsCount: null,
      name: nameObject,
      productFields: [
        ...foundCloneObject?.productFields!.map( ( item ) => {
          return {
            ...item,
            groupName: nameObject,
          };
        } ),
      ],
    };

    const storeLeadData = await service.getLead( 0 );
    const storeMultiInsuredObjects = storeLeadData[keyMultiInsuredObjects] ? storeLeadData[keyMultiInsuredObjects] : [];
    const filterStoreObjects = storeMultiInsuredObjects?.filter(
      ( o ) => !o.name.startsWith( `${nameCloneObject}_` )
    ) || [];

    let newMultiInsuredObjectsList = cloneDeep( multiInsuredObjectsFields );
    newMultiInsuredObjectsList.push( newObject );

    await service.savedInfo( keyMultiInsuredObjects, [
      ...filterStoreObjects, ...newMultiInsuredObjectsList,
    ] );

    setValue( fieldName, newMultiInsuredObjectsList.length, { shouldValidate: true } );
    setMultiInsuredObjectsFields( newMultiInsuredObjectsList );
  }, [ foundCloneObject, multiInsuredObjectsFields, nameCloneObject, service, setValue ] );

  const onDeleteAddress = React.useCallback ( async ( nameObject: string ): Promise<void> => {
    const multiInsuredObjectsList = cloneDeep( multiInsuredObjectsFields );
    const newMultiInsuredObjectsList = multiInsuredObjectsList.filter( ( item ) => item.name !== nameObject );

    const storeLeadData = await service.getLead( 0 );
    const storeMultiInsuredObjects = storeLeadData[keyMultiInsuredObjects] ? storeLeadData[keyMultiInsuredObjects] : [];
    const filterStoreObjects = storeMultiInsuredObjects?.filter(
      ( o ) => !o.name.startsWith( `${nameCloneObject}_` )
    ) || [];

    await service.savedInfo( keyMultiInsuredObjects, [
      ...filterStoreObjects, ...newMultiInsuredObjectsList,
    ] );

    setValue( fieldName, newMultiInsuredObjectsList.length, { shouldValidate: true } );
    setMultiInsuredObjectsFields( [] );
    setMultiInsuredObjectsFields( newMultiInsuredObjectsList );
    actions.recalculationPremium( true );
  }, [ actions, multiInsuredObjectsFields, nameCloneObject, service, setValue ] );

  if ( !foundCloneObject ) {
    return <></>;
  }

  return (
    <Fragment>
      { stepItem && stepItem.label && (
        <FormGroup>
          <Form.Label>
            <div className="d-inline-block"
              dangerouslySetInnerHTML={ { __html: `${stepItem.label}` } }
            >
            </div>
          </Form.Label>
        </FormGroup>
      ) }
      { multiInsuredObjectsFields.length > 0 && (
        <Fragment>
          { multiInsuredObjectsFields.map( ( item, idx ) => (
            <Fragment key={ idx }>
              { stepItem && stepItem.items && stepItem.items.map( ( field, fieldIdx ) => {
                const fieldItem = field as unknown as ContainerFieldItem;
                const visibleCriteria = updatedInsuredObjectName( fieldItem.visibleCriteria!, item.name );
                const isVisibleCriteria = evalFunction( leadData || [], visibleCriteria );

                if ( !isVisibleCriteria || ( fieldItem.type && fieldItem.type !== FieldType.Policy ) ) {
                  return <Fragment key={ fieldIdx }></Fragment>;
                }

                const foundProductField = item.productFields?.find(
                  ( v ) =>
                    `${v.name}_${v.groupName}` === `${fieldItem.fieldName}_${v.groupName}`,
                );

                if ( !foundProductField ) {
                  return <Fragment key={ fieldIdx }></Fragment>;
                }

                return (
                  <div
                    key={ fieldIdx }
                    className={ renderContainerItemClassName( fieldItem ) }
                    style={
                      renderGridStyles(
                        defaultLayoutData,
                        LayoutLevel.Item,
                        currentStepId,
                        container,
                        fieldItem.id,
                      )
                    }
                  >
                    <CoreFieldPanel
                      formData={ formData }
                      factors={ productData.factors }
                      variable={ foundProductField }
                      stepItem={ fieldItem }
                      fieldDefaultValue={ undefined }
                      isDisabled={ isDisabledField( fieldItem ) }
                    />
                  </div>
                );
              } ) }
              { !isDisabledDeleting && !isDisabled && (
                <div className="col-md-12 mb-4 d-flex justify-content-end">
                  <Button
                    type="button"
                    variant="link"
                    className="delete-insured-object m-0 custom-back-button"
                    onClick={ ( e ) => {
                      e.preventDefault();
                      onDeleteAddress( item.name );
                    } }
                  >
                    <img src={ deleteButtonIconUrl } alt="Delete" />
                  </Button>
                </div>
              ) }
            </Fragment>
          ) ) }
        </Fragment>
      ) }
      { !isDisabledAdding && !isDisabled && foundCloneObject && foundCloneObject.isMultiInsuredObject &&
      maxInsuredObjectsCount > multiInsuredObjectsFields.length && (
        <Controller
          name="addInsuredObject"
          control={ control }
          rules={ {
            required: false,
          } }
          defaultValue={ getDefaultValue() }
          render={ ( props ) => (
            <FormGroup>
              <Form.Label
                id={ `${props.name}-label` }
                className="d-flex justify-content-end align-items-center"
              >
                <Button
                  type="button"
                  variant="primary"
                  className="add-insured-object py-0 m-0 bg-btn-primary"
                  onClick={ addInsuredObject }
                >
                  <img src={ addButtonIconUrl } alt="Add" />
                </Button>
              </Form.Label>
            </FormGroup>
          ) }
        />
      ) }
      { foundCloneObject && foundCloneObject.isMultiInsuredObject && (
        <Fragment>
          <Controller
            name={ fieldName }
            control={ control }
            rules={ {
              required: true,
              validate: ( value ) => {
                return isValidateValue( value );
              },
            } }
            defaultValue={ objectsCount }
            render={ ( props ) => (
              <FormGroup controlId={ props.name }>
                <Form.Control
                  { ...props }
                  isInvalid={ errors[props.name] !== undefined }
                  type="hidden"
                />
                <Form.Control.Feedback type="invalid">
                  { errors[props.name]?.message ? ( errors[props.name]?.message ) : (
                    <Fragment>
                      { t( 'base:forms.messages.fieldRequired',
                        { fieldLabel: stepItem?.label } ) }
                    </Fragment>
                  ) }
                </Form.Control.Feedback>
              </FormGroup>
            ) }
          />
        </Fragment>
      ) }
    </Fragment>
  );
};
