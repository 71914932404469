import React, { Fragment } from 'react';
import deleteButtonIconUrl from 'assets/bf-images/funk/delete_icon.svg';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem } from 'Services/widgets/interfaces';
import { AddressSelectElement } from './AddressSelectElement';

export interface AddressFieldProps {
  nameField: string;
  valueField: string;
  stepItem?: ContainerFieldItem;
  nameGroup: string;
  onDeleteAddress: ( objectName: string ) => Promise<void>;
}

export const AddressField: React.FC<AddressFieldProps> = ( {
  nameField,
  valueField,
  stepItem,
  nameGroup,
  onDeleteAddress,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control } = useFormContext();
  const configObject = stepItem && stepItem.config ? stepItem.config : '';

  const onDelete = React.useCallback( async (): Promise<void> => {
    await onDeleteAddress( nameGroup );
  }, [ nameGroup, onDeleteAddress ] );

  return (
    <Fragment>
      <Controller
        name={ nameField }
        control={ control }
        rules={ { required: true } }
        defaultValue={ valueField }
        render={ ( props ) => (
          <FormGroup
            className={ `address-field mb-4${errors[props.name] !== undefined ? ' dynamic-input-error' : ''}` }
            controlId={ props.name }
          >
            <AddressSelectElement
              nameGroup={ nameGroup }
              nameField={ props.name }
              selectValue={ { label: props.value } }
              placeholder={ configObject['addressLabel'] || '' }
              stepItem={ stepItem! }
              countryName={ configObject['countryName'] || '' }
            />
            <Form.Control
              { ...props }
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            { !configObject['deleteAddressDisabled'] &&
              <Button
                type="button"
                variant="link"
                className="delete-address m-0 custom-back-button"
                onClick={ ( e ) => {
                  e.preventDefault();
                  onDelete();
                } }
              >
                <img
                  src={ deleteButtonIconUrl }
                  alt="Delete"
                />
              </Button>
            }
            { configObject['addressDescription'] && (
              <span
                className="d-inline-block w-100 text-right gm-info f-12 mt-1 pr-4"
                dangerouslySetInnerHTML={ { __html: `${configObject['addressDescription'] }` } }
              >
              </span>
            ) }
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
            errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired',
                    { fieldLabel: configObject['addressLabel'] || '' } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
