import React, { Fragment } from 'react';
import { CFDefaultValueType } from 'App/components/widgets/bf-hooks';
import {
  IBFProductVariable,
  ContainerFieldItem,
  IBFInsuredObjectItem,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { FieldsFunkCore } from '../enums';
import { AddressField } from './fields/AddressField';
import { AddressHiddenField } from './fields/AddressHiddenField';
import { JSONItemUIType } from '../../booking-funnel/enum';
import { renderFullAddress } from '../core-hooks';

export interface AddressElementProps {
  addressItem: IBFInsuredObjectItem;
  onDeleteAddress: ( objectName: string ) => Promise<void>;
  stepItem?: ContainerFieldItem;
  formData: ITariffDataStep;
}

export const AddressElement: React.FC<AddressElementProps> = (
  { addressItem, formData, onDeleteAddress, stepItem },
) => {
  const getFullAddressValue = ( field: IBFProductVariable, nameGroup: string ): CFDefaultValueType => {
    const addressNameField = `${field.name}_${nameGroup}`;
    if ( formData && formData[addressNameField] ) {
      return formData[addressNameField];
    }

    const fullAdress = renderFullAddress( formData, nameGroup );

    if ( fullAdress ) {
      return fullAdress;
    }

    return '';
  };

  const getFieldValue = ( field: IBFProductVariable, nameGroup: string ): CFDefaultValueType => {
    const nameField = `${field.name}_${nameGroup ? nameGroup : field.insuredObjectId}`;
    if ( formData && formData[nameField] ) {

      return formData[nameField];
    }

    return field.defaultValue;
  };

  const isAddressSingleLine = stepItem && stepItem.uiType && stepItem.uiType === JSONItemUIType.AddressSingleLine;

  return (
    <Fragment>
      { isAddressSingleLine && addressItem.productFields && addressItem.productFields.map( ( field, idx ) => (
        <Fragment key={ idx }>
          { field.name.startsWith( FieldsFunkCore.FullAddress ) ? (
            <AddressField
              nameField={ `${field.name}_${addressItem.name}` }
              valueField={ getFullAddressValue( field, addressItem.name ) }
              stepItem={ stepItem }
              nameGroup={ addressItem.name }
              onDeleteAddress={ onDeleteAddress }
            />
          ) : (
            <AddressHiddenField
              valueField={ getFieldValue( field, addressItem.name ) }
              variable={ field }
              nameGroup={ addressItem.name }
            />
          ) }
        </Fragment>
      ) ) }
    </Fragment>
  );
};
