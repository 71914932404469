import React, { Fragment } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ILeadData, ITariffDataStep } from 'Services/widgets/interfaces';
import { AppAlertUI, useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useWidgetService } from 'Services/widget';
import { RecalculationTariff, useCoreActions } from '../../DynamicCore';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import {
  formatFormData,
  renderContainerItemClassName,
} from '../../core-hooks';
import { evalFunction } from '../../../booking-funnel/functions';
import { FieldType } from 'Services/widgets/enums';
import { StepInfoItem } from '../../interfaces';
import { DownloadLink } from '../../ui-elements/DownloadLink';
import { CoreCheckbox } from '../core-ui-components/CoreCheckbox';
import { CoreEmailConfirmation } from '../core-ui-components/CoreEmailConfirmation';
import { CoreLabel } from '../core-ui-components/CoreLabel';

export interface SystemCoreStepProps {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  stepData: StepInfoItem;
  recalculationTariff: RecalculationTariff;
  policyStepNames: string[];
}

export const SystemCoreStep: React.FC<SystemCoreStepProps> = ( dataProps ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { productCode, lead, productData, stepData, policyStepNames } = dataProps;

  const actions = useCoreActions();
  const service = useWidgetService();
  const { showAlert } = useAppAlertService();

  const [ isValidCode, setValidCode ] = React.useState<boolean>( false );

  const keySystemStep = stepData.nameStep;
  const currentStep = stepData && stepData.steps.find(
    ( stepItem ) => !stepItem.visibleCriteria || evalFunction( lead || [], stepItem.visibleCriteria ),
  );

  const formContext = useForm( {
    mode: 'onChange',
    shouldUnregister: true,
  } );

  const { formState } = formContext;

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    const savedFormData = formatFormData( formData );

    const result = await service.savedInfo( keySystemStep, savedFormData );

    if ( result.errorCode === 0 ) {
      actions.goToStep( stepData.nextStep!, true );
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, keySystemStep, service, showAlert, stepData.nextStep, t ] );

  const onEnabledNextStep = React.useCallback ( ( isValidData: boolean ): void => {
    setValidCode( isValidData );
  }, [] );

  if ( stepData.previousStep && !lead[stepData.previousStep] ) {
    actions.goToStep( policyStepNames[0], false );
  }

  if ( lead && lead.isLogin ) {
    actions.goToStep( stepData.nextStep!, false );
  }

  return (
    <FormProvider { ...formContext }>
      <div id={ stepData.nameStep } className="system-step">
        <Row className="mt-3 mx-0">
          <Col md={ 12 } className="px-0" id={ `alert-${stepData.nameStep}` }>
            <AppAlertUI />
          </Col>
          <Col md={ 12 } className="px-0">
            <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
              <Fragment>
                { currentStep && (
                  <Fragment>
                    { stepData.title && (
                      <Row>
                        <Col className="p-0" md={ 12 }
                          dangerouslySetInnerHTML={ { __html: `${stepData.title}` } }
                        >
                        </Col>
                      </Row>
                    ) }
                    { stepData.subTitle && (
                      <Row>
                        <Col className="p-0" md={ 12 }
                          dangerouslySetInnerHTML={ { __html: `${stepData.subTitle}` } }
                        >
                        </Col>
                      </Row>
                    ) }
                    { currentStep.containers.map( ( container, index ) => (
                      <Fragment key={ index }>
                        { evalFunction( lead || [], container.visibleCriteria ) && (
                          <Row
                            id={ `${ container.groupName ? `${container.groupName}-${index}` : index }-box` }
                            className={ `${container.name ? container.name : '' }` }
                          >
                            { container.title && (
                              <Col md={ 12 } className="p-0 title-box">
                                <div
                                  className="container-title"
                                  dangerouslySetInnerHTML={ { __html: `${container.title}` } }
                                >
                                </div>
                              </Col>
                            ) }
                            { container.items && container.items.map( ( item, itemIdx ) => (
                              <Fragment key={ itemIdx }>
                                { item.type && item.type === FieldType.DownloadLink && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                  >
                                    <DownloadLink
                                      downloadLinks={ item.downloadLinks }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.Label && (
                                  <CoreLabel
                                    leadData={ lead }
                                    className={ renderContainerItemClassName( item ) }
                                    stepItem={ item }
                                  />
                                ) }
                                { item.type && item.type === FieldType.Consent && (
                                  <div className={ renderContainerItemClassName( item ) }>
                                    <CoreCheckbox
                                      stepItem={ item }
                                      productCode={ productCode }
                                      productData={ productData }
                                      formData={ lead[keySystemStep] }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.EmailConfirmation && (
                                  <div className={ renderContainerItemClassName( item ) }>
                                    <CoreEmailConfirmation
                                      leadData={ lead }
                                      keyName={ keySystemStep }
                                      stepItem={ item }
                                      enabledNextStep={ onEnabledNextStep }
                                    />
                                  </div>
                                ) }
                              </Fragment>
                            ) ) }
                          </Row>
                        ) }
                      </Fragment>
                    ) ) }
                  </Fragment>
                ) }
              </Fragment>
              <Row className="bf-footer-btn-panel justify-content-center align-items-center mt-4 mx-0">
                <Button
                  id={ `${stepData.nextStep}_next` }
                  type="submit"
                  variant="primary"
                  disabled={ formState.isSubmitting || !isValidCode }
                  className="col-sm-12 mr-0"
                >
                  { t( 'bookingFunnel.nextBtn' ) }
                </Button>
                { stepData.previousStep && (
                  <Button
                    id={ `${stepData.previousStep}_back` }
                    type="button"
                    variant="link"
                    className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
                    onClick={ () => actions.goToStep( stepData.previousStep!, true ) }
                  >
                    { t( 'bookingFunnel.previousBtn' ) }
                  </Button>
                ) }
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </FormProvider>
  );
};
