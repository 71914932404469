import React, { Fragment } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ICFFactorType } from '../../../factor-service';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { IDataFactorsAndVariables, useBookingFunnelActions } from '../../BookingFunnel';
import { ITariffDataStep, IBFProductVariable, ILeadData, SubStepItem } from 'Services/widgets/interfaces';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useWidgetService } from 'Services/widget';
import { prepareFormData } from '../../booking-funnel-hooks';
import { FieldPanal } from '../../tariff-elements/FieldPanal';
import { BFStep } from 'App/components/widgets/booking-funnel/enum';
import { evalFunction } from '../../functions';
import { isEmpty, isEqual } from 'lodash';
import { keyPolicyFormDataStep2 } from './PolicyStep2';

export interface PolicySubStep1Props {
  stepItems: SubStepItem;
  lead: ILeadData;
  variables: IBFProductVariable[] | null;
  factors: ICFFactorType[];
  productData: IDataFactorsAndVariables;
}

export const keyPolicyFormDataStep1 = 'subStep1TariffData';

export const PolicySubStep1: React.FC<PolicySubStep1Props> = ( props ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useBookingFunnelActions();
  const { showAlert } = useAppAlertService();
  const service = useWidgetService();

  const { stepItems, lead, variables, factors, productData } = props;
  const { fieldsDefaultValues } = productData.configuration || {};
  const [ leadData, setLeadData ] = React.useState<ILeadData>( lead );

  const previousPolicyData = React.useRef<ITariffDataStep>( {} );
  const policyFormData = lead[keyPolicyFormDataStep1];

  const formContext = useForm( {
    mode: 'onChange',
    shouldUnregister: true,
  } );

  const { formState, getValues, errors } = formContext;

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    const dataTariff = prepareFormData( variables!, formData );

    const result = await service.savedInfo( keyPolicyFormDataStep1, dataTariff );
    if ( result.errorCode === 0 ) {
      actions.goToSubStep( 'subStep2', true );
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, service, showAlert, t, variables ] );

  React.useEffect( () => {
    const formValues = getValues();

    const saveFormValues = async () => {
      try {
        setLeadData( {
          ...lead,
          [keyPolicyFormDataStep1]: previousPolicyData.current,
        } );
        await service.savedInfo( keyPolicyFormDataStep1, previousPolicyData.current );
        await service.savedInfo( keyPolicyFormDataStep2, null );
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };

    if ( isEmpty( previousPolicyData.current ) ) {
      previousPolicyData.current = formValues;
      setLeadData( {
        ...lead,
        [keyPolicyFormDataStep1]: previousPolicyData.current,
      } );
      return;
    }

    if ( !isEqual( formValues, previousPolicyData.current ) ) {
      previousPolicyData.current = formValues;
      saveFormValues();
    }
  }, [ errors, formState.isValid, formState.isValidating, getValues, lead, service, showAlert, t ] );

  if ( variables === null ) {
    return <LoadingSpinner />;
  }

  return (
    <FormProvider { ...formContext }>
      <Row className="max-w-450 mt-4" id="tariff-sub-step1">
        <Col md={ 12 } className="head-tab text-center px-0">
          <h1 className="f-26 text-c-black">{ t( 'bookingFunnel.tariff.subStep1Title' ) }</h1>
        </Col>
        <Col md={ 12 } className="head-tab mt-4 mb-5 px-0 text-center">
          <h2 className="f-18 text-c-black">{ t( 'bookingFunnel.tariff.subStep1SubTitle' ) }</h2>
        </Col>
        <Col md={ 12 } className="px-0">
          <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
            { stepItems && stepItems.containers.map( ( container, index ) => (
              <Row key={ index } className={ `${container.name}` }>
                { evalFunction( leadData || [], container.visibleCriteria ) && (
                  <Fragment>
                    { container.title && (
                      <Col sm={ 12 } className="mb-2">
                        <div
                          className="container-title d-inline-block"
                          dangerouslySetInnerHTML={ { __html: `${container.title}` } }
                        >
                        </div>
                      </Col>
                    ) }
                    { container.items && container.items.map( ( item, itemIdx ) => (
                      <Fragment key={ itemIdx }>
                        { variables.filter(
                          ( v ) => `${v.name}_${v.groupName}` === `${item.fieldName}_${item.insuredObjectName}`,
                        )
                          .map( ( variable, idx ) => (
                            <Col key={ idx } sm={ 12 } className="mb-4">
                              <FieldPanal
                                formData={ policyFormData! }
                                factors={ factors }
                                variable={ variable }
                                stepItem={ item }
                                fieldDefaultValue={
                                  fieldsDefaultValues && fieldsDefaultValues.find(
                                    ( f ) => f.insuredObjectName.toLowerCase() === variable.groupName?.toLowerCase() &&
                                    f.productFieldName.toLowerCase() === variable.name.toLowerCase() )
                                }
                              />
                            </Col>
                          ) ) }
                      </Fragment>
                    ) ) }
                  </Fragment>
                ) }
              </Row>
            ) ) }
            <Row className="justify-content-center align-items-center m-0 mt-5">
              <Button
                id={ `${BFStep.TariffSubStep1}_next` }
                type="submit"
                variant="primary"
                disabled={ formState.isSubmitting }
                className="col-sm-12 mr-0"
              >
                { t( 'bookingFunnel.nextBtn' ) }
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </FormProvider>
  );
};
