import React, { Fragment } from 'react';
import { Col, Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface AddressPersonalMultiLineProps {
  personalDataFields: any;
  defaultCountry: string;
}

export const AddressPersonalMultiLine: React.FC<AddressPersonalMultiLineProps> = ( propsParent ) => {
  const { personalDataFields, defaultCountry } = propsParent;
  const { errors, control } = useFormContext();
  const { t } = useTranslation( [ 'widgets', 'base' ] );

  return (
    <Fragment>
      <Col sm={ 12 } className="mb-4">
        <Form.Label className="dynamic-main-label">
          { t( 'bookingFunnel.funk.registration.adressTitle' ) }
        </Form.Label>
        <Controller
          name="street"
          control={ control }
          rules={ { required: true } }
          defaultValue={ personalDataFields.street }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalDataFunk.street' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                placeholder={ t( 'bookingFunnel.personalDataFunk.street' ) }
                isInvalid={ errors[props.name] !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalDataFunk.street' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col sm={ 12 } className="mb-4">
        <Controller
          name="houseNumber"
          control={ control }
          rules={ { required: true } }
          defaultValue={ personalDataFields.houseNumber }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalDataFunk.houseNumber' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                placeholder={ t( 'bookingFunnel.personalDataFunk.houseNumber' ) }
                isInvalid={ errors[props.name] !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalDataFunk.houseNumber' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="city"
          control={ control }
          rules={ { required: true } }
          defaultValue={ personalDataFields.city }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalDataFunk.city' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                placeholder={ t( 'bookingFunnel.personalDataFunk.city' ) }
                isInvalid={ errors[props.name] !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalDataFunk.city' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="zipCode"
          control={ control }
          rules={ { required: true } }
          defaultValue={ personalDataFields.zipCode }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalDataFunk.postCode' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                placeholder={ t( 'bookingFunnel.personalDataFunk.postCode' ) }
                isInvalid={ errors[props.name] !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalDataFunk.postCode' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="country"
          control={ control }
          rules={ { required: true } }
          defaultValue={ defaultCountry }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalDataFunk.country' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                className="read-only"
                type="text"
                readOnly={ true }
                placeholder={ t( 'bookingFunnel.personalDataFunk.country' ) }
                isInvalid={ errors[props.name] !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalDataFunk.country' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
    </Fragment>
  );
};
