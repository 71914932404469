import React from 'react';
import i18n, { i18n as i18nType } from 'i18next';
import { I18nContext, initReactI18next } from 'react-i18next';
import widgetsDe from '../../../../../public/locales/de/widgets.json';
import widgetsEn from '../../../../../public/locales/en/widgets.json';
import baseDe from '../../../../../public/locales/de/bf-base.json';
import baseEn from '../../../../../public/locales/en/bf-base.json';
import { LanguageType } from 'App/widget/types';

interface Props {
  language?: LanguageType;
}

export const AppTranslationProvider: React.FC<Props> = ( {
  language = 'de',
  children,
} ) => {
  const [ state, setState ] = React.useState<{ i18n : i18nType } | null>( null );
  const promise = React.useMemo<Promise<{ i18n: i18nType}>>( () => {
    return i18n.use( initReactI18next ).init( {
      lng: language,
      fallbackLng: language,
      keySeparator: '.',
      saveMissing: false,
      updateMissing: false,
      nsSeparator: ':',
      defaultNS: 'widgets',
      fallbackNS: 'base',
      initImmediate: true,
      interpolation: { escapeValue: false },
      resources: {
        en: { widgets: widgetsEn, base: baseEn },
        de: { widgets: widgetsDe, base: baseDe },
      },
    } ).then( () => {
      return { i18n: i18n };
    } );
  }, [ language ] );

  React.useEffect( () => {
    promise.then( ( i18nResult ) => { setState( i18nResult ); } );
  }, [ promise ] );

  return (
    <>
      { state === null ? ( <div>Initializing language...</div> ) : (
        <I18nContext.Provider value={ state }>
          { children }
        </I18nContext.Provider>
      ) }
    </>
  );
};
