import React from 'react';
import AsyncPaginate from 'react-select-async-paginate';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { PartnersApiListPartnersRequest, PartnerClass } from '@emilgroup/partner-sdk';
import { DropdownIndicatorTagForBg, NullIndicator } from 'App/components/utils/react-select/indicators';
import { useAppDefaultStyles } from 'Services/stylings';
import { useAppLogger } from 'Services/logger';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { ContainerFieldItem, ILeadData, ITariffDataStep } from 'Services/widgets/interfaces';
import { usePartnerRolesApi, usePartnersApi } from './sdk-hooks';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { PolicyEditData } from '../../../PolicyEdit';
import { TooltipCore } from '../TooltipCore';
import { blankLink } from 'config';
import { isCheckedByField, partnerKey, renderClassNameBox } from '../../../core-hooks';
import { useWidgetService } from 'Services/widget';
import { PartnerRolesApiListPartnerRoleRequest } from '@emilgroup/insurance-sdk';
import { htmlTagsRegex } from './CoreAddressAutoComplete';

export interface CorePartnerSelectProps {
  stepItem: ContainerFieldItem;
  formData: ITariffDataStep;
  productData: IDataFactorsAndVariables | PolicyEditData;
  isDisabled?: boolean;
}

type PartnerOptionClass = PartnerClass & {
  label: string;
  labelExt: JSX.Element | string | null;
};

const bgColorSelected = '#ECF5FA';
const limitRequest: number = 3;
const partnerCodeKey = 'partnerCode';

const evalInScope = ( code: string, contextAsScope: object ): any => {
  // eslint-disable-next-line no-new-func
  return new Function( `with (this) { return (${code}); }` ).call( contextAsScope );
};

export const CorePartnerSelect: React.FC<CorePartnerSelectProps> = ( { stepItem, formData, isDisabled } ) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const { setValue, control, getValues, errors } = useFormContext();
  const service = useWidgetService();
  const defaulStyles = useAppDefaultStyles();
  const partnersApi = usePartnersApi();
  const partnerRolesApi = usePartnerRolesApi();
  const logger = useAppLogger();
  const { showAlert, hideAlert } = useAppAlertService();
  const { label: labelField, filter, order, optionLabel, pageSize, partnerRoleName, tooltip } = stepItem;

  const [ showTooltip, setShowTooltip ] = React.useState<boolean>( false );
  const [ bfTooltip, setBfTooltip ] = React.useState<string>( '' );
  const [ targetLink, setTargetLink ] = React.useState<React.ReactInstance | undefined>( undefined );

  const previousLimitReques = React.useRef<number>( 0 );
  const isLoading = React.useRef<boolean>( false );
  const controlName = `${stepItem.id}_${stepItem.fieldName}`;

  const onShowTooltip = React.useCallback ( (
    isShow: boolean, tooltipHtml: string, target?: React.ReactInstance,
  ): void => {
    setShowTooltip( isShow );
    setBfTooltip( tooltipHtml );
    if ( target ) {
      setTargetLink( target );
    }
  }, [] );

  const evalExpr = React.useCallback( ( template: string, ctx: object ): string => {
    const code = template;
    try {
      const s = evalInScope( code, ctx );

      return s;
    } catch ( e ) {
      logger.error( `Error evaluating [${code}] with context: ${e}` );

      return ( e as Error ).message;
    }

  }, [ logger ] );

  const getPartnerValue = React.useCallback(
    ( partner?: PartnerOptionClass ): any => partner,
    [] );

  const getPartnerLabel = React.useCallback(
    ( partner: PartnerClass | undefined ) => {
      if ( !partner ) {
        return '';
      }

      // eslint-disable-next-line no-template-curly-in-string
      const expr = optionLabel ?? '`${ partner.displayName }`';

      const label = evalExpr( expr, { partner } );

      return label;
    }, [ evalExpr, optionLabel ] );

  const formatPartnerLabel = React.useCallback(
    ( partner: PartnerClass | undefined, searchQuery: string ) => {
      const label = getPartnerLabel( partner );

      if ( !searchQuery || !label ) {
        return label;
      }

      const parts: JSX.Element[] = [];
      let pos = 0;
      const searchLen = searchQuery.length;
      do {
        const index = label.toUpperCase().indexOf( searchQuery.toUpperCase(), pos );
        if ( index >= 0 ) {
          if ( index > 0 ) {
            parts.push( <span key={ `span-${pos}` }>{ label.substring( pos, index ) }</span> );
          }
          parts.push( <b key={ `b-${pos}` }>{ label.substring( index, index + searchLen ) }</b> );

          pos = index + searchLen;
        } else {
          parts.push( <span key={ `span-${pos}` }>{ label.substring( pos ) }</span> );
          break;
        }
      } while ( true );

      return (
        <React.Fragment>
          { parts }
        </React.Fragment>
      );
    }, [ getPartnerLabel ] );

  const convertPartnerToOption = React.useCallback(
    ( partner: PartnerClass, seachQuery: string ): PartnerOptionClass => ( {
      ...partner,
      label: getPartnerLabel( partner ),
      labelExt: formatPartnerLabel( partner, seachQuery ),
    } ), [ formatPartnerLabel, getPartnerLabel ] );

  const initialValue = ( formData ? formData[controlName] : '' );
  const defaultValue = ( initialValue ? convertPartnerToOption( initialValue, '' ) : undefined );

  const [ selectedPartner, setSelectedPartner ] = React.useState<PartnerOptionClass | undefined>( defaultValue );

  const loadOptions = async ( searchQuery, loadedOptions, { page } ) => {
    const emptyOptions = {
      options: [],
      hasMore: false,
      additional: {
        page,
      },
    };

    try {
      const query = ( searchQuery ?? '' ).trim();
      hideAlert();

      const request: PartnersApiListPartnersRequest = {
        filters: evalExpr( filter!, { searchInput: query } ),
        pageToken: `${page}`,
        pageSize,
        order: evalExpr( order!, {} ),
      };

      const response = await partnersApi.listPartners( request );

      return {
        options: response.data.items ?
          response.data.items.map( ( i ) => convertPartnerToOption( i, searchQuery ) ) : [],
        hasMore: response.data.items ? response.data.items.length >= 1 : false,
        additional: {
          page: page + 1,
        },
      };
    } catch ( e ) {
      logger.error( e );
      showAlert( {
        message: t( 'base:forms.messages.error' ),
        type: 'danger',
      } );

      return emptyOptions;
    }
  };

  const getPartnerRoleCodeByName = React.useCallback( async (): Promise<string> => {
    try {
      let partnerRoleCode = '';
      const errMessageLog = 'Not found partner Role';

      if ( !partnerRoleName ) {
        logger.error( errMessageLog );
        return '';
      }

      const request: PartnerRolesApiListPartnerRoleRequest = {
        filters: `name==${partnerRoleName}`,
        pageSize: 1,
      };

      const response = await partnerRolesApi.listPartnerRole( request );
      const { items } = response.data;

      if ( items && items[0] && items[0].code ) {
        partnerRoleCode = items[0].code;
      } else {
        logger.error( errMessageLog );
      }

      return partnerRoleCode;
    } catch ( e ) {
      logger.error( `Error in data update: ${e}` );
      return '';
    }
  }, [ logger, partnerRoleName, partnerRolesApi ] );

  const handleSelect = React.useCallback( async ( item: ValueType<PartnerOptionClass> ): Promise<void> => {
    const partner = item as PartnerOptionClass;
    setSelectedPartner( partner );
    setValue( controlName, partner, { shouldValidate: true } );

    const { code } = partner || {};
    const partnerRoleCode = code ? await getPartnerRoleCodeByName() : '';
    const partnerData = {
      partnerCode: code,
      partnerRoleCode,
    };

    await service.savedInfo( partnerKey, partnerData );

  }, [ controlName, getPartnerRoleCodeByName, service, setValue ] );

  const customStyles = React.useMemo( () => {
    return {
      option: ( styles, { isFocused, isSelected } ) => {
        return {
          ...styles,
          backgroundColor: isFocused | isSelected ? bgColorSelected : null,
          color: defaulStyles.textColor,
        };
      },
    };
  }, [ defaulStyles.textColor ] );

  React.useEffect( () => {
    let isMounted = true;

    if ( previousLimitReques.current >= limitRequest || isLoading.current ) {
      return;
    }

    const checkedValue = async ( ) => {
      try {
        const partnerField = getValues( controlName );
        const leadData: ILeadData = await service.getLead( 0 );

        if ( leadData && leadData[partnerKey] && leadData[partnerKey][partnerCodeKey]
          && !partnerField && !defaultValue ) {
          isLoading.current = true;

          const request: PartnersApiListPartnersRequest = {
            filters: `code==${leadData[partnerKey][partnerCodeKey]}`,
            pageSize: 1,
          };

          const response = await partnersApi.listPartners( request );
          const { items } = response.data;

          if ( items && items[0] && isMounted ) {
            const res = convertPartnerToOption( items[0], '' );
            setValue( controlName, res, { shouldValidate: true } );
            setSelectedPartner( res );
          }

          isLoading.current = false;
          previousLimitReques.current = previousLimitReques.current + 1;
        }

      } catch( e ) {
        logger.error( `Error in data update: ${e}` );
      }
    };

    setTimeout( () => {
      checkedValue();
    }, 500 );

    return () => {
      isMounted = false;
    };
  }, [ controlName, convertPartnerToOption, logger, partnersApi, setValue, getValues, service, defaultValue ] );

  return (
    <div>
      { labelField && (
        <Form.Label id={ `${controlName}-label` } className={ tooltip ? 'tooltip-label' : '' }>
          <div
            className="d-inline-block"
            dangerouslySetInnerHTML={ { __html: `${labelField}` } }
          />
          { tooltip && (
            <a
              className="tooltip-info"
              href={ blankLink }
              role='button'
              onClick={ ( e ) => {
                e.preventDefault();
                onShowTooltip( true, tooltip!, e.target as unknown as React.ReactInstance );
              } }
            >
              { t( 'bookingFunnel.tooltipHelp' ) }
            </a>
          ) }
        </Form.Label>
      ) }
      <Controller
        id={ controlName }
        name={ controlName }
        control={ control }
        rules={ { required: isDisabled ? false : isCheckedByField( 'isRequired', stepItem! ) } }
        defaultValue={ defaultValue || '' }
        render={ ( { name } ) => (
          <FormGroup
            controlId={ name }
            className={ `${ renderClassNameBox( stepItem ) }${errors[name] ? ' core-input-error' : '' }` }
          >
            <AsyncPaginate
              name={ name }
              id={ name }
              className="select-values-list"
              classNamePrefix="select-item"
              placeholder={ t( 'partnerSelect.placeholder' ) }
              defaultValue={ selectedPartner }
              value={ selectedPartner }
              components={ {
                DropdownIndicator: DropdownIndicatorTagForBg,
                IndicatorSeparator: NullIndicator,
              } }
              cacheOptions
              loadOptions={ loadOptions }
              getOptionValue={ ( option ) => getPartnerValue( option ) }
              getOptionLabel={ ( option ) => option.label }
              formatOptionLabel={ ( option ) => option.labelExt }
              blurInputOnSelect={ true }
              isMulti={ false }
              isClearable={ true }
              isSearchable={ true }
              escapeClearsValue
              menuPlacement="bottom"
              onChange={ handleSelect }
              styles={ customStyles }
              maxMenuHeight={ 200 }
              isDisabled={ isDisabled || isLoading.current }
              additional={ {
                page: 1,
              } }
              noOptionsMessage={ () => t( 'partnerSelect.noOptions' ) }
              debounceTimeout={ 300 }
            />
            <Form.Control
              type="hidden"
              isInvalid={ errors[name] !== undefined }
            />
            { labelField && (
              <FormControl.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired', {
                  fieldLabel: labelField.replace( htmlTagsRegex, '' ),
                } ) }
              </FormControl.Feedback>
            ) }
          </FormGroup>
        ) }
      />
      { showTooltip && bfTooltip && (
        <TooltipCore
          tooltipInfo={ bfTooltip }
          onClose={ () => onShowTooltip( false, '' ) }
          targetLink={ targetLink }
        />
      ) }
    </div>
  );
};
