import React from 'react';
import rightArrowIconUrl from 'assets/bf-images/funk/right_arrow.svg';
import rightArrowGreenIconUrl from 'assets/bf-images/funk/right_arrow_green.svg';
import documentIcon from 'assets/bf-images/document_icon.svg';
import { useTranslation } from 'react-i18next';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSummaryValidationSchema } from '../../validation';
import { AppAlertUI, useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import {
  ContainerItemConsent,
  ILeadData,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { LoadingSpinner, useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { ViewSummaryPage } from '../../ui-components/ViewSummaryPage';
import {
  useCoreActions,
  ActiveStepCore,
  ActiveSubStepCore,
  RecalculationTariff,
} from '../../DynamicCore';
import { TariffInfo } from '../../ui-components/TariffInfo';
import { IScreenType } from 'Services/widgets/interfaces';
import { useWidgetService } from 'Services/widget';
import { isEmpty, isEqual } from 'lodash';
import { MobileNavCore } from '../../ui-components/MobileNavCore';
import { DocumentDownloadInfo } from '../../ui-components/summary-views/DocumentDownloadInfo';
import { isEnabledPremiumCalculation } from '../../core-hooks';
import { mainStepName } from 'App/components/widgets/booking-funnel/steps/PolicyStep1';
import { StepInfoItem } from '../../interfaces';
import { JSONSubSteps } from '../../../booking-funnel/enum';
import { Checkbox } from '../../ui-elements/Checkbox';
import { useAppLogger } from 'Services/logger';
import FileSaver from 'file-saver';
import { SavedDataPopup } from '../../ui-components/SavedDataPopup';

export interface SummarySubStepProps {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  screenType: IScreenType;
  recalculationTariff: RecalculationTariff;
  stepData: StepInfoItem;
  policyStepNames: string[];
}

export const SummarySubStep: React.FC<SummarySubStepProps> = (
  { productCode, lead, productData, screenType, recalculationTariff, stepData, policyStepNames },
) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const { showAlert, hideAlert } = useAppAlertService();
  const service = useWidgetService();
  const actions = useCoreActions();
  const logger = useAppLogger();
  const previousFormData = React.useRef<ITariffDataStep>( {} );
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const steps = productData.stepsConfig?.steps!.find( ( s ) => s.name === mainStepName );
  const [ isLoading, setIsLoading ] = React.useState( false );
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );

  const keySummaryData = stepData.nameStep;

  const defaultConsent: ContainerItemConsent[] = [
    {
      name: 'isCreditCheck',
      title: t( 'bookingFunnel.summary.confirmLabel' ),
    },
    {
      name: 'isValidData',
      title: t( 'bookingFunnel.summary.agreeLabel' ),
    },
  ];

  const summaryStep = stepData.steps && stepData.steps.find(
    ( subStep ) => subStep.name === keySummaryData && subStep.subStepName === keySummaryData,
  );
  const consentSubStep = summaryStep && summaryStep.containers
    .find( ( subStep ) => subStep.name === JSONSubSteps.Consent );
  const checkboxesContent = consentSubStep ?
    consentSubStep.items as unknown as ContainerItemConsent[] : defaultConsent;

  const initialData = {};
  checkboxesContent.forEach( ( checkboxContent ) => { initialData[checkboxContent.name] = false; } );
  const summaryValidationSchema = useSummaryValidationSchema( checkboxesContent );

  let summaryDataFields = lead[keySummaryData] && lead[keySummaryData] !== null ? lead[keySummaryData] : initialData;

  const formContext = useForm( {
    defaultValues: summaryDataFields,
    mode: 'onChange',
    resolver: yupResolver( summaryValidationSchema ),
  } );

  const { formState, getValues } = formContext;

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    const result = await service.savedInfo( keySummaryData, formData );
    if ( result.errorCode === 0 && actions.goToSummaryStep ) {
      actions.goToSummaryStep( ActiveStepCore.Summary, ActiveSubStepCore.SummarySubStep2, false );
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, keySummaryData, service, showAlert, t ] );

  const onCloseSavedPopup = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    actions.reloadLead();
    setShowSavedStep( isShow );
  }, [ actions ] );

  const downloadStaticPdfHandler = React.useCallback(
    async ( e: React.MouseEvent<HTMLAnchorElement>,
      name: string,
      entityType: string,
    ): Promise<void> => {
      e.preventDefault();
      hideAlert();

      const document = productData.staticDocuments.find( ( d ) => d.entityType === entityType );

      if( !document ) {
        showAlert( {
          message: t( 'base:forms.messages.error' ),
          type: 'danger',
        } );

        return;
      }

      setIsLoading( true );

      return service.downloadStaticPdfDocument( document.code )
        .then( ( res ) => {
          const blob = new Blob( [ res ], {
            type: 'application/pdf',
          } );
          FileSaver.saveAs( blob, `${name}.pdf` );
          setIsLoading( false );
        } )
        .catch( ( error ) => {
          logger.error( error );
          showAlert( {
            message: t( 'base:forms.messages.error' ),
            type: 'danger',
          } );
          setIsLoading( false );
        } );
    }, [ hideAlert, logger, productData.staticDocuments, service, showAlert, t ] );

  React.useEffect( () => {
    if ( !formState.isValid ) {
      return;
    }

    const formValues = getValues();
    const saveFormValues = async () => {
      try {
        await service.savedInfo( keySummaryData, previousFormData.current );
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };
    if ( isEmpty( previousFormData.current ) ) {
      previousFormData.current = formValues;
      return;
    }
    if ( !isEqual( formValues, previousFormData.current ) ) {
      previousFormData.current = formValues;
      saveFormValues();
    }
  }, [ actions, formState.isSubmitting, formState.isValid,
    formState.isValidating, getValues, keySummaryData, service, showAlert, t ] );

  if ( lead[stepData.previousStep!] === null ) {
    actions.goToStep( stepData.previousStep!, false );
  }

  if ( productData === null ) {
    return <div className="bf-loading col-auto py-4"><LoadingSpinner /></div>;
  }

  const enabledPremiumCalculation = isEnabledPremiumCalculation( stepData, lead );

  return (
    <FormProvider { ...formContext }>
      <div id="summary-step">
        { enabledPremiumCalculation && (
          <TariffInfo
            lead={ lead }
            productData={ productData }
            productCode={ productCode }
            screenType={ screenType }
            showSavePopup={ onSaved }
            isIgnoreOnRecalculation={ true }
            recalculationTariff={ recalculationTariff }
            subSteps={ steps?.subSteps }
            keyStep={ stepData.nameStep }
            policyStepNames={ policyStepNames }
          />
        ) }
        <div className="bf-custom-padding">
          <Row className="mt-5 max-w-667">
            { isLoading && loadingOnFullContainer }
            <Col md={ 12 } className="px-0" id="alert-summary-page">
              <AppAlertUI />
            </Col>
            { summaryStep && summaryStep.stepTitle && (
              <Col md={ 12 } className="head-tab mb-2 text-center mx-0">
                <div dangerouslySetInnerHTML={
                  { __html: summaryStep.stepTitle }
                }
                >
                </div>
              </Col>
            ) }
            <Col className="mt-3 px-0 bf-data-info max-w-550" md={ 12 }>
              <ViewSummaryPage
                lead={ lead }
                productData={ productData! }
                policyStepNames={ policyStepNames }
              />
            </Col>
          </Row>
          <Row className="bf-summary-footer max-w-667 mt-5">
            <Col md={ 12 } className="max-w-610 px-0">
              <h2 className="text-center f-26 ">{ t( 'bookingFunnel.summary.footerTitle' ) }</h2>
              <p className="f-16 text-justify m-0" dangerouslySetInnerHTML=
                {
                  { __html: t( 'bookingFunnel.summary.footerInfo' ) }
                }
              >
              </p>
            </Col>
            <DocumentDownloadInfo
              isOpened={ false }
              iconCard={ documentIcon }
              productCode={ productCode }
              lead={ lead }
              productData={ productData! }
              policyStepNames={ policyStepNames }
            />
            <Col md={ 12 } className="mt-4 px-0">
              <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
                <Row className="max-w-610">
                  {
                    checkboxesContent.map( ( checkboxContent ) => {
                      const { name, title: label, linkItems } = checkboxContent;
                      return (
                        <Checkbox
                          key={ name }
                          name={ name }
                          label={ label }
                          linkItems={ linkItems }
                          defaultValue={ initialData[name] }
                          formContext={ formContext }
                          downloadStaticPdfHandler={ downloadStaticPdfHandler }
                        />
                      );} )
                  }
                  { summaryStep && summaryStep.requiredText && (
                    <Col sm={ 12 } className="mb-4 px-0">
                      <p className="f-12">{ summaryStep.requiredText }</p>
                    </Col>
                  ) }
                </Row>
                <MobileNavCore
                  lead={ lead }
                />
                <div className="dynamic-btn-panel mx-0 d-flex justify-content-space-between">
                  <Button
                    id={ `${stepData.nameStep}_back` }
                    type="button"
                    variant="link"
                    className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
                    onClick={ () => actions.goToStep( stepData.previousStep!, false ) }
                  >
                    { t( 'bookingFunnel.previousBtn' ) }
                  </Button>
                  <Button
                    id={ `${stepData.nameStep}_next` }
                    type="submit"
                    variant={ !formState.isValid ? 'outline-primary' : 'primary' }
                    disabled={ formState.isSubmitting }
                    className="mr-0 mb-0 bg-btn-primary"
                  >
                    { t( 'bookingFunnel.nextBtn' ) }
                    <img
                      src={ !formState.isValid ? rightArrowGreenIconUrl : rightArrowIconUrl }
                      alt={ t( 'bookingFunnel.nextBtn' ) }
                    />
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      { isShowSavedStep && (
        <SavedDataPopup
          currentStep={ stepData.nameStep }
          productCode={ productCode }
          lead={ lead }
          productData={ productData! }
          screenType={ screenType }
          onClose={ () => onCloseSavedPopup( ) }
          goNextStep={ goNextStep }
          policyStepNames={ policyStepNames }
        />
      ) }
    </FormProvider>
  );
};
