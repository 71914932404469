export enum FunkBFStep {
  TariffResultStep = 'step1_tariffResult',
  TariffChangeStep = 'step2_tariffChange',
  PersonalDataStep = 'step3_personalData',
  RegistrationStep = 'step3_registration',
  LoginStep = 'step3_login',
  SmsStep = 'step3_smsLogin',
  SummaryStep = 'step4_summarySubStep',
  PaymentStep = 'step4_paymentSubStep',
  PaymentApprovementStep = 'step5_paymentApprovement'
}

export enum FieldsFunkCore {
  AddFullAddress = 'addFullAddress',
  FullAddress = 'fullAddress'
}

export enum FunkLeadStatus {
  Draft = 'Entwurf',
}

export enum COREBFStep {
  PersonalDataStep = 'personalData',
  RegistrationStep = 'registration',
  LoginStep = 'login',
  SmsStep = 'smsLogin',
  SummaryStep = 'summaryStep',
  PaymentStep = 'paymentStep',
  PaymentApprovementStep = 'paymentApprovement'
}

export enum AddressType {
  InsuredAddress = 'insuredAddress',
  AccountAddress = 'accountAddress'
}

export enum LayoutTypes {
  Default = 'default',
};

export enum SystemProductFieldReadOnly {
  PolicyDurationUnit = 'policyDurationUnit',
  PolicyDurationValue = 'policyDurationValue',
  BillingFrequencyUnit = 'billingFrequencyUnit',
  BillingFrequencyValue = 'billingFrequencyValue',
  PolicyRenewalFrequency = 'policyRenewalFrequency',
  RecurringBillingDay = 'recurringBillingDay',
  RecurringPaymentDay = 'recurringPaymentDay',
  BillingMethod = 'billingMethod',
  IsRecurringBilling = 'isRecurringBilling',
  BrokerID = 'brokerID',
  PolicyStartDate = 'policyStartDate',
}
