export enum FunkBFStep {
  TariffResultStep = 'step1_tariffResult',
  TariffChangeStep = 'step2_tariffChange',
  PersonalDataStep = 'step3_personalData',
  RegistrationStep = 'step3_registration',
  LoginStep = 'step3_login',
  SmsStep = 'step3_smsLogin',
  SummaryStep = 'step4_summarySubStep',
  PaymentStep = 'step4_paymentSubStep',
  PaymentApprovementStep = 'step5_paymentApprovement'
}

export enum FieldsFunkReanovo {
  AddFullAddress = 'addFullAddress',
  FullAddress = 'fullAddress'
}

export enum FunkLeadStatus {
  Draft = 'Entwurf',
}
