import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BaseRequest } from 'Services/base';
import { Base64 } from 'js-base64';
import { IAppUserMeta } from 'App/types';

dayjs.extend( utc );

export class RefreshTokenRequest extends BaseRequest {
  constructor(
    userName: string,
    tenantSlug: string,
  ) {
    super( {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: '/v1/customers/refresh-token',
      data: { username: userName, tenantSlug },
      withCredentials: true,
      responseType: 'json',
      loginRequest: true,
    }, false );
  }
}

export interface TokenResponse {
  /**
   * Access token, contains some info about the user
   */
  accessToken: string;
  /**
   * Refresh Token
   */
  refreshToken?: string;
}

/**
 * User requestData that is collected from id token
 */
export interface IUserInfo {
  email: string;
  tenant_id: number;
  tenant_slug: string;
  tenant_name: string | undefined;
  given_name: string;
  family_name: string;
  role: string;
  tier: string | undefined;
  sub: string;
  tenant_hierarchy: string;
}

export interface IToken {
  readonly value: string;
  /**
   * Expiration Time in UTC
   */
  readonly exp: dayjs.Dayjs;
  /**
   * Issued At Time in UTC
   */
  readonly iat: dayjs.Dayjs;
  /**
   * Tenant slug used to refresh token
   */
  readonly tenantSlug: string;
  /**
   * User name used to refresh token
   */
  readonly userName: string;
}

/**
 * Base auth build based on id token and access token without local account context.
 */
export interface IBaseAuth {
  userInfo: IUserInfo;
  token: IToken;
}
// sub: Subject
/**
 * Full auth with account context
 */
export interface IFullAuth extends IBaseAuth {
  accountIndex: number;
  userMeta: IAppUserMeta;
}

export interface ITenantInfo {
  id: number;
  name: string;
  slug: string;
}

export const parseTokenResponse = ( tokenResponse: TokenResponse ): IBaseAuth => {
  const accessToken = tokenResponse.accessToken;
  const parsedToken: object = JSON.parse( Base64.decode( accessToken.split( '.' )[1] ) );

  const userInfo: IUserInfo = {
    email: parsedToken['email'],
    role: parsedToken['custom:role'],
    tier: parsedToken['custom:tier'],
    tenant_id: Number( parsedToken['custom:tenant_id'] ),
    tenant_slug: parsedToken['custom:tenant_slug'],
    tenant_name: parsedToken['custom:tenant_name'],
    given_name: parsedToken['given_name'],
    family_name: parsedToken['family_name'],
    sub: parsedToken['sub'],
    tenant_hierarchy: parsedToken['custom:tenant_hierarchy'],
  };

  const createdAt: dayjs.Dayjs = dayjs.unix( parsedToken['iat'] as number ).utc();
  const expiredAt: dayjs.Dayjs = dayjs.unix( parsedToken['exp'] as number ).utc();

  return {
    token: {
      value: accessToken,
      iat: createdAt,
      exp: expiredAt,
      tenantSlug: userInfo.tenant_slug,
      userName: userInfo.email,
    },
    userInfo: userInfo,
  };
};
