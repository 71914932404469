import React from 'react';
import { ContainerFieldItem, ILeadData, ITariffDataStep } from 'Services/widgets/interfaces';
import { JSONItemUIType } from 'App/components/widgets/booking-funnel/enum';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { PolicyEditData } from '../../../PolicyEdit';
import { CoreList } from './CoreList';
import { CoreFactorSelect } from './CoreFactorSelect';
import { StepInfoItem } from '../../../interfaces';

export interface CoreExtraListPanelProps {
  stepItem: ContainerFieldItem;
  leadData: ILeadData;
  formData: ITariffDataStep;
  productCode: string;
  productData: IDataFactorsAndVariables | PolicyEditData;
  isDisabled?: boolean;
  stepData: StepInfoItem;
}

export const CoreExtraListPanel: React.FC<CoreExtraListPanelProps> = ( props ) => {
  const { stepItem } = props;
  const isUiTypeSelect: boolean = stepItem.uiType === JSONItemUIType.Select || false;

  return (
    <>
      { isUiTypeSelect ? (
        <CoreFactorSelect
          { ...props }
        />
      ) : (
        <CoreList
          { ...props }
        />
      ) }
    </>
  );
};
