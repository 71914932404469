import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { IEmailData } from './ui-components/SavedDataPopup';
import { PersonalDataStepCore } from './steps/personal-sub-steps/RegistrationStep';
import { ICFFactorTypeOption } from '../factor-service';
import { phoneValidationRegExp } from '../bf-hooks';
import { LoginData } from './steps/personal-sub-steps/LoginStep';
import { Title } from 'Services/widgets/enums';
import { ContainerItemConsent } from 'Services/widgets/interfaces';

export const usePersonalDataValidationSchemaFunk =
  ( isAddressSingleLine: boolean = false ): yup.ObjectSchema<PersonalDataStepCore> => {
    const { t } = useTranslation( [ 'widgets', 'base' ] );
    const schema = React.useMemo( () => {
      const baseFormValidationSchema = {
        gender: yup.string().required(),
        title: yup.mixed<Title>().required(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        zipCode: yup.lazy( ( val ) => {
          switch ( typeof val ) {
            case 'object':
              return yup.object<ICFFactorTypeOption>( {
                name: yup.string(),
                key: yup.string(),
                value: yup.number(),
              } ).required(); // schema for object
            case 'string':
              return yup.string().required(); // schema for string
            default:
              return yup.mixed().notRequired(); // here you can decide what is the default
          }
        } ),
        city: yup.string().required(),
        street: yup.string().required(),
        houseNumber: yup.string().required(),
        phone: yup.string()
          .required( t( 'base:forms.messages.fieldRequired',
            { fieldLabel: t( 'bookingFunnel.personalData.phone' ) } ) )
          .matches( phoneValidationRegExp, t( 'bookingFunnel.personalData.phoneFormatError' ) ),
        email: yup.string().required( t( 'base:forms.messages.fieldRequired',
          { fieldLabel: t( 'bookingFunnel.personalData.email' ) } ) )
          .email( t( 'bookingFunnel.personalData.emailFormatError' ) ),
        country: yup.string().required(),
      };

      let formValidationSchema;

      if ( isAddressSingleLine ) {
        formValidationSchema = yup.object<PersonalDataStepCore>( {
          ...baseFormValidationSchema,
          address: yup.string()
            .matches( /\b[\w\s.]+ \d+,\s+\d{5} [\w\s.]+\b/, t( 'base:forms.messages.addressInvalid' ) )
            .required( t( 'base:forms.messages.addressInvalid' ) ),
        } ).required();
      } else {
        formValidationSchema = yup.object<PersonalDataStepCore>( {
          ...baseFormValidationSchema,
        } ).required();
      }

      return formValidationSchema;
    }, [ isAddressSingleLine, t ] );
    return schema;
  };

export const useSummaryValidationSchema = ( structure: ContainerItemConsent[] ): yup.ObjectSchema => {
  const summaryStructure = {};
  structure.forEach( ( item ) => {
    if ( item.isRequired ) {
      summaryStructure[item.name] = yup.boolean().oneOf( [ true ] );
    }
  } );

  const schema = React.useMemo( () => {
    const formValidationSchema = yup.object( summaryStructure ).required();

    return formValidationSchema;
  }, [ summaryStructure ] );
  return schema;
};

export const useEmailDataValidationSchemaFunk = (): yup.ObjectSchema<IEmailData> => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );

  const schema = React.useMemo( () => {
    const formValidationSchema = yup.object<IEmailData>( {
      email: yup.string().required( t( 'base:forms.messages.fieldRequired',
        { fieldLabel: t( 'bookingFunnel.funkReanovo.emailPopup.emailErrorField' ) } ) )
        .email( t( 'bookingFunnel.personalData.emailFormatError' ) ),
      confirmEmail: yup.string().required( t( 'base:forms.messages.fieldRequired',
        { fieldLabel: t( 'bookingFunnel.funkReanovo.emailPopup.confirmEmailErrorField' ) },
      ) ).test( 'email-match', () =>
        ( t( 'bookingFunnel.funkReanovo.emailPopup.emailErrorMessage' ) ), function( value ) {
        return this.parent.email === value;
      } ),
      confirmationEmailData: yup.boolean().oneOf( [ true ] ),
    } ).required();

    return formValidationSchema;
  }, [ t ] );
  return schema;
};

export const useLoginDataValidationSchemaFunk = (): yup.ObjectSchema<LoginData> => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );

  const schema = React.useMemo( () => {
    const formValidationSchema = yup.object<LoginData>( {
      username: yup.string().required( t( 'base:forms.messages.fieldRequired',
        { fieldLabel: t( 'bookingFunnel.funkMesse.login.emailPlaceholder' ) } ) )
        .email( t( 'bookingFunnel.personalData.emailFormatError' ) ),
      password: yup.string().required( t( 'base:forms.messages.fieldRequired',
        { fieldLabel: t( 'bookingFunnel.funkMesse.login.passwordPlaceholder' ) } ) ),
    } ).required();

    return formValidationSchema;
  }, [ t ] );
  return schema;
};
