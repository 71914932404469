import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import saveIconUrl from 'assets/bf-images/funk/save_icon.svg';
import savePopupIconUrl from 'assets/bf-images/funk/save_icon_popup.svg';
import closeIconUrl from 'assets/bf-images/funk/close_icon.svg';
import { Button, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import {
  ILeadData,
  IPolicyObject,
  IProductDataForInvoice,
  IScreenType,
  SubStepItem,
} from 'Services/widgets/interfaces';
import {
  renderPrice,
  prepareDataForTariff,
  keyMultiInsuredObjects,
  generateVariables,
  generateRules,
  preparePayloadRule,
  preparePolicyPayload,
} from '../core-hooks';
import { defaultDateFormat } from 'App/ui-utils';
import { useWidgetService } from 'Services/widget';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { InvoiceResultCore } from '../interfaces';
import { IDataFactorsAndVariables } from '../../booking-funnel/BookingFunnel';
import { isEmpty } from 'lodash';
import { evalFunction } from '../../booking-funnel/functions';
import { useFormContext } from 'react-hook-form';
import { useAppLogger } from 'Services/logger';
import { UnderwritingResult } from 'Services/widgets/enums';
import { RecalculationTariff } from '../DynamicCore';
import { useLocale } from '../../booking-funnel/booking-funnel-hooks';
import { oneTimePayment } from 'App/components/widgets/bf-hooks';

export interface TariffInfoProps {
  lead: ILeadData;
  productCode: string;
  productData: IDataFactorsAndVariables;
  screenType: IScreenType;
  isShowSavedInfo?: boolean;
  isIgnoreOnRecalculation: boolean;
  showSavePopup?: ( isShow: boolean ) => void;
  showWelcomePopup?: ( isShow: boolean ) => void;
  recalculationTariff: RecalculationTariff;
  subSteps?: SubStepItem[];
  keyStep: string;
  policyStepNames?: string[];
  className?: string;
}

export const keyInvoiceResult = 'invoiceResult';

const defaultResult: InvoiceResultCore = {
  netAmount: 0,
  taxAmount: 0,
  grossAmount: 0,
  creditAmount: 0,
  unit: 'year',
};

export const TariffInfo: React.FC<TariffInfoProps> = ( props ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { showAlert } = useAppAlertService();
  const locale = useLocale();
  const service = useWidgetService();
  const logger = useAppLogger();
  const {
    lead, productCode, productData, screenType,
    isShowSavedInfo, isIgnoreOnRecalculation,
    recalculationTariff, showSavePopup, showWelcomePopup, subSteps,
    keyStep, policyStepNames, className = 'max-w-744',
  } = props;

  const { isCheckedUnderwriting, recalculationTrigger } = recalculationTariff;
  const { formState } = useFormContext();
  const isFirstRender = React.useRef<boolean | null>( null );
  const previousRecalculationTrigger = React.useRef<number | null>( null );
  const resultInvoiceData: InvoiceResultCore | null = lead[keyInvoiceResult] || null;
  const [ tariffResultData, setTariffResultData ] = React.useState<InvoiceResultCore | null>(
    resultInvoiceData || defaultResult );
  const [ isShowWelcome, setShowWelcome ] = React.useState<boolean>(
    lead.isShowWelcome !== undefined ? lead.isShowWelcome : false,
  );
  const defaultTitle = t( 'bookingFunnel.dynamicCore.tariffInfo' );
  const [ statusInfo, setStatusInfo ] = React.useState<string>( '' );
  const [ titleMessage, setTitleMessage ] = React.useState<string>( defaultTitle );

  const savedAt = lead.savedAt !== undefined ? dayjs( lead.savedAt ).format( defaultDateFormat ) :
    dayjs().format( defaultDateFormat );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    if ( showSavePopup ) {
      showSavePopup( isShow );
    }
  }, [ showSavePopup ] );

  const rulesInformer = React.useCallback( ( leadStore: ILeadData ): void => {
    if ( subSteps ) {
      const rules = generateRules( subSteps );

      setStatusInfo( '' );
      setTitleMessage( defaultTitle );

      if ( rules ) {
        let isSetInfo = false;
        rules.forEach( ( item ) => {
          const rejectionData = evalFunction( leadStore || [], item );

          if ( rejectionData ) {
            if ( isSetInfo ) {
              return;
            }

            if ( rejectionData['statusMessage'] ) {
              setStatusInfo( rejectionData['statusMessage'] );

              isSetInfo = true;
            }

            if ( rejectionData['titleMessage'] ) {
              setTitleMessage( rejectionData['titleMessage'] );

              isSetInfo = true;
            }
          }
        } );
      }
    }
  }, [ defaultTitle, subSteps ] );

  const checkedRules = React.useCallback( async ( leadStore: ILeadData, isReset?: boolean ): Promise<void> => {
    try {
      const invoiceResult = leadStore[keyInvoiceResult] as InvoiceResultCore;

      if ( ( !invoiceResult && !isReset ) || ( !isCheckedUnderwriting && !isReset ) ) {
        return;
      }

      const workflowSlug = productData.stepsConfig && productData.stepsConfig.workflowSlug
        ? productData.stepsConfig.workflowSlug : null;

      if ( !workflowSlug ) {
        rulesInformer( leadStore );

        return;
      }

      const workflow = await service.getWorkflow( workflowSlug );
      if ( workflow && workflow.code ) {
        const preparePayload = preparePayloadRule( leadStore, productData, policyStepNames! );
        const response = await service.getUnderwritingResult( workflow.code, preparePayload );

        const { result, statusMessage, errorMessage } = response.underwritingResult;

        if ( result && result === UnderwritingResult.Warning ) {
          setStatusInfo( statusMessage );
          setTitleMessage( errorMessage );
        }

        if ( result && result === UnderwritingResult.Passed ) {
          setStatusInfo( '' );
          setTitleMessage( defaultTitle );
        }
      } else {
        rulesInformer( leadStore );
      }
    } catch( error ) {
      logger.error( error );
    }
  }, [ defaultTitle, isCheckedUnderwriting, logger, policyStepNames, productData, rulesInformer, service ] );

  React.useEffect( () => {
    let isMounted = true;

    const loadTariff = async () => {
      try {
        if ( isMounted ) {
          const leadData: ILeadData = await service.getLead();
          const invoiceResult = leadData[keyInvoiceResult] as InvoiceResultCore;

          checkedRules( leadData );

          if ( formState.isValid && isFirstRender.current === null ) {
            isFirstRender.current = true;
            return;
          }

          if ( !formState.isValid || previousRecalculationTrigger.current === recalculationTrigger ) {
            return;
          }

          if ( !isEmpty( invoiceResult ) && invoiceResult.grossAmount > 0 ) {
            setTariffResultData( invoiceResult );
            return;
          }

          setTariffResultData( null );

          const tariffsData = preparePolicyPayload( leadData, policyStepNames! );

          if ( isIgnoreOnRecalculation && invoiceResult ) {
            setTariffResultData( defaultResult );
            return;
          }

          if ( isEmpty( tariffsData ) ) {
            setTariffResultData( defaultResult );
            return;
          }

          let tariffDataForMultiInsured: IPolicyObject[] = [];
          const multiInsuredObjects = leadData[keyMultiInsuredObjects];

          if ( multiInsuredObjects ) {
            const variables = generateVariables( multiInsuredObjects );

            tariffDataForMultiInsured = prepareDataForTariff(
              tariffsData,
              variables,
              multiInsuredObjects,
              false,
            );
          }

          const tariffData = prepareDataForTariff(
            tariffsData,
            productData.variables,
            productData.insuredObjects,
            false,
          );

          const policyObjects: IProductDataForInvoice = {
            policyObjects: [ ...tariffData, ...tariffDataForMultiInsured ],
          };

          const resultInvoice = await service.getProductInvoice( productCode, policyObjects );

          if ( resultInvoice ) {
            const resultTariff: InvoiceResultCore = {
              netAmount: resultInvoice.netAmount,
              taxAmount: resultInvoice.taxAmount,
              grossAmount: resultInvoice.grossAmount,
              creditAmount: resultInvoice.creditAmount,
              currency: resultInvoice.currency,
              unit: resultInvoice.invoiceItems[0].unit,
            };

            checkedRules( leadData, true );

            await service.savedInfo( keyInvoiceResult, resultTariff );

            setTariffResultData( resultTariff );
            previousRecalculationTrigger.current = recalculationTrigger;
          } else {
            setTariffResultData( defaultResult );
          }
        }
      } catch( e ) {
        setTariffResultData( defaultResult );

        if ( e instanceof Error ) {
          if ( isMounted ) {
            showAlert( {
              message: `Error in premium calculation. The reason is: ${e.message}`,
              type: 'danger',
            } );
          }
        } else {
          throw e;
        }
      }
    };

    setTimeout( () => {
      loadTariff();
    }, 1500 );

    return () => {
      isFirstRender.current = true;
      isMounted = false;
    };
  }, [ service, t, showAlert, productData.variables, productData.insuredObjects,
    productCode, recalculationTrigger, checkedRules, isIgnoreOnRecalculation,
    formState.isValid, keyStep, policyStepNames ] );

  const onClose = React.useCallback ( async ( isShow: boolean ): Promise<void> => {
    try {
      const result = await service.savedInfo( 'isShowWelcome', false );
      if ( result ) {
        setShowWelcome( isShow );

        if ( showWelcomePopup ) {
          showWelcomePopup( isShow );
        }
      }
    } catch( e ) {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ service, showAlert, showWelcomePopup, t ] );

  return (
    <Row id="price-panel" className={ `${ className } ${!isShowSavedInfo ? 'sticky-top' : ''}` }>
      <Col md={ 12 } className={
        `dynamic-tariff-info head-tab text-center mx-0 py-3 px-0 ${screenType.isMobile && 'no-border-radius '}`
      }
      >
        { !isShowWelcome && (
          <Fragment>
            { tariffResultData === null && (
              <div className="snippet" data-title="dot-flashing-th1">
                <div className="stage-top">
                  <div className="dot-flashing-th1"></div>
                </div>
              </div>
            ) }
            { tariffResultData !== null && (
              <Fragment>
                { statusInfo && (
                  <span className="tariff-head">{ statusInfo }</span>
                ) }
                <span className={ `tariff-price${statusInfo ? ' add-margin-top' : ''}` }>
                  { renderPrice( tariffResultData.grossAmount, locale, tariffResultData.currency!, true ) }
                </span>
              </Fragment>
            ) }
            <span className="tariff-time">
              { titleMessage }
              { tariffResultData && tariffResultData.unit && tariffResultData.unit !== oneTimePayment && (
                <span
                  className="text-current-color"
                > / { t( `bookingFunnel.dynamicCore.timeOptions.${tariffResultData.unit}` ) }
                </span>
              ) }
            </span>
          </Fragment>
        ) }
        { showSavePopup && !isShowWelcome && (
          <Button
            id={ `${keyStep}_save_data` }
            type="button"
            variant="primary"
            className={ `btn-save ${isShowSavedInfo ? 'btn-save-info' : ''}` }
            onClick={ () => onSaved( true ) }
          >
            <img src={ isShowSavedInfo ? savePopupIconUrl : saveIconUrl } alt="Save Alt" />
          </Button>
        ) }

        { isShowWelcome && (
          <div className="dynamic-welcome-info">
            <h2>{ t( 'bookingFunnel.funkReanovo.welcomeTitleSaved' ) }</h2>
            <p>
              <Trans
                i18nKey={ t( 'bookingFunnel.funk.welcomeTextInfo', { savedDate: `${savedAt}` } ) }
                ns="widgets"
                components={ {
                  textBold: <b>textBold</b>,
                } }
              />
            </p>
            <Button
              type="button"
              variant="primary"
              className="btn-close"
              onClick={ () => onClose( false ) }
            >
              <img src={ closeIconUrl } alt="Close Alt" />
            </Button>
          </div>
        ) }
      </Col>
    </Row>
  );
};
