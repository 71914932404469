import React, { Fragment } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { isEmpty, isEqual } from 'lodash';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { AppAlertUI, useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { useCoreActions, RecalculationTariff } from '../../DynamicCore';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ITariffDataStep, ILeadData } from 'Services/widgets/interfaces';
import { useWidgetService } from 'Services/widget';
import { StepInfoItem } from '../../interfaces';
import { FormType } from 'Services/widgets/enums';
import { CoreCirclesNavigationMobile } from '../core-ui-components/CoreCirclesNavigationMobile';
import { CoreSavedDataPopup } from '../core-ui-components/CoreSavedDataPopup';
import { CoreSaveLeadNav } from '../core-ui-components/CoreSaveLeadNav';

const RegistrationComponent = React.lazy( () =>
  import( './personal-sub-steps/RegistrationCoreStep' )
    .then( ( { RegistrationCoreStep } ) => ( { default: RegistrationCoreStep } ) ),
);
const LoginMFAComponent = React.lazy( () =>
  import( './personal-sub-steps/LoginMFACoreStep' )
    .then( ( { LoginMFACoreStep } ) => ( { default: LoginMFACoreStep } ) ),
);
const LoginUserComponent = React.lazy( () =>
  import( './personal-sub-steps/LoginUserCoreStep' )
    .then( ( { LoginUserCoreStep } ) => ( { default: LoginUserCoreStep } ) ),
);

export interface PersonalCoreStepProps {
  productCode: string;
  subStep: string | null;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  tenantSlug: string;
  recalculationTariff: RecalculationTariff;
  stepData: StepInfoItem;
  policyStepNames: string[];
  isDisabledForm: boolean;
}

interface ITypeOption {
  value: string;
  label: string;
}

const typeOptions: ITypeOption[] = [
  { value: FormType.Login, label: 'Ja' },
  { value: FormType.Registration, label: 'Noch nicht' },
];

const saveSelectedClient = 'saveSelectedClient';
const infoSelectClient = 'infoSelectClient';

export const PersonalCoreStep: React.FC<PersonalCoreStepProps> = ( propsStep ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { showAlert } = useAppAlertService();
  const service = useWidgetService();
  const previousResultData = React.useRef<ITariffDataStep>( {} );
  const {
    productCode, subStep, lead, productData,
    tenantSlug, recalculationTariff, stepData, policyStepNames, isDisabledForm,
  } = propsStep;
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );
  const { saveForLaterButton } = stepData || {};

  const formContext = useForm( {
    mode: 'onChange',
  } );

  const { errors, control, getValues, formState, trigger } = formContext;

  const onCloseSavedPopup = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    if ( !formState.isValid ) {
      trigger();
      return;
    }

    actions.reloadLead();
    setShowSavedStep( isShow );
  }, [ actions, formState.isValid, trigger ] );

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    try {
      const result = await service.savedInfo( saveSelectedClient, formData );
      if ( result.errorCode === 0 ) {
        actions.goToPersonalStep( stepData.nameStep, formData[infoSelectClient], true );
      } else {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    } catch( e ) {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, service, showAlert, stepData.nameStep, t ] );

  React.useEffect( () => {
    if ( !formState.isValid ) {
      return;
    }

    const formValues = getValues();
    const saveFormValues = async () => {
      try {
        await service.saveSelectedClient( previousResultData.current );
        actions.reloadLead();
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };
    if ( isEmpty( previousResultData.current ) ) {
      previousResultData.current = formValues;
      return;
    }
    if ( !isEqual( formValues, previousResultData.current ) ) {
      previousResultData.current = formValues;
      saveFormValues();
    }
  }, [ actions, formState.isValid, formState.isValidating, getValues, service, showAlert, t ] );

  if ( !lead[stepData.previousStep!] ) {
    actions.goToStep( stepData.previousStep!, false );
  }

  if ( productData === null ) {
    return <div className="bf-loading"><LoadingSpinner /></div>;
  }

  const registrationForm = stepData && stepData.steps.find(
    ( stepItem ) => stepItem.formType === FormType.Registration,
  );
  const loginForm = stepData && stepData.steps.find( ( stepItem ) => stepItem.formType === FormType.Login );

  const loginSettings = loginForm && loginForm.settings!;

  if ( ( subStep !== null && subStep === FormType.Registration ) || !loginForm ) {
    return (
      <RegistrationComponent
        currentStep={ registrationForm! }
        productCode={ productCode }
        lead={ lead }
        productData={ productData }
        stepData={ stepData }
        policyStepNames={ policyStepNames }
        recalculationTariff={ recalculationTariff }
        isParentStep={ !isEmpty( loginForm ) }
        isDisabledForm={ isDisabledForm }
      />
    );
  }

  if ( ( subStep !== null && subStep === FormType.Login ) || !registrationForm ) {
    return (
      <Fragment>
        { loginSettings && loginSettings.disableMFA ? (
          <LoginUserComponent
            currentStep={ loginForm! }
            productCode={ productCode }
            lead={ lead }
            productData={ productData }
            tenantSlug={ tenantSlug }
            stepData={ stepData }
            isParentStep={ !isEmpty( registrationForm ) }
            policyStepNames={ policyStepNames }
          />
        ) : (
          <LoginMFAComponent
            currentStep={ loginForm! }
            productCode={ productCode }
            lead={ lead }
            productData={ productData }
            tenantSlug={ tenantSlug }
            stepData={ stepData }
            isParentStep={ !isEmpty( registrationForm ) }
            policyStepNames={ policyStepNames }
          />
        ) }
      </Fragment>
    );
  }

  return (
    <FormProvider { ...formContext }>
      <div id="step-tabpane-personal">
        <div id="alert-tariff-page" className="mx-0 mt-3 row">
          <Col md={ 12 }>
            <AppAlertUI />
          </Col>
        </div>
        <Row className="mt-3 mx-0">
          <Col md={ 12 }>
            <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
              <Row className="bf-personal-core">
                { stepData.title && (
                  <Col className="p-0" md={ 12 }
                    dangerouslySetInnerHTML={ { __html: `${stepData.title}` } }
                  >
                  </Col>
                ) }
                { stepData.subTitle && (
                  <Col className="p-0" md={ 12 }
                    dangerouslySetInnerHTML={ { __html: `${stepData.subTitle}` } }
                  >
                  </Col>
                ) }
                <Col md={ 12 } className="p-0 mb-0">
                  <Controller
                    name={ infoSelectClient }
                    control={ control }
                    rules={ { required: true } }
                    defaultValue={ lead[saveSelectedClient] ? lead[saveSelectedClient][infoSelectClient] : '' }
                    render={ ( props ) => (
                      <FormGroup className={ `custom-btn-radio mb-2 rdb-${props.name}` }>
                        { typeOptions.map( ( item, idx ) => {
                          return (
                            <Fragment key={ idx }>
                              <div className="box-radio mb-4">
                                <Form.Control
                                  { ...props }
                                  className="form-check-input"
                                  type="radio"
                                  id={ `option-${ props.name + '-' + item.value}` }
                                  value={ item.value }
                                  checked={ props.value === item.value }
                                  onBlur={ props.onBlur }
                                />
                                <Form.Label
                                  className="btn btn-border-radio m-0"
                                  htmlFor={ `option-${ props.name + '-' + item.value}` }
                                >
                                  { item.label }
                                </Form.Label>
                              </div>
                            </Fragment>
                          );
                        } ) }
                        <Form.Control
                          id={ `error-messages-${props.name}` }
                          type="hidden"
                          isInvalid={ errors[props.name] !== undefined }
                        />
                        <Form.Control.Feedback type="invalid">
                          { t( 'base:forms.messages.fieldRequired', {
                            fieldLabel: 'Kundenkonto',
                          } ) }
                        </Form.Control.Feedback>
                      </FormGroup>
                    ) }
                  />
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center mt-0 mx-0">
                <CoreCirclesNavigationMobile lead={ lead } />
              </Row>
              <Row className="bf-footer-btn-panel justify-content-center align-items-center mt-4">
                { saveForLaterButton && saveForLaterButton.enabled && (
                  <>
                    { saveForLaterButton.suppressEmailDialog ? (
                      <CoreSaveLeadNav
                        currentStep={ registrationForm }
                        stepData={ stepData }
                        productCode={ productCode }
                        productData={ productData! }
                        disabled={ formState.isSubmitting }
                      />
                    ) : (
                      <Button
                        id={ `${stepData.nextStep ? stepData.nextStep+'_' : ''}save_for_later` }
                        type="button"
                        variant="primary"
                        disabled={ formState.isSubmitting }
                        onClick={ () => onSaved( true ) }
                        className="col-sm-12 mr-0"
                      >
                        { saveForLaterButton.buttonText ?
                          saveForLaterButton.buttonText : t( 'bookingFunnel.saveForLaterBtn' ) }
                      </Button>
                    ) }
                  </>
                ) }
                <Button
                  id={ `${stepData.nextStep}_next` }
                  type="submit"
                  variant="primary"
                  disabled={ formState.isSubmitting }
                  className="col-sm-12 mr-0"
                >
                  { t( 'bookingFunnel.nextBtn' ) }
                </Button>
                { stepData.previousStep && (
                  <Button
                    id={ `${stepData.previousStep}_back` }
                    type="button"
                    variant="link"
                    className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
                    onClick={ () => actions.goToStep( stepData.previousStep!, true ) }
                  >
                    { t( 'bookingFunnel.previousBtn' ) }
                  </Button>
                ) }
              </Row>
            </Form>
          </Col>
        </Row>
        { isShowSavedStep && (
          <CoreSavedDataPopup
            currentStep={ stepData.nameStep }
            productCode={ productCode }
            productData={ productData! }
            onClose={ () => onCloseSavedPopup( ) }
            goNextStep={ goNextStep }
            policyStepNames={ policyStepNames }
          />
        ) }
      </div>
    </FormProvider>
  );
};
