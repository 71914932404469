import React, { useState, Fragment } from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import { GroupType, FormatOptionLabelMeta, ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAppDefaultStyles } from 'Services/stylings';
import { useFormContext } from 'react-hook-form';
import { NullIndicator } from 'App/components/utils/react-select/indicators';
import { useWidgetService } from 'Services/widget';
import { AddressSelectValuesOptionComponent } from './options';
import { AddressItem, ContainerFieldItem, ILeadData } from 'Services/widgets/interfaces';
import { AddressFields } from 'Services/widgets/enums';
import { useCoreActions } from '../../DynamicCore';
import { checkedUnderwriting } from '../../../booking-funnel/booking-funnel-hooks';
import { personalDataCoreKey } from '../../core-booking-funnel/steps/personal-sub-steps/RegistrationCoreStep';
import { JsonObject } from '@cover42/ts-contracts';
import { AddressType } from '../../enums';

export interface AddressTypeOption {
  label: string;
  countryName?: string;
  city?: string;
  street?: string;
  postalCode?: string;
  houseNumber?: string;
}

const bgColorSelected = '#ECF5FA';

export interface SelectState {
  value: ValueType<AddressTypeOption>;
  isLoading?: boolean;
}

export interface ValuesProps {
  nameGroup: string;
  nameField: string;
  selectValue: AddressTypeOption;
  isDisabled?: boolean;
  placeholder: string;
  stepItem: ContainerFieldItem;
  countryName: string;
}

export const AddressSelectElement: React.FC<ValuesProps> = ( {
  nameGroup,
  nameField,
  selectValue,
  isDisabled,
  placeholder,
  stepItem,
  countryName,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const service = useWidgetService();
  const defaulStyles = useAppDefaultStyles();
  const { setValue, setError } = useFormContext();
  const stateDefault: SelectState = {
    value: selectValue,
  };

  const [ selectState, setSelectState ] = useState<SelectState>( stateDefault );

  const promiseOptions = React.useCallback( ( inputValue: string ) => {
    return service.getAddressCompletions( inputValue, countryName );
  }, [ countryName, service ] );

  const loadOptions = React.useCallback( debounce( ( inputText, callback ) => {
    promiseOptions( inputText ).then( ( options ) => {
      options = options.map( ( item ) => {
        const labelDestructured = item.label.split( ',' );
        labelDestructured.pop();
        const label = labelDestructured.join( ', ' );
        return {
          ...item,
          label,
        };
      } );
      return callback( options );
    } );
  }, 1000 ), [ promiseOptions ] );

  const getOptionValue = React.useCallback( ( option: AddressTypeOption ) => {
    return option.label;
  }, [ ] );

  const getOptionLabel: (
    option: AddressTypeOption,
    labelMeta: FormatOptionLabelMeta<AddressTypeOption>
  ) => React.ReactNode = React.useCallback( ( option ) => {
    return <AddressSelectValuesOptionComponent values={ option as AddressTypeOption } />;
  }, [] );

  const getGroupLabel = React.useCallback( ( group: GroupType<AddressTypeOption> ) => {
    return null;
  }, [ ] );

  const setAddressPersonalData = React.useCallback( async (
    item: AddressTypeOption, personalData: JsonObject,
  ) => {
    const newPersonalData = {
      ...personalData,
      [AddressFields.ZipCode]: item.postalCode,
      [AddressFields.City]: item.city,
      [AddressFields.Country]: item.countryName,
      [AddressFields.Street]: item.street,
      [AddressFields.HouseNumber]: item.houseNumber,
    };

    await service.savedInfo( personalDataCoreKey, newPersonalData );
  }, [ service ] );

  const setAddressValues = React.useCallback( ( fieldName: string, item: AddressTypeOption ) => {
    const zipField = `${AddressFields.Zip}_${nameGroup}`;
    setValue( zipField, item.postalCode, { shouldValidate: true } );

    const cityField = `${AddressFields.City}_${nameGroup}`;
    setValue( cityField, item.city, { shouldValidate: true } );

    const countryField = `${AddressFields.Country}_${nameGroup}`;
    setValue( countryField, item.countryName, { shouldValidate: true } );

    const streetField = `${AddressFields.Street}_${nameGroup}`;
    setValue( streetField, item.street, { shouldValidate: true } );

    const houseNumberField = `${AddressFields.HouseNumber}_${nameGroup}`;
    setValue( houseNumberField, item.houseNumber, { shouldValidate: true } );

    const isAddressValid = `${AddressFields.IsAddressValid}_${nameGroup}`;
    setValue( isAddressValid, true, { shouldValidate: true } );

    setValue( nameField, item.label, { shouldValidate: true } );
  }, [ nameField, nameGroup, setValue ] );

  const handleSelect = React.useCallback( async ( selectedValue: ValueType<AddressTypeOption> ) => {
    const errMessage = t( 'base:forms.messages.addressInvalid' );

    try {
      const item: AddressTypeOption = selectedValue as AddressTypeOption;
      const resValidateAddress = await service.validateAddress( item as AddressItem );
      const leadStore: ILeadData = await service.getLead();

      if ( resValidateAddress.isAddressValid ) {
        setSelectState( {
          value: item,
          isLoading: false,
        } );

        setAddressValues( nameField, item );


        const personalInfo = leadStore && leadStore[personalDataCoreKey];

        if ( personalInfo !== null && personalInfo['addressType']
        && personalInfo['addressType'] === AddressType.InsuredAddress ) {
          await setAddressPersonalData( item, personalInfo as unknown as JsonObject );
        }
      } else {
        setError( nameField, { shouldFocus: true, message: errMessage } );
      }
    } catch ( error ) {
      setError( nameField, { shouldFocus: true, message: errMessage } );
    }
  }, [ nameField, service, setAddressPersonalData, setAddressValues, setError, t ] );

  const onMenuOpenHandle = React.useCallback( ( ) => {
    setSelectState( {
      ...selectState,
      isLoading: false,
    } );
  }, [ selectState ] );

  const onBlurHandle = React.useCallback( ( ) => {
    setSelectState( {
      ...selectState,
      isLoading: false,
    } );

    const isCheckedUnderwriting = checkedUnderwriting( stepItem );
    actions.recalculationPremium( isCheckedUnderwriting );
  }, [ actions, selectState, stepItem ] );

  const optionsMessage = React.useMemo( ( ) => {
    let messageText: string = t( 'base:noOptions' );
    if ( selectState.isLoading ) {
      messageText = t( 'bookingFunnel.sLoadingRecords' );
    }

    return messageText;
  }, [ t, selectState.isLoading ] );

  const customStyles = React.useMemo( () => {
    return {
      option: ( styles, { isFocused, isSelected } ) => {
        return {
          ...styles,
          backgroundColor: isFocused | isSelected ? bgColorSelected : null,
          color: defaulStyles.textColor,
        };
      },
    };
  }, [ defaulStyles.textColor ] );

  return (
    <Fragment>
      <AsyncSelect
        name={ `${nameField}-address` }
        id={ `${nameField}-address` }
        className="select-values-list"
        classNamePrefix="select-item"
        placeholder={ placeholder }
        defaultValue={ selectState.value && selectState.value['label'] ? selectState.value : null }
        components={ {
          DropdownIndicator: NullIndicator,
          IndicatorSeparator: NullIndicator,
        } }
        cacheOptions={ false }
        cache={ false }
        isLoading={ selectState.isLoading }
        loadOptions={ loadOptions }
        onMenuOpen={ onMenuOpenHandle }
        onBlur={ onBlurHandle }
        blurInputOnSelect={ true }
        isMulti={ false }
        isClearable={ false }
        isSearchable={ true }
        escapeClearsValue
        menuPlacement="bottom"
        getOptionValue={ getOptionValue }
        formatOptionLabel={ getOptionLabel }
        formatGroupLabel={ getGroupLabel }
        onChange={ handleSelect }
        noOptionsMessage={ () => { return optionsMessage; } }
        styles={ customStyles }
        maxMenuHeight={ 200 }
        isDisabled={ isDisabled }
      />
    </Fragment>
  );
};
