import React from 'react';
import { FormGroup, Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
  EmilFunctionsApi,
  EmilFunctionsApiExecuteEmilFunctionRequest,
} from '@emilgroup/insurance-sdk';

import { useAppLogger } from 'Services/logger';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { publicApiUrl } from 'App/utils';
import { HiddenField } from '../../steps/personal-sub-steps/form-fields/HiddenField';

import {
  ContainerFieldItem,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { htmlTagsRegex } from './extra/CoreAddressAutoComplete';
import { isCheckedByField } from '../../core-hooks';


export interface CoreEmilFunctionLookupProps {
  stepItem: ContainerFieldItem;
  formData: ITariffDataStep;
  isDisabled?: boolean;
  emilFunctionSlug?: string;
  placeholder?: string;
  mapping?: string;
}

interface ResultMapping {
  source: string;
  target: string;
}

interface HiddenField {
  insuredObjectName: string;
  fieldName: string;
}

const parseMappings = ( mapping: string ): {
  mappings: ResultMapping[];
  hiddenFields: HiddenField[];
} => {
  if ( !( mapping ?? '' ).trim() ) {
    return {
      mappings: [],
      hiddenFields: [],
    };
  }

  const arr = JSON.parse( mapping ) as string[][];
  const mappings = arr.map( ( i ) => ( {
    source: i[0],
    target: i[1],
  } ) );

  const hiddenFields = mappings.map( ( { target } ) => {
    const [ insuredObjectName, fieldName ] = target.split( '.' );

    return { insuredObjectName, fieldName };
  } );

  return { mappings, hiddenFields };
};

const evalInScope = ( code: string, contextAsScope: object ): any => {
  // eslint-disable-next-line no-new-func
  return new Function( `with (this) { return (${code}); }` ).call( contextAsScope );
};

const createEmilFunctionApi = () => new EmilFunctionsApi( undefined, publicApiUrl );

export const CoreEmilFunctionLookup: React.FC<CoreEmilFunctionLookupProps> = (
  {
    stepItem, formData, isDisabled, emilFunctionSlug, placeholder, mapping,
  },
) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, setValue, control, getValues } = useFormContext();
  const logger = useAppLogger();
  const { showAlert, hideAlert } = useAppAlertService();
  const { label } = stepItem || {};
  const allFields = getValues();

  if ( !( emilFunctionSlug ?? '' ).trim() ) {
    logger.error( 'emilFunctionSlug property should be provided' );
    showAlert( {
      message: t( 'emilFunctionLookup.noEmilFunctionSlug' ),
      type: 'danger',
    } );
  }

  const controlName = `${stepItem.fieldName}_${stepItem.insuredObjectName}`;
  const { mappings, hiddenFields } = parseMappings( mapping ?? '' );

  const evalExpr = React.useCallback( ( template: string, ctx: object ): string => {
    const code = template;
    try {
      const s = evalInScope( code, ctx );

      return s;
    } catch ( e ) {
      logger.error( `Error evaluating [${code}] with context: ${e}` );

      return ( e as Error ).message;
    }

  }, [ logger ] );

  const initialValue = ( formData ? formData[controlName] : '' );

  const [ currenQuery, setCurrentQuery ] = React.useState<string>( initialValue );
  const [ lastQuery, setLastQuery ] = React.useState<string | undefined>( undefined );

  const loadData = React.useCallback( async ( query: string ) => {
    try {
      if ( !query ) {
        return {};
      }

      hideAlert();

      const request: EmilFunctionsApiExecuteEmilFunctionRequest = {
        slug: emilFunctionSlug || '',
        executeEmilFunctionRequestDto: {
          payload: {
            query,
          },
        },
      };

      const emilFunctionApi = createEmilFunctionApi();

      const response = await emilFunctionApi.executeEmilFunction( request );

      if ( response.status < 200 || response.status >= 300 ) {
        logger.error( response );
        showAlert( {
          message: t( 'emilFunctionLookup.invalidResponse' ),
          type: 'danger',
        } );

        return {};
      }

      const { result } = ( response.data as { result: object } );

      return result;
    } catch ( e ) {
      logger.error( e );
      showAlert( {
        message: t( 'base:forms.messages.error' ),
        type: 'danger',
      } );

      return {};
    }

  }, [ emilFunctionSlug, hideAlert, logger, showAlert, t ] );

  const setMappedFields = React.useCallback( ( data: Object ): void => {
    if ( isDisabled ) {
      return;
    }

    for ( const { source, target } of mappings ) {
      const value = data && !isEmpty( data ) ? evalExpr( source, { data } ) : undefined;
      const [ part1, part2 ] = target.split( '.' );
      const fieldName = ( part2 ? `${part2}_${part1}` : part1 );

      setValue( fieldName, value ? value : '', { shouldValidate: true } );
    }
  }, [ evalExpr, isDisabled, mappings, setValue ] );

  const loadDataByQuery = React.useCallback( async ( ) => {
    const value = currenQuery.trim();
    setValue( controlName, value, { shouldValidate: true } );

    if ( value === lastQuery ) {
      return;
    }

    const data = await loadData( value );

    setMappedFields( data );
    setLastQuery( value );
  }, [ controlName, currenQuery, lastQuery, loadData, setMappedFields, setValue ] );

  const onBlur = React.useCallback( async () => {
    await loadDataByQuery();
  },
  [ loadDataByQuery ] );

  const onKeyPress = React.useCallback( async ( e: React.KeyboardEvent<HTMLInputElement> ) => {
    if ( e.key === 'Enter' ) {
      await loadDataByQuery();
    }
  }, [ loadDataByQuery ] );

  const isFieldSetInForm = React.useCallback( async ( fieldName: string ): Promise<boolean> => {
    Object.keys( allFields ).forEach( ( fieldKey ) => {
      if ( fieldKey === fieldName ) {
        return true;
      }
    } );

    return false;
  }, [ allFields ] );

  return (
    <Controller
      id={ controlName }
      name={ controlName }
      rules={ { required: isCheckedByField( 'isRequired', stepItem! ) } }
      control={ control }
      defaultValue={ initialValue }
      render={ ( props ) => (
        <FormGroup className="mb-0" controlId={ props.name }>
          { label && (
            <Form.Label id={ `${props.name}-label` }>
              <div className="d-inline-block" dangerouslySetInnerHTML={ { __html: `${label}` } }></div>
            </Form.Label>
          ) }
          <Form.Control
            { ...props }
            type={ 'text' }
            className={ stepItem?.className }
            placeholder={ placeholder }
            isInvalid={ errors[props.name] !== undefined }
            disabled={ isDisabled }
            value={ currenQuery }
            onChange={ ( e ) => { setCurrentQuery( ( e.target as any ).value ); } }
            onBlur={ onBlur }
            onKeyPress={ onKeyPress }
          />
          <Form.Control.Feedback type="invalid">
            { errors[props.name]?.message ? ( errors[props.name]?.message ) : (
              <React.Fragment>
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: label && label.replace( htmlTagsRegex, '' ) },
                ) }
              </React.Fragment>
            ) }
          </Form.Control.Feedback>

          { !isEmpty( allFields ) && hiddenFields.map( ( field, idx ) => {
            const hiddenName = `${field.fieldName}_${field.insuredObjectName}`;

            const isSetField = isFieldSetInForm( hiddenName );

            if ( !isSetField ) {
              return (
                <React.Fragment key={ idx }>
                  <HiddenField
                    isRequired={ false }
                    fieldName={ hiddenName }
                    fieldValue={ formData && formData[hiddenName] }
                  />
                </React.Fragment>
              );
            }

            return null;
          } ) }
        </FormGroup>
      ) }
    />
  );
};
