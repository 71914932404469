import React, { Fragment } from 'react';
import plusIcon from 'assets/bf-images/funk/plus_icon.png';
import minusIcon from 'assets/bf-images/funk/minus_icon.svg';
import { useTranslation } from 'react-i18next';
import { Card, Accordion } from 'react-bootstrap';
import { Title } from 'Services/widgets/enums';
import { PersonalDataStepCore } from '../../steps/personal-sub-steps/RegistrationStep';
import { ActiveStepCore, useCoreActions } from '../../DynamicCore';
import { JSONComponentType } from 'App/components/widgets/booking-funnel/enum';

export interface IPersonalInfoProps {
  isOpened: boolean;
  index: number;
  iconCard: string;
  personalData: PersonalDataStepCore | null;
}

export const PersonalInfo: React.FC<IPersonalInfoProps> = ( {
  isOpened, index, personalData,
} ) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const actions = useCoreActions();
  const [ isCollapsed, setCollapsed ] = React.useState<boolean>( isOpened );
  const personalInfo = personalData as PersonalDataStepCore;

  const isAddressSingleLine = personalInfo?.address ? true : false;

  const allSteps = actions.getStepsInfo();
  const personalDataStep = allSteps?.find( ( step ) => step.componentType === JSONComponentType.PersonalData );
  const currentStep = personalDataStep?.steps.find( ( item ) => item.name === ActiveStepCore.PersonalData );

  const collapseToggleHandler = React.useCallback( () => {
    setCollapsed( !isCollapsed );
  }, [ isCollapsed ] );

  const getTitle = ( titleVal: Title ): string => {
    const titles = Object.values( Title );

    if ( titles.includes( titleVal ) ) {
      return titleVal;
    }

    // When the value selected by the user is Title.none, titleVal is:
    // t( 'bookingFunnel.personalDataFunk.honorifics.none' )
    // So the if clause will be false
    // Explained in RegistrationStep
    return '-';
  };

  return (
    <Fragment>
      { personalData !== null && (
        <Accordion>
          <Card>
            <Accordion.Toggle
              className="py-3 px-0"
              as={ Card.Header }
              variant="link"
              eventKey="bf-personal-info"
              onClick={ collapseToggleHandler }
            >
              <span className="align-middle bf-title-text">
                { `${index}. ${ currentStep && currentStep.stepTitleToSummary}` }
              </span>
              <img src={ isCollapsed ? minusIcon : plusIcon } className="align-middle" alt="chevron" />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="bf-personal-info">
              <Card.Body className="mx-4">
                <div className="details-section d-flex justify-content-space-between">
                  <p><b>{ t( 'bookingFunnel.funk.personalData.gender' ) }</b></p>
                  <p>
                    { personalInfo?.gender ? t( `bookingFunnel.personalDataFunk.${personalInfo?.gender}` ) : '-' }
                  </p>
                </div>
                <div className="details-section d-flex justify-content-space-between">
                  <p><b>{ t( 'bookingFunnel.funk.personalData.name' ) }</b></p>
                  <p>{ personalInfo?.firstName } { personalInfo?.lastName }</p>
                </div>
                <div className="details-section d-flex justify-content-space-between">
                  <p><b>{ t( 'bookingFunnel.funk.personalData.title' ) }</b></p>
                  <p>
                    { personalInfo.title && personalInfo.title !== undefined ? getTitle( personalInfo.title ) : '-' }
                  </p>
                </div>
                {
                  isAddressSingleLine ?
                    (
                      <div className="details-section d-flex justify-content-space-between">
                        <p><b>{ t( 'bookingFunnel.funk.personalData.address' ) }</b></p>
                        <p>{ personalInfo?.address }</p>
                      </div>
                    ) : (
                      <Fragment>
                        <div className="details-section d-flex justify-content-space-between">
                          <p><b>{ t( 'bookingFunnel.funk.personalData.streetInfo' ) }</b></p>
                          <p>{ personalInfo?.street } { personalInfo?.houseNumber }</p>
                        </div>
                        <div className="details-section d-flex justify-content-space-between">
                          <p><b>{ t( 'bookingFunnel.funk.personalData.cityInfo' ) }</b></p>
                          <p>{ personalInfo?.city }, { personalInfo?.zipCode }</p>
                        </div>
                      </Fragment>
                    )
                }
                <div className="details-section d-flex justify-content-space-between">
                  <p><b>{ t( 'bookingFunnel.personalDataFunk.country' ) }</b></p>
                  <p>{ personalInfo?.country ? personalInfo?.country : '-' }</p>
                </div>
                <div className="details-section d-flex justify-content-space-between">
                  <p><b>{ t( 'bookingFunnel.funk.personalData.phone' ) }</b></p>
                  <p>{ personalInfo?.phone ? personalInfo?.phone : '-' }</p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ) }
    </Fragment>
  );
};
