import React from 'react';
import jsonpath from 'jsonpath';
import { ContainerFieldItem, ILeadData } from 'Services/widgets/interfaces';
import { htmlTagsRegex } from '../extra/CoreAddressAutoComplete';

export interface CoreAddressAutoCompleteVeiwProps {
  lead: ILeadData;
  stepItem: ContainerFieldItem;
}

export const CoreAddressAutoCompleteVeiw: React.FC<CoreAddressAutoCompleteVeiwProps> = ( { stepItem, lead } ) => {

  const getFieldValue = ( ): string => {
    const fieldValue = jsonpath.query( lead || {}, `$.${stepItem.fieldName}` )[0];

    return fieldValue || '-';
  };

  return (
    <div className="details-section mx-4">
      <p
        className="bf-selected-val f-14"
        dangerouslySetInnerHTML={ { __html: `${stepItem.label!.replace( htmlTagsRegex, '' )}` } }
      >
      </p>
      <p>{ getFieldValue() }</p>
    </div>
  );
};
