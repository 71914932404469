import React from 'react';
import arrowDownIcon from 'assets/bf-images/funk/arrow_down_icon.svg';
import arrowUpIcon from 'assets/bf-images/funk/arrow_up_icon.svg';
import emptyIcon from 'assets/bf-images/funk/empty_icon.svg';
import groupCheckIcon from 'assets/bf-images/funk/empty_check_icon.svg';
import { Accordion, Card, Col } from 'react-bootstrap';
import { IBFProductVariable, ContainerFieldItem, ITariffDataStep, IProductInvoice } from 'Services/widgets/interfaces';
import { ICFFactorType } from '../../factor-service';
import { DescriptionField } from './fields/DescriptionField';
import { FactorCheckButton } from './fields/FactorCheckButton';
import { CFDefaultValueType } from '../../bf-hooks';
import { useFormContext } from 'react-hook-form';
import { yesValue } from '../core-hooks';
import { PriceField } from './fields/PriceField';

export interface ProductPanelProps {
  valField: CFDefaultValueType;
  variable: IBFProductVariable;
  factor: ICFFactorType;
  showTooltip: ( isShow: boolean, tooltip: string ) => void;
  stepItem?: ContainerFieldItem;
  invoiceData?: IProductInvoice[];
  tariffData?: ITariffDataStep;
}

export const ProductPanel: React.FC<ProductPanelProps> = ( {
  valField,
  variable,
  factor,
  showTooltip,
  stepItem,
  invoiceData,
  tariffData,
} ) => {
  const [ isCollapsed, setCollapsed ] = React.useState<boolean>( false );
  const { getValues } = useFormContext();
  const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;

  const collapseToggleHandler = React.useCallback( (): void => {
    setCollapsed( !isCollapsed );
  }, [ isCollapsed ] );

  const isCheckedProduct = React.useCallback( ( ): boolean => {
    const productValueSaved = tariffData && tariffData[fieldName];

    if ( productValueSaved && productValueSaved !== undefined ) {
      return productValueSaved === yesValue;
    }

    const productValue = getValues( fieldName );

    if ( productValue ) {
      return productValue === yesValue;;
    }

    return false;
  }, [ fieldName, getValues, tariffData ] );

  return (
    <Accordion
      id={ `${variable.name}-accordion` }
      className="product-accordion"
      defaultActiveKey={ '' }
    >
      <Card className="px-0 bg-none">
        <Accordion.Toggle
          className={ `py-3 px-0${ isCheckedProduct() ? ' accordion-done' : '' }` }
          as={ Card.Header }
          variant="link"
          eventKey={ `${variable.name}-${variable.id}` }
          onClick={ collapseToggleHandler }
        >
          <div className="img-accordion align-middle">
            <img
              src={ isCheckedProduct() ? groupCheckIcon : emptyIcon }
              className="align-middle"
              alt="group icon"
            />
          </div>
          { variable.bfLabel ?
            <span className="title-accordion align-middle"
              dangerouslySetInnerHTML={ { __html: `${variable.bfLabel}` } }
            >
            </span>
            : ( <span className="title-accordion align-middle">{ variable.label } </span> ) }
          <img src={ isCollapsed ? arrowDownIcon : arrowUpIcon } className="align-middle" alt="chevron" />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={ `${variable.name}-${variable.id}` }>
          <Card.Body>
            <Col sm={ 12 } className="mb-4 px-0">
              <DescriptionField
                variable={ variable }
              />
            </Col>
            <Col sm={ 12 } className="mb-4 px-0">
              <PriceField
                invoiceData={ invoiceData! }
                variable={ variable }
                stepItem={ stepItem }
              />
            </Col>
            <Col sm={ 12 } className="mb-4 px-0">
              <FactorCheckButton
                valueField={ valField }
                variable={ variable }
                factor={ factor }
                stepItem={ stepItem! }
              />
            </Col>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
