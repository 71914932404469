import React, { Fragment } from 'react';
import debounce from 'lodash/debounce';
import { Combobox } from 'react-widgets';
import { Form, FormGroup, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem, IBFProductVariable } from 'Services/widgets/interfaces';
import { blankLink } from 'config';
import { FieldDefaultValue } from 'App/widget/types';
import { isBoolean } from 'class-validator';
import { isRequiredField } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { renderFieldLabel } from '../../../core-hooks';
import { AsteriskRequiredField } from 'App/components/widgets/AsteriskRequiredField';
import LicensePlateData from '../../../license_plates_2024_DE.json';
import { ParseLicensePlate, parseLicensePlate } from './license-plate-hooks';

export interface LicensePlateItem {
  code: string;
  city: string;
  land: string;
}

export interface SelectRegionState {
  value: LicensePlateItem | null;
  isLoading?: boolean;
  isSelected: boolean;
}

export interface InitState {
  selectItem: SelectRegionState;
  parsedData: ParseLicensePlate;
}

export interface LicensePlateFieldProps {
  valueField: string;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  isShortName?: boolean;
  showAsterisk?: boolean;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
  isDisabled?: boolean;
}

const countryCode = 'D';
// Regular expression to allow only letters (English and German alphabets)
const regexLetters = /^[a-zA-ZäöüÄÖÜß]*$/;

export const LicensePlateField: React.FC<LicensePlateFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  showAsterisk,
  stepItem,
  fieldDefaultValue,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control, setValue } = useFormContext();
  const { productFieldValue, isReadOnly } = fieldDefaultValue || {};
  const isFirstRender = React.useRef<boolean | null>( null );
  const fieldName = isShortName ? variable.name :
    `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;

  const initialState = React.useMemo<InitState>( () => {
    let stateDefault: SelectRegionState = {
      value: null,
      isSelected: false,
    };

    let parsedData = {
      regionCode: '',
      districtCode: '',
      number: '',
      suffix: '',
    };

    const parsedPlate = parseLicensePlate(
      valueField || productFieldValue as string || stepItem?.defaultValue as string );
    if ( parsedPlate ) {
      const { regionCode } = parsedPlate;

      const foundItem = LicensePlateData.find( ( r ) => r.code.toLowerCase() === regionCode.toLowerCase() );

      if ( foundItem ) {
        stateDefault.value = foundItem;
      }

      parsedData = parsedPlate;
    }

    return {
      selectItem: stateDefault,
      parsedData,
    };
  }, [ productFieldValue, stepItem, valueField ] );

  const [ selectRegionState, setSelectRegionState ] = React.useState<SelectRegionState>( initialState.selectItem );
  const [ licensePlateItems, setLicensePlateItems ] = React.useState<LicensePlateItem[]>( LicensePlateData );
  const [ searchTerm, setSearchTerm ] = React.useState<string>( initialState.selectItem.value?.code || '' );

  // State variables for each part of the license plate
  const [ districtCode, setDistrictCode ] = React.useState( initialState.parsedData.districtCode || '' );
  const [ numbers, setNumbers ] = React.useState( initialState.parsedData.number || '' );
  const [ typeSuffix, setTypeSuffix ] = React.useState( initialState.parsedData.suffix || '' );

  // References for each input to shift focus
  const districtCodeRef = React.useRef<HTMLInputElement>( null );
  const numbersRef = React.useRef<HTMLInputElement>( null );
  const typeSuffixRef = React.useRef<HTMLSelectElement>( null );
  const [ isOpen, setIsOpen ] = React.useState<boolean>( false );

  const handleInputClick = React.useCallback( () => {
    setIsOpen( true ); // Open the dropdown when input is clicked
  }, [] );

  const handleToggle = React.useCallback( ( open ) => {
    setIsOpen( open ); // Control open state of dropdown
  }, [] );

  const getItemsToRegion = React.useCallback( async ( inputValue ): Promise<LicensePlateItem[]> => {
    let items: LicensePlateItem[] = licensePlateItems;

    if ( inputValue.length > 0 ) {
      const search = inputValue.toLocaleLowerCase();

      items = items.filter( ( o ) => {
        return o.code.toLowerCase().includes ( search );
      } );
    }

    return Promise.resolve( items );
  }, [ licensePlateItems ] );

  const debouncedSearch = React.useCallback( debounce(
    ( searchText: string, setter: ( data: LicensePlateItem[] ) => void ) => {
      return getItemsToRegion( searchText ).then( ( res ) => {
        setSelectRegionState( {
          ...selectRegionState,
          isLoading: false,
          isSelected: false,
        } );

        return setter( res );
      } );
    }, 1000 ), [] );

  // Format LP for storage according to the specification
  const formatForStorage = React.useCallback( () => {
    if ( selectRegionState.value === null ) {
      return '';
    }
    const formattedRegion = selectRegionState.value && selectRegionState.value.code.padEnd( 3, ' ' );
    const formattedAlpha = districtCode.padEnd( 2, ' ' );
    const formattedNumbers = numbers.padStart( 4, ' ' );
    const formattedType = typeSuffix || '';

    return `${formattedRegion}${formattedAlpha}${formattedNumbers}${formattedType}`;
  }, [ districtCode, numbers, selectRegionState.value, typeSuffix ] );

  // Regular expression for German license plates
  const isValidLicensePlate = React.useCallback( () => {
    const plateFormatted = formatForStorage();

    const regionCode = plateFormatted.slice( 0, 3 ).replaceAll( ' ', '' );
    if ( !/^[A-ZÄÖÜ]{1,3}$/.test( regionCode ) ) {
      return false;
    }

    const dstCode= plateFormatted.slice( 3, 5 ).replaceAll( ' ', '' );
    if ( !/^[A-ZÄÖÜ]{1,2}$/.test( dstCode ) ) {
      return false;
    }

    const plateNumbers = plateFormatted.slice( 5, 9 ).replaceAll( ' ', '' );
    if ( !/^\d{1,4}$/.test( plateNumbers ) ) {
      return false;
    }

    return true;
  }, [ formatForStorage ] );

  // Handle input change for each part and automatically focus the next field
  const handleRegionOnChange = React.useCallback( ( searchVal ) => {
    if ( searchVal.length <= 3 && searchVal && typeof searchVal === 'string' ) {
      setSelectRegionState( {
        ...selectRegionState,
        isLoading: true,
        isSelected: false,
      } );
      setSearchTerm( searchVal.toUpperCase() );

      debouncedSearch( searchVal, setLicensePlateItems );
    }

    if ( !searchVal ) {
      setSelectRegionState( {
        value: null,
        isLoading: false,
        isSelected: false,
      } );
      setSearchTerm( '' );
    }
  }, [ debouncedSearch, selectRegionState ] );

  const handleRegionOnSelect = React.useCallback( ( item ) => {
    setSelectRegionState( {
      value: item,
      isLoading: false,
      isSelected: true,
    } );

    setSearchTerm( item.code );

    setTimeout( () => {
      if ( districtCodeRef.current ) {
        districtCodeRef.current.focus();
      };
    }, 500 );

    if ( isFirstRender.current === null ) {
      isFirstRender.current = true;
    }
  }, [] );

  const handleRegionOnBlur = React.useCallback( ( item ) => {
    if ( searchTerm ) {
      // Find the first item that matches the input value
      const foundItem = licensePlateItems.find( ( lp ) =>
        lp.code.toLowerCase().startsWith( searchTerm.toLowerCase() ),
      );
      if ( foundItem ) {
        setSelectRegionState( {
          ...selectRegionState,
          value: foundItem,
        } );

        setSearchTerm( foundItem.code );
      }
    }
  }, [ licensePlateItems, searchTerm, selectRegionState ] );

  const handleOnKeyPress = React.useCallback( ( event ) => {
    const char = String.fromCharCode( event.which || event.keyCode );

    if ( !regexLetters.test( char ) ) {
      event.preventDefault(); // Prevent non-letter characters
    }
  }, [] );

  const handleDistrictCodeOnChange = React.useCallback ( ( event: React.ChangeEvent<HTMLInputElement> ): void => {
    const { value, maxLength } = event.target;

    setDistrictCode( value ? value.toUpperCase() : '' );

    if ( value.length === maxLength && numbersRef.current ) numbersRef.current.focus();

    if ( isFirstRender.current === null ) {
      isFirstRender.current = true;
    }
  }, [] );

  const handleNumbersOnChange = React.useCallback ( ( event: React.ChangeEvent<HTMLInputElement> ): void => {
    const { value, maxLength } = event.target;
    const numbersVal = value.replace( /\D/g, '' ); // Allow only digits

    setNumbers( numbersVal );

    if ( numbersVal.length === maxLength && typeSuffixRef.current ) typeSuffixRef.current.focus();

    if ( isFirstRender.current === null ) {
      isFirstRender.current = true;
    }
  }, [] );

  const handleTypeSuffixOnChange = React.useCallback ( ( event: React.ChangeEvent<HTMLSelectElement> ): void => {
    setTypeSuffix( event.target.value );
  }, [] );

  const getDefaultValue = React.useCallback ( (): string => {
    const stringValue = variable.defaultValue as string;

    if ( valueField ) {
      return valueField;
    }

    if ( productFieldValue ) {
      return productFieldValue as string;
    }

    if ( stepItem && stepItem.defaultValue ) {
      return stepItem.defaultValue as string || '';
    }

    return stringValue !== null ? stringValue : '';
  }, [ productFieldValue, stepItem, valueField, variable.defaultValue ] );

  const renderClassName = React.useCallback ( (): string => {
    let classNameField = '';
    let readOnly = '';

    if ( stepItem?.className ) {
      classNameField = stepItem?.className;
    }

    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return `${classNameField} read-only`;
    }

    if ( stepItem?.isReadOnly ) {
      readOnly = ' read-only';
    }

    return `${classNameField}${readOnly}`;
  }, [ isReadOnly, stepItem ] );

  const isReadOnlyInput = React.useCallback ( (): boolean => {
    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return isReadOnly;
    }

    if ( stepItem && stepItem.isReadOnly ) {
      return stepItem.isReadOnly;
    }

    return false;
  }, [ isReadOnly, stepItem ] );

  React.useEffect( () => {
    let isMounted = true;

    if ( isFirstRender.current === null ) {
      return;
    }

    const updateData = () => {
      if ( isMounted ) {
        const formatted = formatForStorage();
        setValue( fieldName, formatted, { shouldValidate: true } );
      }
    };

    setTimeout( () => {
      updateData();
    }, 500 );

    return () => {
      isFirstRender.current = true;
      isMounted = false;
    };
  }, [ fieldName, formatForStorage, setValue, numbers, typeSuffix, selectRegionState.value ] );

  return (
    <Fragment>
      <Controller
        control={ control }
        name={ fieldName }
        rules={ {
          required: isDisabled ? false : isRequiredField( variable, stepItem ),
          validate: () => {
            if ( isDisabled ) {
              return true;
            }

            const err = t( 'licencePlate.invalidLicencePlate' );

            return isValidLicensePlate() ? true : err ;
          },
        } }
        defaultValue={ getDefaultValue() }
        render={ ( props ) => (
          <FormGroup className={ stepItem?.classNameBox! } controlId={ props.name }>
            <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
              <div
                className="d-inline-block"
                dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
              />
              { showAsterisk && variable.isRequired && ( <AsteriskRequiredField /> ) }
              { variable.bfTooltip && (
                <a
                  id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                  href={ blankLink }
                  role='button'
                  onClick={ ( e ) => {
                    e.preventDefault();
                    showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            { variable.bfDescription && (
              <span className="gm-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            <Row className={
              `license-plate-container${renderClassName()}${errors[props.name] ? ' core-input-error' : ''}`
            }
            >
              <div className="license-plate-info">
                <span className="country-container">{ countryCode }</span>
              </div>
              <div className="license-plate-inputs">
                <Combobox
                  inputProps={
                    {
                      maxLength: 3,
                      onClick: handleInputClick, // Trigger open on input click
                    }
                  }
                  open={ isOpen }
                  onToggle={ handleToggle }
                  filter={ false }
                  value={ searchTerm }
                  defaultValue={ selectRegionState.value && selectRegionState.value.code ?
                    selectRegionState.value.code : null }
                  hideCaret
                  hideEmptyPopup={ false }
                  textField="code"
                  className={ `${selectRegionState.isLoading ? 'combobox-loading' : ''}` }
                  data={ licensePlateItems }
                  onChange={ handleRegionOnChange }
                  onSelect={ handleRegionOnSelect }
                  disabled={ isReadOnlyInput() }
                  busy={ selectRegionState.isLoading }
                  onBlur={ handleRegionOnBlur }
                  onKeyPress={ handleOnKeyPress }
                />
                <div className="circle-container"></div>
                <input
                  className="districtCode"
                  ref={ districtCodeRef }
                  type="text"
                  readOnly={ isReadOnlyInput() }
                  maxLength={ 2 }
                  value={ districtCode }
                  onChange={ handleDistrictCodeOnChange }
                  onKeyPress={ handleOnKeyPress }
                />
                <input
                  className="numbersField"
                  ref={ numbersRef }
                  required={ true }
                  type="number"
                  inputMode="numeric"
                  readOnly={ isReadOnlyInput() }
                  maxLength={ 4 }
                  value={ numbers }
                  onChange={ handleNumbersOnChange }
                />
                <select
                  className="form-select"
                  value={ typeSuffix }
                  onChange={ handleTypeSuffixOnChange }
                  ref={ typeSuffixRef }
                >
                  <option value=""></option>
                  <option value="E">E</option>
                  <option value="H">H</option>
                </select>
              </div>
            </Row>
            { selectRegionState.value && (
              <p className="my-2">{ selectRegionState.value.city }, { selectRegionState.value.land }</p>
            ) }
            <Form.Control
              { ...props }
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired', { fieldLabel: variable.label } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
