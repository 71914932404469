import React, { useState, Fragment } from 'react';
import AsyncSelect from 'react-select/async';
import { GroupType, FormatOptionLabelMeta, ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAppDefaultStyles } from 'Services/stylings';
import { ICFFactorTypeOption, useFactorService } from 'App/components/widgets/factor-service';
import { SelectValuesOptionComponent } from 'App/components/widgets/base-ui-elements/select-values/options';
import { useFormContext } from 'react-hook-form';
import { DropdownIndicatorTagForBg, NullIndicator } from 'App/components/utils/react-select/indicators';
import { useCoreActions } from '../../DynamicCore';
import { checkedUnderwriting } from '../../../booking-funnel/booking-funnel-hooks';
import { ContainerFieldItem } from 'Services/widgets/interfaces';

const bgColorSelected = '#ECF5FA';
const yesValue = '1';
const noValue = '0';

export interface ISelectState {
  value: ValueType<ICFFactorTypeOption>;
  isLoading?: boolean;
  defaultOptions?: ICFFactorTypeOption[] | boolean;
}

export interface ValuesProps {
  id?: string;
  nameField: string;
  selectValue: ICFFactorTypeOption[];
  listValues: ICFFactorTypeOption[];
  isDisabled?: boolean;
  stepItem: ContainerFieldItem;
}

export const MultiSelectElement: React.FC<ValuesProps> = ( {
  id = 'select-values',
  nameField,
  selectValue,
  listValues,
  isDisabled,
  stepItem,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const factorService = useFactorService();
  const defaulStyles = useAppDefaultStyles();
  const { setValue } = useFormContext();
  const stateDefault: ISelectState = {
    value: selectValue,
  };

  const [ selectState, setSelectState ] = useState<ISelectState>( stateDefault );

  const promiseOptions = React.useCallback( ( inputValue: string ) => {
    return factorService.getOptionsToFactor( inputValue, listValues );
  }, [ listValues, factorService ] );

  const getOptionValue = React.useCallback( ( option: ICFFactorTypeOption ) => {
    return option.name;
  }, [ ] );

  const getOptionLabel: (
    option: ICFFactorTypeOption,
    labelMeta: FormatOptionLabelMeta<ICFFactorTypeOption>
  ) => React.ReactNode = React.useCallback( ( option ) => {
    return <SelectValuesOptionComponent values={ option as ICFFactorTypeOption } />;
  }, [] );

  const getGroupLabel = React.useCallback( ( group: GroupType<ICFFactorTypeOption> ) => {
    return null;
  }, [ ] );

  const onSelectField = React.useCallback( ( selectedValues: string[] ): void => {
    const splitName = nameField.split( '_' );
    if ( splitName.length >= 2 ) {
      const allValues = listValues.map( ( item: { key: string | number } ) => item.key as string );

      allValues.forEach( ( item ) => {
        const newNameField = `${item}_${splitName[1]}`;
        if ( selectedValues.includes( item ) ) {
          setValue( newNameField, yesValue, { shouldValidate: true } );
        } else {
          setValue( newNameField, noValue, { shouldValidate: true } );
        }
      } );
    }

  }, [ listValues, nameField, setValue ] );

  const handleSelect = React.useCallback( async ( selectedValue: ValueType<ICFFactorTypeOption> ) => {
    const items: ICFFactorTypeOption[] = selectedValue as ICFFactorTypeOption[];

    setSelectState( {
      value: items,
      isLoading: false,
      defaultOptions: false,
    } );

    let selectedValues: string[] = [];
    if ( items && items.length > 0 ) {
      selectedValues = items.map( ( item: { key: string | number } ) => item.key as string );
    }

    onSelectField( selectedValues );
    setValue( nameField, selectedValues.length ? JSON.stringify( selectedValues ) : '', { shouldValidate: true } );

    const isCheckedUnderwriting = checkedUnderwriting( stepItem );
    actions.recalculationPremium( isCheckedUnderwriting );
  }, [ actions, nameField, onSelectField, setValue, stepItem ] );

  const getDefaultOptions = React.useCallback( async () => {
    const items: ICFFactorTypeOption[] = await factorService.getOptionsToFactor( '', listValues );

    setSelectState( {
      ...selectState,
      isLoading: false,
      defaultOptions: items,
    } );
  }, [ factorService, listValues, selectState ] );

  const onMenuOpenHandle = React.useCallback( ( ) => {
    setSelectState( {
      ...selectState,
      isLoading: true,
    } );
    getDefaultOptions();
  }, [ getDefaultOptions, selectState ] );

  const onBlurHandle = React.useCallback( ( ) => {
    setSelectState( {
      ...selectState,
      isLoading: false,
      defaultOptions: false,
    } );
  }, [ selectState ] );

  const optionsMessage = React.useMemo( ( ) => {
    let messageText: string = t( 'base:noOptions' );
    if ( selectState.isLoading ) {
      messageText = t( 'bookingFunnel.sLoadingRecords' );
    }

    return messageText;
  }, [ t, selectState.isLoading ] );

  const customStyles = React.useMemo( () => {
    return {
      option: ( styles, { isFocused, isSelected } ) => {
        return {
          ...styles,
          backgroundColor: isFocused | isSelected ? bgColorSelected : null,
          color: defaulStyles.textColor,
        };
      },
    };
  }, [ defaulStyles.textColor ] );

  const hasNoOptions = !listValues?.length;
  const placeholder = !hasNoOptions ? t( 'selectValues.selectValue' ) : t( 'selectValues.loading' );

  return (
    <Fragment>
      <AsyncSelect
        name={ `${nameField}-multi-select` }
        id={ `${id}-search-input` }
        className="select-values-list"
        classNamePrefix="select-item"
        placeholder={ placeholder }
        defaultValue={ selectState.value }
        components={ {
          DropdownIndicator: DropdownIndicatorTagForBg,
          IndicatorSeparator: NullIndicator,
        } }
        cacheOptions
        defaultOptions={ selectState.defaultOptions }
        isLoading={ selectState.isLoading }
        loadOptions={ promiseOptions }
        onMenuOpen={ onMenuOpenHandle }
        onBlur={ onBlurHandle }
        blurInputOnSelect={ true }
        isMulti={ true }
        isClearable={ true }
        isSearchable={ true }
        escapeClearsValue
        menuPlacement="bottom"
        getOptionValue={ getOptionValue }
        formatOptionLabel={ getOptionLabel }
        formatGroupLabel={ getGroupLabel }
        onChange={ handleSelect }
        noOptionsMessage={ () => { return optionsMessage; } }
        styles={ customStyles }
        maxMenuHeight={ 200 }
        isDisabled={ isDisabled || hasNoOptions }
      />
    </Fragment>
  );
};
