import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import {
  FIELD_TYPE_BOOL,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_STRING,
  FIELD_TYPE_DATETIME,
  CFDefaultValueType,
} from '../../bf-hooks';
import { BooleanField } from './fields/BooleanField';
import { StringField } from './fields/StringField';
import { NumberField } from './fields/NumberField';
import {
  IBFProductVariable,
  ContainerFieldItem,
  IBFInsuredObjectItem,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { BaseFields } from 'Services/widgets/enums';
import { PolicyStartDateField } from './fields/PolicyStartDateField';
import { DateField } from './fields/DateField';
import { BirthDateField } from './fields/BirthDateField';
import { CheckboxField } from './fields/CheckboxField';
import { JSONItemUIType } from '../../booking-funnel/enum';

export interface BaseElementProps {
  valField: CFDefaultValueType;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string ) => void;
  stepItem?: ContainerFieldItem;
  formData: ITariffDataStep;
  insuredObjects: IBFInsuredObjectItem[];
  multiInsuredObjects?: IBFInsuredObjectItem[];
}

export const BaseElement: React.FC<BaseElementProps> = (
  { valField, variable, showTooltip, stepItem, formData, insuredObjects, multiInsuredObjects },
) => {
  const fromDate = dayjs().subtract( 80, 'years' ).format();
  const toDate = dayjs().add( 24, 'months' ).format();
  const toDateToBirthDate = dayjs().subtract( 18, 'years' ).format();

  const isDefaultDateField = React.useCallback( ( nameField: string ): boolean => {
    const excludedFields: string[] = [ BaseFields.PolicyStartDate, BaseFields.BirthDateDe, BaseFields.BirthDateEn ];
    if ( excludedFields.includes( nameField ) ) {
      return false;
    }

    return true;
  }, [ ] );

  const isBirthDateField = React.useCallback( ( nameField: string ): boolean => {
    const includedFields: string[] = [ BaseFields.BirthDateDe, BaseFields.BirthDateEn ];
    if ( includedFields.includes( nameField ) ) {
      return true;
    }

    return false;
  }, [ ] );

  const isCheckbox: boolean = stepItem?.uiType === JSONItemUIType.Checkbox;

  return (
    <Fragment>
      { variable.typeId === FIELD_TYPE_STRING && (
        <StringField
          valueField={ valField }
          variable={ variable }
          showTooltip={ showTooltip }
          stepItem={ stepItem }
        />
      ) }
      { variable.typeId === FIELD_TYPE_DATETIME && variable.name === BaseFields.PolicyStartDate && (
        <PolicyStartDateField
          valueField={ valField }
          variable={ variable }
          showTooltip={ showTooltip }
          stepItem={ stepItem }
        />
      ) }
      { variable.typeId === FIELD_TYPE_DATETIME && isDefaultDateField( variable.name ) && (
        <DateField
          valueField={ valField }
          variable={ variable }
          fromDate={ fromDate }
          toDate={ toDate }
          showTooltip={ showTooltip }
          stepItem={ stepItem }
        />
      ) }
      { variable.typeId === FIELD_TYPE_DATETIME && isBirthDateField( variable.name ) && (
        <BirthDateField
          valueField={ valField }
          variable={ variable }
          fromDate={ fromDate }
          toDate={ toDateToBirthDate }
          showTooltip={ showTooltip }
          stepItem={ stepItem }
        />
      ) }
      { variable.typeId === FIELD_TYPE_NUMBER && (
        <NumberField
          valueField={ valField }
          variable={ variable }
          showTooltip={ showTooltip }
          stepItem={ stepItem }
        />
      ) }
      { variable.typeId === FIELD_TYPE_BOOL && (
        <Fragment>
          { isCheckbox ? (
            <CheckboxField
              valueField={ valField }
              variable={ variable }
              showTooltip={ showTooltip }
              stepItem={ stepItem }
            />
          ) : (
            <BooleanField
              valueField={ valField }
              variable={ variable }
              showTooltip={ showTooltip }
              stepItem={ stepItem }
            />
          ) }
        </Fragment>
      ) }
    </Fragment>
  );
};
