import React from 'react';
import { Card } from 'react-bootstrap';

export const ViewLodingTariffResult: React.FC = ( ) => {

  return (
    <Card id="view-tariff" className="sticky-top z-idx-auto">
      <Card.Body>
        <div className="mb-3 p-0 ph-item core-tariff-ph-item row">
          <div className="ph-col-12 p-0 m-0">
            <div className="ph-row p-0 m-0">
              <div className="ph-col-12 mt-0 mb-2"></div>
              <div className="ph-col-12 m-0 big"></div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
