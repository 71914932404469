import React from 'react';
import { IApiService } from './base';

// export const mockedApiService: IApiService = new LocalStorageApi();
export const ApiContext: React.Context<IApiService> = React.createContext( undefined as any );

// This is a helper context to work with mocked and real api in the same time
export const RealApiContext: React.Context<IApiService> = React.createContext( undefined as any );

// This is a helper context to work with mocked and real api in the same time
export const MockedApiContext: React.Context<IApiService> = React.createContext( undefined as any );

export const useAppApi = (): IApiService => {
  const api = React.useContext( ApiContext );
  return api;
};
