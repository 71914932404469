import React, { Fragment } from 'react';
import { CFDefaultValueType, isObject } from '../../bf-hooks';
import { ContainerFieldItem, IBFProductVariable, ITariffDataStep } from 'Services/widgets/interfaces';
import { ICFFactorType } from '../../factor-service';
import { BaseFields, ProductFieldTypeEntity } from 'Services/widgets/enums';
import { FactorElement } from '../../base-ui-elements/FactorElement';
import { BaseElement } from './BaseElement';
import { TooltipPopup } from '../../base-ui-elements/TooltipPopup';
import { JsonObject } from '@cover42/protobuf-util';
import { FieldDefaultValue } from 'App/widget/types';

export interface FieldPanalProps {
  formData: ITariffDataStep;
  factors: ICFFactorType[];
  variable: IBFProductVariable;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
}

export const FieldPanal: React.FC<FieldPanalProps> = ( {
  formData, factors, variable, stepItem, fieldDefaultValue,
} ) => {
  const [ showTooltip, setShowTooltip ] = React.useState<boolean>( false );
  const [ bfTooltip, setBfTooltip ] = React.useState<string>( '' );

  const isBirthDateField = React.useCallback( ( nameField: string ): boolean => {
    const includedFields: string[] = [ BaseFields.BirthDateDe, BaseFields.BirthDateEn ];
    if ( includedFields.includes( nameField ) ) {
      return true;
    }

    return false;
  }, [ ] );

  const getFieldValue = ( field: IBFProductVariable ): CFDefaultValueType => {
    if ( formData !== null ) {
      const nameField = `${field.name}_${field.groupName ? field.groupName : field.insuredObjectId}`;
      return formData[nameField];
    }

    if ( !isBirthDateField( variable.name ) && isObject( variable.defaultValue ) ) {
      const objectDate = variable.defaultValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    return '';
  };

  const onShowTooltip = React.useCallback ( ( isShow: boolean, tooltip: string ): void => {
    setShowTooltip( isShow );
    setBfTooltip( tooltip );
  }, [] );

  return (
    <Fragment>
      { variable.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
        <FactorElement
          valField={ getFieldValue( variable ) }
          variable={ variable }
          factor={ factors.find( ( f ) => f.id === variable.typeId )! }
          showTooltip={ onShowTooltip }
          stepItem={ stepItem }
          fieldDefaultValue={ fieldDefaultValue }
        />
      ) }
      { variable.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
        <BaseElement
          valField={ getFieldValue( variable ) }
          variable={ variable }
          showTooltip={ onShowTooltip }
          stepItem={ stepItem }
          fieldDefaultValue={ fieldDefaultValue }
        />
      ) }
      { showTooltip && bfTooltip && (
        <TooltipPopup
          tooltipInfo={ bfTooltip }
          onClose={ () => onShowTooltip( false, '' ) }
        />
      ) }
    </Fragment>
  );
};
