import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import {
  FIELD_TYPE_BOOL,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_STRING,
  FIELD_TYPE_DATETIME,
  FIELD_TYPE_DURUNIT,
  CFDefaultValueType,
} from '../../bf-hooks';
import { BooleanField } from '../../base-ui-elements/fields/BooleanField';
import { NumberField } from '../../base-ui-elements/fields/NumberField';
import { StringField } from '../../base-ui-elements/fields/StringField';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { DatePickedField } from '../../base-ui-elements/fields/DatePickedField';
import { BaseFields } from 'Services/widgets/enums';
import { PolicyStartDateField } from '../../base-ui-elements/fields/PolicyStartDateField';
import { DateUnitField } from '../../base-ui-elements/fields/DateUnitField';
import { FieldDefaultValue } from 'App/widget/types';

export interface BaseElementProps {
  valField: CFDefaultValueType;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string ) => void;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
}

export const BaseElement: React.FC<BaseElementProps> = ( {
  valField, variable, showTooltip, stepItem, fieldDefaultValue,
} ) => {
  const fromDate = dayjs().subtract( 80, 'years' ).format();
  const toDateToBirthDate = dayjs().subtract( 18, 'years' ).format();
  const isDefaultDateField = React.useCallback( ( nameField: string ): boolean => {
    const excludedFields: string[] = [
      BaseFields.PolicyStartDate,
      BaseFields.PolicyEndDate,
      BaseFields.BirthDateDe,
      BaseFields.BirthDateEn,
    ];
    if ( excludedFields.includes( nameField ) ) {
      return false;
    }

    return true;
  }, [ ] );

  const isBirthDateField = React.useCallback( ( nameField: string ): boolean => {
    const includedFields: string[] = [ BaseFields.BirthDateDe, BaseFields.BirthDateEn ];
    if ( includedFields.includes( nameField ) ) {
      return true;
    }

    return false;
  }, [ ] );

  return (
    <Fragment>
      { variable.typeId === FIELD_TYPE_STRING && (
        <StringField
          valueField={ valField }
          variable={ variable }
          showTooltip={ showTooltip }
          stepItem={ stepItem }
          fieldDefaultValue={ fieldDefaultValue }
        />
      ) }
      { variable.typeId === FIELD_TYPE_DATETIME && variable.name === BaseFields.PolicyStartDate && (
        <PolicyStartDateField
          valueField={ valField }
          variable={ variable }
          showTooltip={ showTooltip }
          stepItem={ stepItem }
        />
      ) }
      { variable.typeId === FIELD_TYPE_DATETIME && variable.name === BaseFields.PolicyEndDate && (
        <DatePickedField
          valueField={ valField }
          variable={ variable }
          fromDate={ fromDate }
          showTooltip={ showTooltip }
          stepItem={ stepItem }
          nameFieldToCheck={ BaseFields.PolicyStartDate }
        />
      ) }
      { variable.typeId === FIELD_TYPE_DATETIME && isDefaultDateField( variable.name ) && (
        <DatePickedField
          valueField={ valField }
          variable={ variable }
          fromDate={ fromDate }
          showTooltip={ showTooltip }
          stepItem={ stepItem }
        />
      ) }
      { variable.typeId === FIELD_TYPE_DATETIME && isBirthDateField( variable.name ) && (
        <DatePickedField
          valueField={ valField }
          variable={ variable }
          fromDate={ fromDate }
          toDate={ toDateToBirthDate }
          showTooltip={ showTooltip }
          stepItem={ stepItem }
        />
      ) }
      { variable.typeId === FIELD_TYPE_NUMBER && (
        <NumberField valueField={ valField } variable={ variable } showTooltip={ showTooltip } stepItem={ stepItem } />
      ) }
      { variable.typeId === FIELD_TYPE_BOOL && (
        <BooleanField valueField={ valField } variable={ variable } showTooltip={ showTooltip } stepItem={ stepItem } />
      ) }
      { variable.typeId === FIELD_TYPE_DURUNIT && (
        <DateUnitField
          valueField={ valField }
          variable={ variable }
          stepItem={ stepItem }
        />
      ) }
    </Fragment>
  );
};
