import React, { Fragment } from 'react';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { ILeadData, IScreenType } from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from '../../booking-funnel/BookingFunnel';
import { ActiveSubStepCore, RecalculationTariff } from '../DynamicCore';
import { SummarySubStep } from './summary-sub-steps/SummarySubStep';
import { PaymentSubStep } from './summary-sub-steps/PaymentSubStep';
import { StepInfoItem } from '../interfaces';

export interface SummaryStepProps {
  subStep: ActiveSubStepCore;
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  screenType: IScreenType;
  stepData: StepInfoItem;
  recalculationTariff: RecalculationTariff;
  policyStepNames: string[];
}

export const SummaryStep: React.FC<SummaryStepProps> = ( props ) => {
  const { subStep, productCode, lead, productData, screenType, stepData, recalculationTariff, policyStepNames } = props;

  const recalculationConfig: RecalculationTariff = {
    ...recalculationTariff,
    isCheckedUnderwriting: true,
  };

  if ( productData === null ) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      { subStep === ActiveSubStepCore.SummarySubStep1 && (
        <SummarySubStep
          productCode={ productCode }
          lead={ lead }
          productData={ productData }
          screenType={ screenType }
          stepData={ stepData }
          recalculationTariff={ recalculationConfig }
          policyStepNames={ policyStepNames }
        />
      ) }
      { subStep === ActiveSubStepCore.SummarySubStep2 && (
        <PaymentSubStep
          lead={ lead }
          productData={ productData }
          productCode={ productCode }
          screenType={ screenType }
          stepData={ stepData }
          recalculationTariff={ recalculationConfig }
          policyStepNames={ policyStepNames }
        />
      ) }
    </Fragment>
  );
};
