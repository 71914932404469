import React from 'react';
import { sleep } from 'App/utils';

export interface ICFFactorTypeOption {
  name: string;
  key: number | string;
  value: number;
  productFactorId?: number;
}

export interface ICFFactorType {
  id?: number;
  group: string;
  label: string;
  name: string;
  values: ICFFactorTypeOption[];
}

export interface IFactorService {
  getOptionsToFactor(
    input: string, options: ICFFactorTypeOption[], isShowAllItems?: boolean,
  ): Promise<ICFFactorTypeOption[]>;
}

export class FactorService implements IFactorService {
  getOptionsToFactor(
    input: string, options: ICFFactorTypeOption[], isShowAllItems?: boolean,
  ): Promise<ICFFactorTypeOption[]> {
    return sleep( 500 ).then( () => {
      let res: ICFFactorTypeOption[] = options;
      if ( input.length > 0 ) {
        const search = input.toLocaleLowerCase();
        res = res.filter( ( o ) => {
          return o.name.toLowerCase().includes ( search );
        } );
      }

      if ( !isShowAllItems ) {
        // Selecting first 20 items
        res = res.slice( 0, 20 );
      }

      return res;
    } );
  }
}

export const CFFactorServiceContext: React.Context<IFactorService> =
  React.createContext( new FactorService() as IFactorService );

export const useFactorService = (): IFactorService => {
  return React.useContext( CFFactorServiceContext );
};
