import React, { Fragment } from 'react';
import { IBFProductVariable, IProductInvoice, ContainerFieldItem } from 'Services/widgets/interfaces';
import { getPriceToProduct } from '../../core-hooks';
import { useLocale } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';

export interface PriceFieldProps {
  invoiceData?: IProductInvoice[];
  variable: IBFProductVariable;
  stepItem?: ContainerFieldItem;
}

export const PriceField: React.FC<PriceFieldProps> = ( {
  invoiceData,
  variable,
  stepItem,
} ) => {
  const locale = useLocale();

  return (
    <Fragment>
      <div className="bg-price-info d-flex justify-content-center align-items-center">
        <span className="price-label mr-2">
          <p className="p-0 m-0">Prämie brutto:</p>
        </span>
        <span className="price">{ getPriceToProduct( stepItem!, invoiceData!, locale ) }</span>
      </div>
    </Fragment>
  );
};
