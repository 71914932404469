import React, { Fragment } from 'react';
import checkProductIconUrl from 'assets/bf-images/funk/check_product_icon.svg';
import { Col } from 'react-bootstrap';


export const ProductList: React.FC = ( ) => {
  return (
    <Fragment>
      <h2 className="f-26 btn-link-green text-center mb-4">Berufshaftpflicht-Versicherung</h2>
      <Col md={ 12 } className="mt-4 px-0 d-inline-block">
        <p className="d-flex">
          <img className="pr-2 d-inline-block" src={ checkProductIconUrl } alt="Check Product" />
          <span className="d-inline-block">
            <b>5 Mio. € Deckungssumme</b> pauschal für Personen-, Sach und Vermögensschäden
          </span>
        </p>
        <p className="d-flex">
          <img className="pr-2 d-inline-block" src={ checkProductIconUrl } alt="Check Product" />
          <span className="d-inline-block">Erfüllt die Anforderungen der Zulassungsstellen der KVen</span>
        </p>
        <p className="d-flex">
          <img className="pr-2 d-inline-block" src={ checkProductIconUrl } alt="Check Product" />
          <span className="d-inline-block">
          Bis zu <b>2 angestellte Fachärzte/Fachärztinnen</b> derselben Fachrichtung <b>beitragsfrei</b> mitversichert
          </span>
        </p>
        <p className="d-flex">
          <img className="pr-2 d-inline-block" src={ checkProductIconUrl } alt="Check Product" />
          <span className="d-inline-block">
            Alle angestellten Ärzte/Ärztinnen ohne Facharztanerkennung sowie sämtliche MFA enthalten
          </span>
        </p>
        <p className="d-flex">
          <img className="pr-2 d-inline-block" src={ checkProductIconUrl } alt="Check Product" />
          <span className="d-inline-block">Erweiterter Strafrechtsschutz enthalten</span>
        </p>
        <p className="d-flex">
          <img className="pr-2 d-inline-block" src={ checkProductIconUrl } alt="Check Product" />
          <span className="d-inline-block"><b>Nachhaftung</b> enthalten (zeitlich unbegrenzt)</span>
        </p>
        <p className="d-flex">
          <img className="pr-2 d-inline-block" src={ checkProductIconUrl } alt="Check Product" />
          <span className="d-inline-block">
            Erstellt in Zusammenarbeit mit der <b>SIGNAL IDUNA Allgemeine Versicherung AG</b>
          </span>
        </p>
      </Col>
    </Fragment>
  );
};
