import React, { useState, Fragment } from 'react';
import AsyncSelect from 'react-select/async';
import { GroupType, FormatOptionLabelMeta, ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAppDefaultStyles } from 'Services/stylings';
import { ICFFactorTypeOption, useFactorService } from '../factor-service';
import { SelectValuesOptionComponent } from './select-values/options';
import { useFormContext } from 'react-hook-form';
import { DropdownIndicatorTagForBg, NullIndicator } from 'App/components/utils/react-select/indicators';

const bgColorSelected = '#ECF5FA';

export interface ISelectState {
  value: ValueType<ICFFactorTypeOption>;
  isLoading?: boolean;
  defaultOptions?: ICFFactorTypeOption[] | boolean;
}

export interface ValuesProps {
  id?: string;
  labelField: string;
  nameField: string;
  selectValue: ICFFactorTypeOption;
  listValues: ICFFactorTypeOption[];
  isDisabled?: boolean;
  isShowAllItems?: boolean;
}

export const SelectElement: React.FC<ValuesProps> = ( {
  id = 'select-values',
  labelField,
  nameField,
  selectValue,
  listValues,
  isDisabled,
  isShowAllItems = false,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const factorService = useFactorService();
  const defaulStyles = useAppDefaultStyles();
  const { setValue } = useFormContext();
  const stateDefault: ISelectState = {
    value: selectValue,
  };

  const [ selectState, setSelectState ] = useState<ISelectState>( stateDefault );

  const promiseOptions = React.useCallback( ( inputValue: string ) => {
    return factorService.getOptionsToFactor( inputValue, listValues, isShowAllItems );
  }, [ factorService, isShowAllItems, listValues ] );

  const getOptionValue = React.useCallback( ( option: ICFFactorTypeOption ) => {
    return option.name;
  }, [ ] );

  const getOptionLabel: (
    option: ICFFactorTypeOption,
    labelMeta: FormatOptionLabelMeta<ICFFactorTypeOption>
  ) => React.ReactNode = React.useCallback( ( option ) => {
    return <SelectValuesOptionComponent values={ option as ICFFactorTypeOption } />;
  }, [] );

  const getGroupLabel = React.useCallback( ( group: GroupType<ICFFactorTypeOption> ) => {
    return null;
  }, [ ] );

  const handleSelect = React.useCallback( ( selectedValue: ValueType<ICFFactorTypeOption> ) => {
    const item: ICFFactorTypeOption = selectedValue as ICFFactorTypeOption;
    setSelectState( {
      value: item,
      isLoading: false,
      defaultOptions: false,
    } );
    setValue( nameField, item, { shouldValidate: true } );
  }, [ nameField, setValue ] );

  // Reset select current value when it changed in the parent control
  React.useEffect( () => {
    handleSelect( selectValue );
  }, [ handleSelect, selectValue ] );

  const getDefaultOptions = React.useCallback( async () => {
    const items: ICFFactorTypeOption[] = await factorService.getOptionsToFactor( '', listValues, isShowAllItems );

    setSelectState( {
      ...selectState,
      isLoading: false,
      defaultOptions: items,
    } );
  }, [ factorService, isShowAllItems, listValues, selectState ] );

  const onMenuOpenHandle = React.useCallback( ( ) => {
    setSelectState( {
      ...selectState,
      isLoading: true,
    } );
    getDefaultOptions();
  }, [ getDefaultOptions, selectState ] );

  const onBlurHandle = React.useCallback( ( ) => {
    setSelectState( {
      ...selectState,
      isLoading: false,
      defaultOptions: false,
    } );
  }, [ selectState ] );

  const optionsMessage = React.useMemo( ( ) => {
    let messageText: string = t( 'base:noOptions' );
    if ( selectState.isLoading ) {
      messageText = t( 'bookingFunnel.sLoadingRecords' );
    }

    return messageText;
  }, [ t, selectState.isLoading ] );

  const customStyles = React.useMemo( () => {
    return {
      option: ( styles, { isFocused, isSelected } ) => {
        return {
          ...styles,
          backgroundColor: isFocused | isSelected ? bgColorSelected : null,
          color: defaulStyles.textColor,
        };
      },
    };
  }, [ defaulStyles.textColor ] );

  const hasNoOptions = !listValues?.length;
  const placeholder = !hasNoOptions ? t( 'selectValues.selectValue' ) : t( 'selectValues.loading' );

  return (
    <Fragment>
      <AsyncSelect
        name={ nameField }
        id={ `${id}-search-input` }
        className="select-values-list"
        classNamePrefix="select-item"
        placeholder={ placeholder }
        defaultValue={ selectState.value }
        components={ {
          DropdownIndicator: DropdownIndicatorTagForBg,
          IndicatorSeparator: NullIndicator,
        } }
        cacheOptions
        defaultOptions={ selectState.defaultOptions }
        isLoading={ selectState.isLoading }
        loadOptions={ promiseOptions }
        onMenuOpen={ onMenuOpenHandle }
        onBlur={ onBlurHandle }
        blurInputOnSelect={ true }
        isMulti={ false }
        isClearable={ false }
        isSearchable={ true }
        escapeClearsValue
        menuPlacement="bottom"
        getOptionValue={ getOptionValue }
        formatOptionLabel={ getOptionLabel }
        formatGroupLabel={ getGroupLabel }
        onChange={ handleSelect }
        noOptionsMessage={ () => { return optionsMessage; } }
        styles={ customStyles }
        maxMenuHeight={ 200 }
        isDisabled={ isDisabled || hasNoOptions }
      />
    </Fragment>
  );
};
