export enum DateTimePlaceHolders {
  Today = 'today',
  Tomorrow = 'tomorrow',
  StartNextMonth = 'startNextMonth',
  StartNextYear = 'startNextYear',
}

export enum DateTimeOffsets {
  Day = 'd',
  Month = 'm',
  Year = 'y',
}

export enum DateTimeOperations {
  Add = '+',
  Subtract = '-',
}
