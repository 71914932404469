import React from 'react';
import {
  ActiveStepCore,
  useCoreActions,
  ActiveSubStepCore,
  RecalculationTariff,
} from '../../DynamicCore';
import { Row, Col } from 'react-bootstrap';
import { PaymentType } from 'Services/widgets/enums';
import { StripeSystem } from '../payment-systems/StripeSystem';
import { AppAlertUI } from 'App/components/utils/alerts/AppAlertService';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { IScreenType, ILeadData } from 'Services/widgets/interfaces';
import { TariffInfo } from '../../ui-components/TariffInfo';
import { mainStepName } from 'App/components/widgets/booking-funnel/steps/PolicyStep1';
import { FormProvider, useForm } from 'react-hook-form';
import { StepInfoItem } from '../../interfaces';
import { JSONSubSteps } from 'App/components/widgets/booking-funnel/enum';
import { isEnabledPremiumCalculation } from '../../core-hooks';
import { SavedDataPopup } from '../../ui-components/SavedDataPopup';

export interface PaymentSubStepItem {
  paymentMethod: string;
  accountFirstName?: string;
  accountLastName?: string;
  accountEmail?: string;
  paymentDataValid?: boolean;
}

export interface PaymentSubStepProps {
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  productCode: string;
  screenType: IScreenType;
  stepData: StepInfoItem;
  recalculationTariff: RecalculationTariff;
  policyStepNames: string[];
}

export const PaymentSubStep: React.FC<PaymentSubStepProps> = (
  { lead, productData, productCode, screenType, recalculationTariff, stepData, policyStepNames },
) => {
  const actions = useCoreActions();
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );

  const formContext = useForm<PaymentSubStepItem>( {
    mode: 'onChange',
  } );

  const { leadData } = productData;
  const steps = productData.stepsConfig?.steps!.find( ( s ) => s.name === mainStepName );
  const enabledPremiumCalculation = isEnabledPremiumCalculation( stepData, lead );
  const currentStep = stepData.steps && stepData.steps.find(
    ( subStep ) => subStep.subStepName === JSONSubSteps.Payment,
  );

  if ( lead[ActiveStepCore.Summary] === null && actions.goToSummaryStep ) {
    actions.goToSummaryStep( ActiveStepCore.Summary, ActiveSubStepCore.SummarySubStep1, false );
  }

  const onCloseSavedPopup = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  return (
    <FormProvider { ...formContext }>
      <div id="payment-data-step">
        { enabledPremiumCalculation && (
          <TariffInfo
            lead={ lead }
            productData={ productData }
            productCode={ productCode }
            screenType={ screenType }
            isIgnoreOnRecalculation={ true }
            recalculationTariff={ recalculationTariff }
            subSteps={ steps?.subSteps }
            keyStep={ stepData.nameStep }
            className="max-w-906"
          />
        ) }
        <Row className="mt-5 mx-0 bf-custom-padding">
          { currentStep && currentStep.stepTitle && (
            <Col md={ 12 } className="head-tab mb-2 text-center mx-0">
              <div dangerouslySetInnerHTML={
                { __html: currentStep.stepTitle }
              }
              >
              </div>
            </Col>
          ) }
          <Col md={ 12 } className="max-w-667 mt-4" id="alert-payment-page">
            <AppAlertUI />
          </Col>
          <Col className="mt-4 max-w-667" md={ 12 }>
            { leadData.psp === PaymentType.Stripe && (
              <StripeSystem
                formContext={ formContext }
                lead={ lead }
                isCustomLayout={ false }
                productData={ productData }
                productCode={ productCode }
                currentStep={ currentStep! }
                policyStepNames={ policyStepNames }
              />
            ) }
          </Col>
        </Row>
        { isShowSavedStep && (
          <SavedDataPopup
            currentStep={ stepData.nameStep }
            productCode={ productCode }
            lead={ lead }
            productData={ productData! }
            screenType={ screenType }
            onClose={ () => onCloseSavedPopup( ) }
            goNextStep={ goNextStep }
            policyStepNames={ policyStepNames }
          />
        ) }
      </div>
    </FormProvider>
  );
};
