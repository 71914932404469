import React, { Fragment } from 'react';
import GenderOptions from '../../../genderOptions';
import rightArrowIconUrl from 'assets/bf-images/funk/right_arrow.svg';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Row, Col, Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useWidgetService } from 'Services/widget';
import { ActiveSubStepCore, useCoreActions, ActiveStepCore } from '../../DynamicCore';
import { AddressSettings, ILeadData, ITariffDataStep, SubStepItem } from 'Services/widgets/interfaces';
import { ICFFactorTypeOption } from '../../../factor-service';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { isEmpty, isEqual } from 'lodash';
import { FUNKBFStep } from 'App/components/widgets/funk/enums';
import { MobileNavCore } from '../../ui-components/MobileNavCore';
import { Title } from 'Services/widgets/enums';
import { AddressPersonalMultiLine } from '../../ui-elements/AddressPersonalMultiLine';
import { AddressPersonalSingleLine } from '../../ui-elements/AddressPersonalSingleLine';
import { AccountType } from '@cover42/ts-contracts';
import { phoneValidationRegExp } from 'App/components/widgets/bf-hooks';

const defaultCountry = 'Deutschland';

export interface PersonalDataStepCore {
  firstName: string;
  title: Title;
  lastName: string;
  email: string;
  gender: string;
  street: string;
  houseNumber: string;
  zipCode: ICFFactorTypeOption | string;
  city: string;
  phone: string;
  country: string;
  address?: string;
  type?: AccountType;
}

const initialData: PersonalDataStepCore = {
  gender: '',
  title: Title.none,
  firstName: '',
  lastName: '',
  zipCode: '',
  city: '',
  street: '',
  houseNumber: '',
  phone: '',
  email: '',
  country: defaultCountry,
};

export interface PersonalDataStepProps {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  stepData?: SubStepItem;
}

export const personalDataKey = 'personalData';
const addressField = 'address';

export const RegistrationStep: React.FC<PersonalDataStepProps> = ( { productCode, lead, productData, stepData } ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { showAlert } = useAppAlertService();
  const service = useWidgetService();

  const previousFormData = React.useRef<ITariffDataStep>( {} );

  // A meeting has to happen to decide how are we going to design the configuration step for personal data, if we want
  // to have a single container or do we plan on having multiple containers, among other things.
  // For now, we are assuming that there is only one container.
  const containersPersonalData = stepData && stepData.containers;

  if ( containersPersonalData && ( containersPersonalData.length > 1 || containersPersonalData.length === 0 ) ) {
    throw new Error( 'The personal data step should have only one container' );
  }

  const items = containersPersonalData && containersPersonalData[0].items;
  const addressItem = items && items.find( ( item ) => item.name === 'address' );
  const settings = addressItem && addressItem.settings as AddressSettings;
  const isAddressSingleLine = settings && settings.isSingleLine;

  let personalDataFields: PersonalDataStepCore;
  if ( lead.personalData !== null ) {
    personalDataFields = lead.personalData as PersonalDataStepCore;
  } else if ( !lead.personalData && isAddressSingleLine ) {
    personalDataFields = {
      ...initialData,
      address: '',
    };
  } else {
    personalDataFields = initialData;
  }

  // This is intended to avoid the edge case where the user saved a multiline address
  // and then faced a single line address booking funnel.
  if ( isAddressSingleLine && lead.personalData !== null ) {
    const { street, houseNumber, zipCode, city }
      = lead.personalData as PersonalDataStepCore;

    const newAddress = ( street ? street : '' )
    + ( houseNumber ? ` ${houseNumber}, ` : ', ' )
    + ( zipCode ? `${zipCode} `: '' )
    + ( city ? city : '' );

    personalDataFields = {
      ...personalDataFields,
      address: newAddress,
    };
  }

  const formContext = useForm<PersonalDataStepCore>( {
    mode: 'onChange',
    shouldUnregister: true,
  } );

  const { errors, control, formState, setValue, getValues } = formContext;

  const onSubmit = React.useCallback( async ( formData: PersonalDataStepCore ) => {
    // When AddressSingleLine is used we are using "address" field to store the user's input.
    // We have to delete it before sending it to the backend because it is not expected to receive it.
    if ( formData[addressField] ) {
      delete formData[addressField];
    }

    const result = await service.savedInfo( personalDataKey, formData );

    if ( result.errorCode === 0 && actions.goToSummaryStep ) {
      actions.goToSummaryStep( ActiveStepCore.Summary, ActiveSubStepCore.SummarySubStep1, true );
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, service, showAlert, t ] );

  React.useEffect( () => {
    if ( !formState.isValid ) {
      return;
    }

    const formValues = getValues();
    const saveFormValues = async () => {
      try {
        await service.savedInfo( personalDataKey, previousFormData.current );
        actions.reloadLead();
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };

    if ( isEmpty( previousFormData.current ) ) {
      previousFormData.current = formValues;
      return;
    }

    if ( !isEqual( formValues, previousFormData.current ) ) {
      previousFormData.current = formValues;
      saveFormValues();
    }
  }, [ actions, formState.isValid, formState.isValidating, getValues, service, showAlert, t ] );

  const handleChange = ( e: React.ChangeEvent<HTMLSelectElement>, name: string ) => {
    const element = e.target as HTMLSelectElement;
    const value = element.value;

    setValue( name, value, { shouldValidate: true } );
  };

  const formatPhoneNumber = ( phoneNumber: string ): string => {
    if ( !phoneNumber ) {
      return '';
    }

    return phoneNumber.replace( / /g, '' ).replaceAll( '-', '' );
  };

  const isValidatePhone = ( value: string ): string | boolean => {
    if ( !value ) {
      return true;
    }

    const phone = formatPhoneNumber( value );
    if ( !phoneValidationRegExp.test( phone ) ) {
      const errMessage = t( 'bookingFunnel.personalData.phoneFormatError' );
      return errMessage;
    }

    return true;
  };

  const isValidEmail = ( email: string ): boolean => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( email );
  };

  const isValidateEmail = ( value: string ): string | boolean => {
    if ( value && !isValidEmail( value ) ) {
      const errMessage = t( 'bookingFunnel.personalData.emailFormatError' );
      return errMessage;
    }

    return true;
  };

  if ( productData === null ) {
    return <div className="bf-loading h-100 my-5"><LoadingSpinner /></div>;
  }

  return (
    <div className="bf-custom-padding">
      <FormProvider { ...formContext }>
        <div id="personal-data-step" className="mt-2 px-0 max-w-744">
          <Row className="mx-0">
            <Col md={ 12 } className="head-tab mb-2 text-center mx-0">
              <h1 className="f-26">{ t( 'bookingFunnel.personalDataFunk.stepTitle' ) }</h1>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col className="px-0 mt-5" md={ 12 }>
              <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
                <Row className="max-w-450">
                  <Col sm={ 12 } className="mb-4">
                    <Form.Label className="dynamic-main-label">
                      { t( 'bookingFunnel.personalDataFunk.title' ) }
                    </Form.Label>
                    <Controller
                      name="gender"
                      control={ control }
                      rules={ { required: true } }
                      defaultValue={ personalDataFields.gender }
                      render={ ( props ) => (
                        <FormGroup className="custom-btn-radio mb-2 max-w-280">
                          <div className="dynamic-gender">
                            { GenderOptions.map( ( o, idx ) => {
                              return (
                                <Fragment key={ idx }>
                                  <FormControl
                                    { ...props }
                                    className="form-check-input"
                                    type="radio"
                                    id={ o.value }
                                    value={ o.value }
                                    checked={ props.value === o.value }
                                    onBlur={ props.onBlur }
                                  />
                                  <Form.Label
                                    className="btn btn-border-radio"
                                    htmlFor={ o.value }
                                  >
                                    { t( `bookingFunnel.personalDataFunk.${o.value}` ) }
                                  </Form.Label>
                                </Fragment>
                              );
                            } ) }
                          </div>
                          <Form.Control
                            type="hidden"
                            isInvalid={ errors[props.name] !== undefined }
                          />
                          <Form.Control.Feedback type="invalid">
                            { t( 'base:forms.messages.fieldRequired',
                              { fieldLabel: t( 'bookingFunnel.personalDataFunk.title' ) } ) }
                          </Form.Control.Feedback>
                        </FormGroup>
                      ) }
                    />
                  </Col>
                  <Col sm={ 12 } className="mb-4">
                    <Form.Label className="dynamic-main-label">
                      { t( 'bookingFunnel.funk.registration.nameTitle' ) }
                    </Form.Label>
                    <Controller
                      name="firstName"
                      control={ control }
                      rules={ { required: true } }
                      defaultValue={ personalDataFields.firstName }
                      render={ ( props ) => (
                        <FormGroup controlId={ props.name }>
                          <Form.Label>
                            { t( 'bookingFunnel.personalDataFunk.firstName' ) }
                          </Form.Label>
                          <Form.Control
                            { ...props }
                            type="text"
                            placeholder={ t( 'bookingFunnel.personalDataFunk.firstName' ) }
                            isInvalid={ errors[props.name] !== undefined }
                          />
                          <Form.Control.Feedback type="invalid">
                            { t( 'base:forms.messages.fieldRequired',
                              { fieldLabel: t( 'bookingFunnel.personalDataFunk.firstName' ) } ) }
                          </Form.Control.Feedback>
                        </FormGroup>
                      ) }
                    />
                  </Col>
                  <Col sm={ 12 } className="mb-4">
                    <Controller
                      name="lastName"
                      control={ control }
                      rules={ { required: true } }
                      defaultValue={ personalDataFields.lastName }
                      render={ ( props ) => (
                        <FormGroup controlId={ props.name }>
                          <Form.Label>
                            { t( 'bookingFunnel.personalDataFunk.lastName' ) }
                          </Form.Label>
                          <Form.Control
                            { ...props }
                            type="text"
                            placeholder={ t( 'bookingFunnel.personalDataFunk.lastName' ) }
                            isInvalid={ errors.lastName !== undefined }
                          />
                          <Form.Control.Feedback type="invalid">
                            { t( 'base:forms.messages.fieldRequired',
                              { fieldLabel: t( 'bookingFunnel.personalDataFunk.lastName' ) } ) }
                          </Form.Control.Feedback>
                        </FormGroup>
                      ) }
                    />
                  </Col>
                  <Col sm={ 12 } className="mb-4">
                    <Form.Label className="dynamic-main-label">
                      { t( 'bookingFunnel.funk.registration.nameTitleField' ) }
                    </Form.Label>
                    <Controller
                      name="title"
                      control={ control }
                      defaultValue={ personalDataFields.title || Title.none }
                      render={ ( props ) => (
                        <FormGroup className="date-picker-field" controlId={ props.name }>
                          <div
                            className={ `${props.value ?
                              'date-container' : 'date-container form-select-empty' }` }
                          >
                            <Form.Control
                              as="select"
                              className="form-select"
                              value={ props.value }
                              onChange={ ( e: React.ChangeEvent<HTMLSelectElement> ) => handleChange( e, props.name ) }
                              placeholder={ t( 'bookingFunnel.funk.registration.nameTitleField' ) }
                              isInvalid={ errors[props.name] !== undefined }
                            >
                              { Object.keys( Title ).map( ( key, idx ) =>
                                <option
                                  key={ idx }
                                  //When value is empty i.e. Title.none, the value really stored is the innerHTML
                                  value={ Title[key] }
                                >
                                  { t( `bookingFunnel.personalDataFunk.honorifics.${key}` ) }
                                </option> ) }
                            </Form.Control>
                          </div>
                          <Form.Control
                            type="hidden"
                            isInvalid={ errors[props.name] !== undefined }
                          />
                          <Form.Control.Feedback type="invalid">
                            <Fragment>
                              { t( 'base:forms.messages.fieldRequired',
                                { fieldLabel: t( 'bookingFunnel.funk.registration.nameTitleField' ) } ) }
                            </Fragment>
                          </Form.Control.Feedback>
                        </FormGroup>
                      ) }
                    />
                  </Col>
                  {
                    isAddressSingleLine ?
                      <AddressPersonalSingleLine
                        personalDataFields={ personalDataFields }
                        stepItem={ addressItem }
                      /> :
                      <AddressPersonalMultiLine
                        personalDataFields={ personalDataFields }
                        defaultCountry={ defaultCountry }
                      />
                  }
                  <Col sm={ 12 } className="mb-4">
                    <Controller
                      name="phone"
                      control={ control }
                      rules={ {
                        required: true,
                        validate: ( value ) => {
                          return isValidatePhone( value );
                        },
                      } }
                      defaultValue={ personalDataFields.phone }
                      render={ ( props ) => (
                        <FormGroup controlId={ props.name }>
                          <Form.Label className="dynamic-main-label">
                            { t( 'bookingFunnel.funk.registration.telTitle' ) }
                          </Form.Label>
                          <Form.Control
                            { ...props }
                            type="text"
                            placeholder={ t( 'bookingFunnel.personalDataFunk.phone' ) }
                            isInvalid={ errors[props.name] !== undefined }
                          />
                          <span className="float-right f-12 mt-1">
                            { t( 'bookingFunnel.personalDataFunk.phoneFormat' ) }
                          </span>
                          <Form.Control.Feedback type="invalid">
                            { errors[props.name]?.message ?
                              ( errors[props.name]?.message ) : (
                                <Fragment>
                                  { t( 'base:forms.messages.fieldRequired',
                                    { fieldLabel: t( 'bookingFunnel.personalData.phone' ) } ) }
                                </Fragment>
                              ) }
                          </Form.Control.Feedback>
                        </FormGroup>
                      ) }
                    />
                  </Col>
                  <Col sm={ 12 } className="mb-4">
                    <Controller
                      name="email"
                      control={ control }
                      rules={ {
                        required: true,
                        validate: ( value ) => {
                          return isValidateEmail( value );
                        },
                      } }
                      defaultValue={ personalDataFields.email }
                      render={ ( props ) => (
                        <FormGroup controlId={ props.name }>
                          <Form.Label className="dynamic-main-label">
                            { t( 'bookingFunnel.funk.registration.emailTitle' ) }
                          </Form.Label>
                          <Form.Control
                            { ...props }
                            type="text"
                            placeholder={ t( 'bookingFunnel.personalDataFunk.email' ) }
                            isInvalid={ errors[props.name] !== undefined }
                          />
                          <Form.Control.Feedback type="invalid">
                            { errors[props.name]?.message ?
                              ( errors[props.name]?.message ) : (
                                <Fragment>
                                  { t( 'base:forms.messages.fieldRequired',
                                    { fieldLabel: t( 'bookingFunnel.personalData.email' ) } ) }
                                </Fragment>
                              ) }
                          </Form.Control.Feedback>
                        </FormGroup>
                      ) }
                    />
                  </Col>
                  <Col sm={ 12 } className="mb-4">
                    <p className="text-center">
                      { t( 'bookingFunnel.funk.registration.textInfo' ) }
                    </p>
                  </Col>
                </Row>
                <div className="max-w-667">
                  <MobileNavCore
                    lead={ lead }
                  />
                </div>
                <Row className="dynamic-btn-panel mt-5 mx-0 d-flex justify-content-space-between">
                  <Button
                    id={ `${FUNKBFStep.RegistrationStep}_back` }
                    type="button"
                    variant="link"
                    className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
                    onClick={ () => actions.goToPersonalStep( ActiveStepCore.PersonalData, null, true ) }
                  >
                    { t( 'bookingFunnel.previousBtn' ) }
                  </Button>
                  <Button
                    id={ `${FUNKBFStep.RegistrationStep}_next` }
                    type="submit"
                    variant="primary"
                    disabled={ formState.isSubmitting }
                    className="mr-0 mb-0 bg-btn-primary"
                  >
                    { t( 'bookingFunnel.nextBtn' ) }
                    <img src={ rightArrowIconUrl } className="ml-2" alt={ t( 'bookingFunnel.nextBtn' ) } />
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </FormProvider>
    </div>
  );
};
