import React from 'react';

export interface IAppDefaultStyles {
  maxMenuHeight: number;
  bgColorSelected: string;
  textColor: string;
  widthInputUserSearch: string;
  maxMenuHeightUserSearch: number;
}

const defaultStyles: IAppDefaultStyles = {
  maxMenuHeight: 400,
  bgColorSelected: '#F0F2F4',
  textColor: '#111',
  widthInputUserSearch: '243px',
  maxMenuHeightUserSearch: 183,
};

export const AppDefaultStylesContext: React.Context<IAppDefaultStyles> = React.createContext( defaultStyles );

export const useAppDefaultStyles = (): IAppDefaultStyles => {
  return React.useContext( AppDefaultStylesContext );
};
