import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { AccountDataType, InputType } from 'Services/widgets/enums';
import { ContainerFieldItem, ILeadData, ITariffDataStep } from 'Services/widgets/interfaces';
import { GenderField } from '../steps/personal-sub-steps/form-fields/GenderField';
import { EmailField } from '../steps/personal-sub-steps/form-fields/EmailField';
import { StringField } from '../steps/personal-sub-steps/form-fields/StringField';
import { TitleField } from '../steps/personal-sub-steps/form-fields/TitleField';
import { PhoneField } from '../steps/personal-sub-steps/form-fields/PhoneField';
import { PhoneInputAccountField } from '../steps/personal-sub-steps/form-fields/PhoneInputAccountField';
import { useTranslation } from 'react-i18next';
import { BirthDateField } from 'App/components/widgets/base-ui-elements/BirthDateField';
import { CoreAddressSingleLine } from './CoreAddressSingleLine';
import { JSONItemUIType } from 'App/components/widgets/booking-funnel/enum';
import { CoreBirthDateField } from '../steps/personal-sub-steps/form-fields/CoreBirthDateField';
import { AccountTypeField } from '../steps/personal-sub-steps/form-fields/AccountTypeField';
import { FieldDefaultValue } from 'App/widget/types';
import { CFDefaultValueType } from 'App/components/widgets/bf-hooks';
import { NumberField } from '../steps/personal-sub-steps/form-fields/NumberField';

export interface CoreAccountPanelProps {
  lead: ILeadData;
  formData: ITariffDataStep;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
  fieldDefaultValue?: FieldDefaultValue;
}

export const CoreAccountPanel: React.FC<CoreAccountPanelProps> = ( {
  lead, formData, stepItem, isDisabled, fieldDefaultValue,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const endDateToBirthDate = dayjs().subtract( 18, 'years' ).format();
  const isReactDayPicker: boolean = stepItem?.uiType === JSONItemUIType.ReactDayPicker || false;
  const { productFieldValue, isReadOnly } = fieldDefaultValue || {};

  const getDefaultValue = (): CFDefaultValueType => {
    if ( formData && stepItem && formData[stepItem.fieldName] ) {
      return formData[stepItem.fieldName];
    }

    if ( productFieldValue ) {
      return productFieldValue as string;
    }

    if ( stepItem && stepItem.defaultValue ) {
      return stepItem.defaultValue as string;
    }

    return '';
  };

  return (
    <Fragment>
      { stepItem && (
        <Fragment>
          { stepItem.dataType === AccountDataType.AccountType && (
            <AccountTypeField
              fieldValue={ getDefaultValue() }
              fieldName={ stepItem.fieldName }
              stepItem={ stepItem }
              isDisabled={ isDisabled }
            />
          ) }
          { stepItem.dataType === AccountDataType.Gender && (
            <GenderField
              fieldValue={ getDefaultValue() }
              fieldName={ stepItem.fieldName }
              stepItem={ stepItem }
              isDisabled={ isDisabled }
            />
          ) }
          { stepItem.dataType === AccountDataType.Email && (
            <EmailField
              fieldValue={ getDefaultValue() }
              fieldName={ stepItem.fieldName }
              stepItem={ stepItem }
              isDisabled={ isDisabled }
              isReadOnly={ isReadOnly }
            />
          ) }
          { stepItem.dataType === AccountDataType.String && (
            <StringField
              fieldValue={ getDefaultValue() }
              fieldName={ stepItem.fieldName }
              stepItem={ stepItem }
              isDisabled={ isDisabled }
              isReadOnly={ isReadOnly }
            />
          ) }
          { stepItem.dataType === AccountDataType.TitleType && (
            <TitleField
              fieldValue={ getDefaultValue() }
              fieldName={ stepItem.fieldName }
              stepItem={ stepItem }
              isDisabled={ isDisabled }
            />
          ) }
          { stepItem.dataType === AccountDataType.Phone && stepItem.inputType !== InputType.PhoneInput && (
            <PhoneField
              fieldValue={ getDefaultValue() }
              fieldName={ stepItem.fieldName }
              stepItem={ stepItem }
              isDisabled={ isDisabled }
              isReadOnly={ isReadOnly }
            />
          ) }
          { stepItem.dataType === AccountDataType.Phone && stepItem.inputType === InputType.PhoneInput && (
            <PhoneInputAccountField
              fieldValue={ getDefaultValue() }
              fieldName={ stepItem.fieldName }
              stepItem={ stepItem }
              isDisabled={ isDisabled }
              isReadOnly={ isReadOnly }
            />
          ) }
          { !isReactDayPicker && stepItem.dataType === AccountDataType.Date && (
            <BirthDateField
              nameField={ stepItem.fieldName }
              labelField={ t( 'bookingFunnel.personalData.birthDate' ) }
              valueField={ getDefaultValue() }
              endDate={ endDateToBirthDate }
              isRequired={ stepItem.isRequired ? stepItem.isRequired : false }
              isDisabled={ isDisabled }
            />
          ) }
          { isReactDayPicker && stepItem.dataType === AccountDataType.Date && (
            <CoreBirthDateField
              nameField={ stepItem.fieldName }
              labelField={ t( 'bookingFunnel.personalData.birthDate' ) }
              valueField={ getDefaultValue() }
              endDate={ endDateToBirthDate }
              stepItem={ stepItem }
              isDisabled={ isDisabled }
            />
          ) }
          { stepItem.dataType === AccountDataType.Address && (
            <CoreAddressSingleLine
              fieldValue={ getDefaultValue() }
              fieldName={ stepItem.fieldName }
              personalDataFields={ formData }
              stepItem={ stepItem }
              isDisabled={ isDisabled }
            />
          ) }
          { stepItem.dataType === AccountDataType.Number && (
            <NumberField
              fieldValue={ getDefaultValue() }
              fieldName={ stepItem.fieldName }
              stepItem={ stepItem }
              isDisabled={ isDisabled }
              isReadOnly={ isReadOnly }
            />
          ) }
        </Fragment>
      ) }
    </Fragment>
  );
};
