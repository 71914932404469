import React, { Fragment } from 'react';
import { Form, FormGroup, Col } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddressSettings, ContainerFieldItem } from 'Services/widgets/interfaces';
import { AddressPersonalSelectElement } from './AddressPersonalSelect';
import { HiddenField } from '../steps/personal-sub-steps/form-fields/HiddenField';
import { AddressFields } from 'Services/widgets/enums';

export interface AddressPersonalOneLineProps {
  personalDataFields: any;
  stepItem?: ContainerFieldItem;
}

const hiddenFields: string[] = [
  AddressFields.City,
  AddressFields.Street,
  AddressFields.HouseNumber,
  AddressFields.ZipCode,
];

export const AddressPersonalSingleLine: React.FC<AddressPersonalOneLineProps> = ( {
  personalDataFields,
  stepItem,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const formContext = useFormContext();
  const { errors, control } = formContext;

  const settings = stepItem?.settings as AddressSettings;
  const restrictedCountries: string[] = settings.restrictedCountries || [];
  const defaultCountry = restrictedCountries[0];

  const isValidateAddress = ( value: string ): string | boolean => {
    const errFields = errors ? Object.keys( errors ) : null;

    if ( !errFields ) {
      return true;
    }

    const filteredErrFields = errFields.filter( ( fieldKey ) => hiddenFields.includes( fieldKey ) );
    if ( filteredErrFields.length ) {
      const errMessage = t( 'base:forms.messages.addressInvalid' );

      return errMessage;
    }

    return true;
  };

  return (
    <Fragment>
      <Col sm={ 12 } className="mb-4">
        <Form.Label className="dynamic-main-label">
          { t( 'bookingFunnel.funk.registration.adressTitleSingleLine' ) }
        </Form.Label>
        <p className="text-center"
          dangerouslySetInnerHTML={ { __html: t( 'bookingFunnel.funk.registration.adressSubtitleSingleLine' ) } }
        />
        <Controller
          name="address"
          control={ control }
          rules={ {
            required: true,
            validate: ( value ) => {
              return isValidateAddress( value );
            },
          } }
          defaultValue={ personalDataFields.address }
          render={ ( props ) => {
            return (
              <FormGroup
                className={ `address-field mb-4${errors[props.name] !== undefined ? ' dynamic-input-error' : ''}` }
                controlId={ props.name }
              >
                <Form.Label>
                  { t( 'bookingFunnel.personalDataFunk.address' ) }
                </Form.Label>
                <AddressPersonalSelectElement
                  nameGroup="personalData"
                  nameField={ props.name }
                  selectValue={ { label: props.value, countryName: defaultCountry } }
                  placeholder={ t( 'bookingFunnel.personalDataFunk.address' ) || '' }
                  stepItem={ stepItem! }
                />
                <Form.Control
                  { ...props }
                  type="hidden"
                  isInvalid={ errors[props.name] !== undefined }
                />
                <Form.Control.Feedback type="invalid">
                  { errors[props.name]?.message ? (
            errors[props.name]?.message
                  ) : (
                    <Fragment>
                      { t( 'base:forms.messages.fieldRequired' ) }
                    </Fragment>
                  ) }
                </Form.Control.Feedback>
              </FormGroup>
            );} }
        />
        { hiddenFields.map( ( field, idx ) => {
          return (
            <Fragment key={ idx }>
              <HiddenField
                isRequired={ true }
                fieldName={ field }
                fieldValue={ personalDataFields && personalDataFields[field] }
              />
            </Fragment>
          );
        } ) }
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="country"
          control={ control }
          defaultValue={ t( `bookingFunnel.countries.${defaultCountry}` ) }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalDataFunk.country' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                className="read-only"
                type="text"
                readOnly={ true }
                placeholder={ t( 'bookingFunnel.personalDataFunk.country' ) }
                isInvalid={ errors[props.name] !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalDataFunk.country' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
    </Fragment>
  );
};
