import React from 'react';
import { ICFFactorTypeOption } from '../../../factor-service';

export interface SelectValuesOptionProps {
  values: ICFFactorTypeOption;
}

export const SelectValuesOptionComponent: React.FC<SelectValuesOptionProps> = ( { values } ) => {
  return (
    <span className="d-inline-flex align-items-center">
      { values.name }
    </span>
  );
};
