import React, { Fragment } from 'react';
import downloadGreenIconUrl from 'assets/bf-images/funk/download_green.svg';
import { Col } from 'react-bootstrap';
import { DownloadLinkItem } from 'Services/widgets/interfaces';

export interface DownloadLinkProps {
  downloadLinks?: DownloadLinkItem[];
}

export const DownloadLink: React.FC<DownloadLinkProps> = ( {
  downloadLinks,
} ) => {

  return (
    <Fragment>
      { downloadLinks && downloadLinks.map( ( linkItem, idx ) => (
        <Col key={ idx } sm={ 12 } className="mb-2 px-0 max-w-645">
          <p className="mb-0">{ linkItem.textInfo }
            <a
              href={ linkItem.link }
              target="_blank"
              rel="noopener noreferrer"
              className="underline ml-2"
            >
              <span className="mr-3 btn-green-color">
                { linkItem.name }
              </span>
              <img
                src={ downloadGreenIconUrl }
                alt="download"
              />
            </a>
          </p>
        </Col>
      ) ) }
    </Fragment>
  );
};
