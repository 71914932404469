import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface LoadPageProps {
  addedClassName: () => string;
}

export const LoadPage: React.FC<LoadPageProps> = ( props ) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const { addedClassName } = props;

  return (
    <Fragment>
      <div className={ `mt-5 success-page custom-layout-4 ${ addedClassName() }` }>
        <div className="dynamic-success-page max-w-936">
          <Row className='"mx-0 bf-custom-padding'>
            <Col md={ 12 } className="mt-4 text-center">
              <h1 className="f-26 mb-4">{ t( 'bookingFunnel.funkReanovo.loadPageTitle' ) }</h1>
            </Col>
            <Col md={ 12 }>
              <div className="snippet" data-title="dot-flashing">
                <div className="stage">
                  <div className="dot-flashing"></div>
                </div>
              </div>
            </Col>
            <Col md={ 12 } className="mt-4 text-center ws-contact-box max-w-700">
              <p className="f-18 text-center m-0 p-0"><b>{ t( 'bookingFunnel.funkReanovo.loadPageInfo' ) }</b></p>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};
