import React, { Fragment } from 'react';
import successIconUrlDoc from 'assets/bf-images/success-doc.svg';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { COREBFStep } from '../../enums';
import { SuccessPageContent } from 'Services/widgets/interfaces';

export interface CoreSuccessPageProps {
  bfLogo: string;
  homePageHandler: ( isGoToPortal: boolean, siteUrl?: string ) => void;
  pageContent?: SuccessPageContent;
  leadNumber: string;
}

export const CoreSuccessPage: React.FC<CoreSuccessPageProps> = ( props ) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const { bfLogo, homePageHandler, pageContent, leadNumber } = props;
  const { showApplicationNumber, successMessage, toHomePageBtnText, toHomePageUrl } = pageContent || {};

  return (
    <Fragment>
      <div className="success-page custom-layout-1">
        <Row className="max-w-450 m-box-mob">
          { bfLogo && (
            <Col sm={ 12 } className="bf-page-header">
              <div className="logo d-flex">
                <img src={ bfLogo } alt="Logo" />
              </div>
            </Col>
          ) }
          { showApplicationNumber && leadNumber && (
            <Col sm={ 12 } className="bf-header-app-number">
              <span className="f-26">{ t( 'bookingFunnel.applicationNumber', {
                'appNumber': leadNumber,
              } ) }
              </span>
            </Col>
          ) }
          <Col md={ 12 } className="core-success-img text-center px-0">
            <img src={ successIconUrlDoc } alt="success" />
          </Col>
          <Col md={ 12 } className="mt-4 px-0 text-center">
            { successMessage ? (
              <div dangerouslySetInnerHTML={ { __html: `${successMessage}` } }></div>
            ) : <h1 className="f-26">{ t( 'bookingFunnel.successMessage' ) }</h1>
            }
          </Col>
          <Col md={ 12 } className="mt-5 px-0 text-center">
            <Button
              id={ `${COREBFStep.PaymentApprovementStep}_to_home_page` }
              variant="primary"
              className="col-sm-12 mr-0"
              onClick={ ( e ) => homePageHandler( false, toHomePageUrl ) }
            >
              { toHomePageBtnText ? toHomePageBtnText : t( 'bookingFunnel.toHomeBtn' ) }
            </Button>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
