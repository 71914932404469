import React from 'react';
import { FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import AsyncPaginate from 'react-select-async-paginate';
import { ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { AccountsApi, AccountsApiListAccountsRequest, AccountClass } from '@emilgroup/account-sdk';

import { DropdownIndicatorTagForBg, NullIndicator } from 'App/components/utils/react-select/indicators';
import { useAppDefaultStyles } from 'Services/stylings';
import { useAppLogger } from 'Services/logger';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { publicApiUrl } from 'App/utils';
import { HiddenField } from '../../steps/personal-sub-steps/form-fields/HiddenField';

import {
  ContainerFieldItem,
  ITariffDataStep,
} from 'Services/widgets/interfaces';


export interface CoreAccountSelectProps {
  stepItem: ContainerFieldItem;
  formData: ITariffDataStep;
  isDisabled?: boolean;
  optionLabel?: string;
  mapping?: string;
}

type AccountOptionClass = AccountClass & {
  label: string;
  labelExt: JSX.Element | string | null;
};

interface ResultMapping {
  source: string;
  target: string;
}

interface HiddenField {
  insuredObjectName: string;
  fieldName: string;
}

const parseMappings = ( mapping: string ): {
  mappings: ResultMapping[];
  hiddenFields: HiddenField[];
} => {
  if ( !( mapping ?? '' ).trim() ) {
    return {
      mappings: [],
      hiddenFields: [],
    };
  }

  const arr = JSON.parse( mapping ) as string[][];
  const mappings = arr.map( ( i ) => ( {
    source: i[0],
    target: i[1],
  } ) );

  const hiddenFields = mappings.map( ( { target } ) => {
    const [ insuredObjectName, fieldName ] = target.split( '.' );

    return { insuredObjectName, fieldName };
  } );

  return { mappings, hiddenFields };
};

const bgColorSelected = '#ECF5FA';
const pageSize = 10;

const evalInScope = ( code: string, contextAsScope: object ): any => {
  // eslint-disable-next-line no-new-func
  return new Function( `with (this) { return (${code}); }` ).call( contextAsScope );
};

const createAccountsApi = () => new AccountsApi( undefined, publicApiUrl );

export const CoreAccountSelect: React.FC<CoreAccountSelectProps> = (
  {
    stepItem, formData, isDisabled, optionLabel, mapping,
  },
) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const { setValue, control } = useFormContext();
  const defaulStyles = useAppDefaultStyles();
  const logger = useAppLogger();
  const { showAlert, hideAlert } = useAppAlertService();

  const controlName = `${stepItem.fieldName}_${stepItem.insuredObjectName}`;
  const { mappings, hiddenFields } = parseMappings( mapping ?? '' );

  const evalExpr = React.useCallback( ( template: string, ctx: object ): string => {
    const code = template;
    try {
      const s = evalInScope( code, ctx );

      return s;
    } catch ( e ) {
      logger.error( `Error evaluating [${code}] with context: ${e}` );

      return ( e as Error ).message;
    }

  }, [ logger ] );

  const getAccountValue = React.useCallback(
    ( account?: AccountOptionClass ): any => account,
    [] );

  const getAccountLabel = React.useCallback(
    ( account: AccountClass | undefined ) => {
      if ( !account ) {
        return '';
      }

      // eslint-disable-next-line no-template-curly-in-string
      const expr = optionLabel ?? '`${ account.firstName } ${ account.lastName }`';

      const label = evalExpr( expr, { account } );

      return label;
    }, [ evalExpr, optionLabel ] );

  const formatAccountLabel = React.useCallback(
    ( account: AccountClass | undefined, searchQuery: string ) => {
      const label = getAccountLabel( account );

      if ( !searchQuery || !label ) {
        return label;
      }

      const parts: JSX.Element[] = [];
      let pos = 0;
      const searchLen = searchQuery.length;
      do {
        const index = label.toUpperCase().indexOf( searchQuery.toUpperCase(), pos );
        if ( index >= 0 ) {
          if ( index > 0 ) {
            parts.push( <span key={ `span-${pos}` }>{ label.substring( pos, index ) }</span> );
          }
          parts.push( <b key={ `b-${pos}` }>{ label.substring( index, index + searchLen ) }</b> );

          pos = index + searchLen;
        } else {
          parts.push( <span key={ `span-${pos}` }>{ label.substring( pos ) }</span> );
          break;
        }
      } while ( true );

      return (
        <React.Fragment>
          { parts }
        </React.Fragment>
      );
    }, [ getAccountLabel ] );

  const convertAccountToOption = React.useCallback(
    ( account: AccountClass, seachQuery: string ): AccountOptionClass => ( {
      ...account,
      label: getAccountLabel( account ),
      labelExt: formatAccountLabel( account, seachQuery ),
    } ), [ formatAccountLabel, getAccountLabel ] );

  const initialValue = ( formData ? formData[controlName] : undefined );
  const defaultValue = ( initialValue ? convertAccountToOption( initialValue, '' ) : undefined );

  const [ selectedAccount, setSelectedAccount ] = React.useState<AccountOptionClass | undefined>( defaultValue );

  const loadOptions = async ( searchQuery, loadedOptions, { page } ) => {
    const emptyOptions = {
      options: [],
      hasMore: false,
      additional: {
        page,
      },
    };

    try {
      const query = ( searchQuery ?? '' ).trim();
      if ( !query ) {
        return emptyOptions;
      }

      hideAlert();

      const request: AccountsApiListAccountsRequest = {
        filter: `name=${query}`,
        pageToken: `${page}`,
        pageSize,
      } as unknown as AccountsApiListAccountsRequest;

      const accountsApi = createAccountsApi();

      const response = await accountsApi.listAccounts( request );

      return {
        options: response.data.items.map( ( i ) => convertAccountToOption( i, searchQuery ) ),
        hasMore: response.data.items.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } catch ( e ) {
      logger.error( e );
      showAlert( {
        message: t( 'base:forms.messages.error' ),
        type: 'danger',
      } );

      return emptyOptions;
    }

  };

  const setMappedFields = React.useCallback( ( account: AccountOptionClass ): void => {
    if ( isDisabled ) {
      return;
    }

    for ( const { source, target } of mappings ) {
      const value = account ? evalExpr( source, { account } ) : undefined;
      const [ part1, part2 ] = target.split( '.' );
      const fieldName = ( part2 ? `${part2}_${part1}` : part1 );

      setValue( fieldName, value ? value : '', { shouldValidate: true } );
    }
  }, [ evalExpr, isDisabled, mappings, setValue ] );

  const handleSelect = React.useCallback( ( item: ValueType<AccountOptionClass> ) => {
    const account = item as AccountOptionClass;
    setSelectedAccount( account );
    setValue( controlName, account, { shouldValidate: true } );
    setMappedFields( account );

  }, [ controlName, setMappedFields, setValue ] );

  const customStyles = React.useMemo( () => {
    return {
      option: ( styles, { isFocused, isSelected } ) => {
        return {
          ...styles,
          backgroundColor: isFocused | isSelected ? bgColorSelected : null,
          color: defaulStyles.textColor,
        };
      },
    };
  }, [ defaulStyles.textColor ] );

  return (
    <Controller
      id={ controlName }
      name={ controlName }
      control={ control }
      defaultValue={ defaultValue }
      render={ ( { onChange, name } ) => (
        <FormGroup className="mb-0" controlId={ name }>
          <AsyncPaginate
            name={ name }
            id={ name }
            className="select-values-list"
            classNamePrefix="select-item"
            placeholder={ t( 'accountSelect.placeholder' ) }
            value={ selectedAccount }
            components={ {
              DropdownIndicator: DropdownIndicatorTagForBg,
              IndicatorSeparator: NullIndicator,
            } }
            cacheOptions
            loadOptions={ loadOptions }
            getOptionValue={ ( option ) => getAccountValue( option ) }
            getOptionLabel={ ( option ) => option.label }
            formatOptionLabel={ ( option ) => option.labelExt }
            blurInputOnSelect={ true }
            isMulti={ false }
            isClearable={ true }
            isSearchable={ true }
            escapeClearsValue
            menuPlacement="bottom"
            onChange={ handleSelect }
            styles={ customStyles }
            maxMenuHeight={ 200 }
            isDisabled={ isDisabled }
            additional={ {
              page: 1,
            } }
            noOptionsMessage={ () => t( 'accountSelect.noOptions' ) }
            debounceTimeout={ 300 }
          />

          { hiddenFields.map( ( field, idx ) => {
            const hiddenName = `${field.fieldName}_${field.insuredObjectName}`;

            return (
              <React.Fragment key={ idx }>
                <HiddenField
                  isRequired={ false }
                  fieldName={ hiddenName }
                  fieldValue={ formData && formData[hiddenName] }
                />
              </React.Fragment>
            );
          } ) }
        </FormGroup>
      ) }
    />
  );
};
