import React from 'react';
import dayjs from 'dayjs';
import LocaleDe from 'date-fns/locale/de';
import DatePicker, { registerLocale } from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { useTranslation } from 'react-i18next';
import { Separators } from 'Services/widgets/enums';


export interface DatePickerProps {
  currentValue: string;
  fromDate?: string;
  toDate?: string;
  separator: Separators;
  dateFormat?: string;
  placeholderText?: string;
  isReadOnly?: boolean;
  showMonthYearPicker: boolean;
  showFullMonthYearPicker: boolean;
  onChangeDate: ( dateValue ) => void;
  onBlurDate?: ( dateValue ) => void;
  isDisabled?: boolean;
}

export const DatePickerComponent: React.FC<DatePickerProps> = ( {
  currentValue,
  fromDate,
  toDate,
  separator,
  dateFormat = 'dd/MM/yyyy',
  isReadOnly,
  placeholderText = 'TT/MM/JJJJ',
  showMonthYearPicker,
  showFullMonthYearPicker,
  onChangeDate,
  onBlurDate,
  isDisabled,
} ) => {
  const { i18n } = useTranslation( [ 'widgets', 'base' ] );
  const lng = i18n.language;
  const maskDate = [ /\d/, /\d/, `${separator}`, /\d/, /\d/, `${separator}`, /\d/, /\d/, /\d/, /\d/ ];
  const inputValue = currentValue ? new Date( currentValue ) : '';
  const minYear = fromDate ? dayjs( fromDate ).format( 'YYYY' ) : '';
  const maxYear = toDate ? dayjs( toDate ).format( 'YYYY' ) : '';
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe( 'dd/mm/yyyy', {
    minYear,
    maxYear,
  } );

  if ( lng === 'de' ) {
    registerLocale( lng, LocaleDe );
  }

  const formatDateValue = React.useCallback( ( inputDate: string ): string => {
    if ( inputDate ) {
      const resDate = inputDate.replace( Separators.Underscore, '' );

      if ( resDate.length === 10 ) {
        return resDate.split( separator ).reverse().join( Separators.Dash );
      }
    }

    return '';
  }, [ separator ] );

  const handleChangeDate = React.useCallback( ( selectedDate: string ): void => {
    onChangeDate( selectedDate );
  }, [ onChangeDate ] );

  const handleChangeInput = React.useCallback( ( inputEvent ): void => {
    const dateVal = inputEvent.target.value;
    if ( !dateVal ) {
      onChangeDate( dateVal );

      return;
    }

    const formatDate = formatDateValue( dateVal );
    if ( formatDate ) {
      onChangeDate( formatDate );
    }

  }, [ formatDateValue, onChangeDate ] );

  const handleBlur = React.useCallback( ( inputEvent ): void => {
    if ( onBlurDate ) {
      const dateVal = inputEvent.target.value;
      onBlurDate( dateVal );
    }
  }, [ onBlurDate ] );

  // eslint-disable-next-line react/display-name
  const ReadOnlyInput = React.forwardRef( ( inputProps: Record<string, any>, ref: React.Ref<HTMLInputElement> ) => (
    <div className="input-group">
      <input
        ref={ ref }
        className="form-control date-custom-input"
        onClick={ inputProps.onClick }
        defaultValue={ inputProps.value }
        type="text"
        readOnly={ isReadOnly }
        placeholder={ placeholderText }
        disabled={ isDisabled }
      />
      <div onClick={ inputProps.onClick } className="ws-icon-select">
        <span className="input-group-text">
          <i className="material-icons material-icons-outlined calendar-icon">calendar_today</i>
        </span>
      </div>
    </div>
  ) );

  // eslint-disable-next-line react/display-name
  const CustomInputMask = React.forwardRef( ( inputProps: Record<string, any>, ref: React.Ref<HTMLInputElement> ) => (
    <div className="input-group">
      <MaskedInput
        ref={ ref }
        className="form-control date-custom-input"
        type="text"
        value={ inputProps.value }
        pipe={ autoCorrectedDatePipe }
        mask={ maskDate }
        keepCharPositions= { true }
        guide = { true }
        placeholder={ placeholderText }
        onChange={ handleChangeInput }
        onBlur={ handleBlur }
        disabled={ isDisabled }
      />
      <div onClick={ inputProps.onClick } className="ws-icon-select">
        <span className="input-group-text">
          <i className="material-icons material-icons-outlined calendar-icon">calendar_today</i>
        </span>
      </div>
    </div>
  ) );

  return (
    <DatePicker
      customInput={ isReadOnly || isDisabled ? <ReadOnlyInput /> : <CustomInputMask /> }
      selected={ inputValue }
      onChange={ handleChangeDate }
      minDate={ fromDate ? new Date( fromDate ) : '' }
      maxDate={ toDate ? new Date( toDate ) : '' }
      dateFormat={ dateFormat }
      showMonthYearPicker={ showMonthYearPicker }
      showFullMonthYearPicker={ showFullMonthYearPicker }
      showYearDropdown={ true }
      placeholderText={ placeholderText }
      locale={ lng }
      disabled={ isDisabled }
      yearDropdownItemNumber={ 200 }
      scrollableYearDropdown={ true }
    />
  );
};
