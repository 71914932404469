import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { blankLink } from 'config';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { DatePickerComponent } from '../../booking-funnel/ui/DatePickerComponent';
import { Separators } from 'Services/widgets/enums';
import { isObject } from '../../bf-hooks';
import { JsonObject } from '@cover42/protobuf-util';
import { formatDefaultDate, formatInputDate, isRequiredField } from '../../booking-funnel/booking-funnel-hooks';

export interface DatePickedProps {
  valueField: string;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string ) => void;
  isShortName?: Boolean;
  fromDate?: string;
  toDate?: string;
  stepItem?: ContainerFieldItem;
  nameFieldToCheck?: string;
}

export const DatePickedField: React.FC<DatePickedProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  fromDate,
  toDate,
  stepItem,
  nameFieldToCheck,
} ) => {
  const { t, i18n } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control, setValue, setError, getValues } = useFormContext();

  const separatorDot = Separators.Dot;
  const lng = i18n.language;
  const formatDateText = lng === 'de' ? 'TT.MM.JJJJ' : 'DD.MM.YYYY';
  const dateField = valueField ? valueField : '';
  const oneYear = dayjs( dateField ).add( 1, 'years' );

  const getFromDate = React.useCallback( ( ): string => {
    const defaultDate = dayjs( new Date() ).format();

    if ( isObject( variable.minValue ) ) {
      const objectDate = variable.minValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    if ( fromDate ) {
      return fromDate;
    }

    if ( isObject( variable.defaultValue ) ) {
      const objectDate = variable.defaultValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    return defaultDate;
  }, [ fromDate, variable.defaultValue, variable.minValue ] );
  const getToDate = React.useCallback( ( ): string => {
    const defaultDate = oneYear.format();

    if ( isObject( variable.maxValue ) ) {
      const objectDate = variable.maxValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    if ( toDate ) {
      return toDate;
    }

    return defaultDate;
  }, [ oneYear, toDate, variable.maxValue ] );

  const getMinDate = React.useCallback( ( ): string => {

    if ( nameFieldToCheck ) {
      const minDate: string = getValues( `${nameFieldToCheck}_${variable.groupName}` );

      if ( minDate ) {
        const addOneDay = dayjs( minDate ).add( 1, 'day' ).format();
        return addOneDay;
      }
    }

    return getFromDate();
  }, [ getFromDate, getValues, nameFieldToCheck, variable.groupName ] );

  const isValidDateField = React.useCallback( ( dateVal: string ): boolean => {
    const currentVal = dayjs( dateVal, formatDefaultDate, false );
    if ( nameFieldToCheck ) {
      const dateToCheck: string = getValues( `${nameFieldToCheck}_${variable.groupName}` );

      if ( !dateToCheck ) {
        return true;
      }

      const currentDate = dayjs( dateToCheck, formatDefaultDate, false );

      if ( currentVal.isBefore( currentDate ) ) {
        return false;
      }

      if ( currentVal.isSame( currentDate ) ) {
        return true;
      }
    }

    const fromDateVal = dayjs( getFromDate(), formatDefaultDate, false );
    const toDateVal = dayjs( getToDate(), formatDefaultDate, false );

    if ( fromDateVal.isAfter( currentVal, 'date' ) ) {
      return false;
    }

    if ( toDateVal.isBefore( currentVal, 'date' ) ) {
      return false;
    }

    return true;
  }, [ getFromDate, getToDate, getValues, nameFieldToCheck, variable.groupName ] );

  const datePickerHandler = React.useCallback( ( dateVal: string, nameField: string ) => {
    if ( !isValidDateField( dateVal ) ) {
      const errMessage = t( 'base:forms.messages.dateError', {
        minDate: dayjs( getMinDate() ).format( formatInputDate ),
        maxDate: dayjs( getToDate() ).format( formatInputDate ),
      } );

      setError( nameField, { shouldFocus: true, message: errMessage } );
    }

    if ( !dateVal && isValidDateField( dateVal ) ) {
      setValue( nameField, dateVal, { shouldValidate: true } );
      return;
    }

    const selectedDate = dayjs( dateVal );

    if ( selectedDate.isValid() ) {
      setValue( nameField, selectedDate.format(), { shouldValidate: true } );
    }
  }, [ getMinDate, getToDate, isValidDateField, setError, setValue, t ] );

  const isValidFormatDate = React.useCallback( ( inputDate: string ): boolean => {
    if ( inputDate ) {
      const resDate = inputDate.replace( Separators.Underscore, '' );

      if ( resDate.length < 10 ) {
        return false;
      }

      const formatDate = resDate.split( separatorDot ).reverse().join( Separators.Dash );

      return dayjs( formatDate ).isValid();
    }

    return false;
  }, [ separatorDot ] );
  const datePickerBlurHandler = React.useCallback( ( dateVal: string, nameField: string ): void => {
    if ( !isValidFormatDate( dateVal ) ) {
      const errMessage = t( 'base:forms.messages.dateInvalid',
        { fieldLabel: variable.label, fieldFormat: formatDateText },
      );

      setError( nameField, { shouldFocus: true, message: errMessage } );
    }
  }, [ formatDateText, isValidFormatDate, setError, t, variable.label ] );

  return (
    <Fragment>
      <Controller
        name={ isShortName ? variable.name :
          `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
        control={ control }
        rules={ {
          required: isRequiredField( variable, stepItem ),
          validate: ( value ) => {
            if( !isValidDateField( value ) ) {
              const errMessage = t( 'base:forms.messages.dateError', {
                minDate: dayjs( getMinDate() ).format( formatInputDate ),
                maxDate: dayjs( getToDate() ).format( formatInputDate ),
              } );
              return errMessage;
            }
          },
        } }
        defaultValue={ dateField }
        render={ ( props ) => (
          <FormGroup
            className={ `core-date-box date-picker-field${errors[props.name] ? ' core-date-error' : ''}` }
            controlId={ props.name }
          >
            <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
              { variable.bfLabel ?
                <div className="d-inline-block" dangerouslySetInnerHTML={ { __html: `${variable.bfLabel}` } }></div>
                : variable.label }
              { variable.bfTooltip && (
                <a
                  id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                  href={ blankLink }
                  onClick={ ( e ) => {
                    e.preventDefault();
                    showTooltip( true, variable.bfTooltip! );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            { variable.bfDescription && (
              <span className="core-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            <DatePickerComponent
              currentValue={ props.value }
              onChangeDate={ ( date: string ) => datePickerHandler( date, props.name ) }
              onBlurDate={ ( date: string ) => datePickerBlurHandler( date, props.name ) }
              fromDate={ getFromDate() }
              toDate={ getToDate() }
              separator={ separatorDot }
              placeholderText={ formatDateText }
              isReadOnly={ false }
              showMonthYearPicker={ false }
              showFullMonthYearPicker={ true }
            />
            <span className="float-right f-12 mt-1">{
              t( 'base:forms.dateFormatInfo',
                { fieldFormat: formatDateText },
              )
            }
            </span>
            <Form.Control
              { ...props }
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired',
                    { fieldLabel: variable.label } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
