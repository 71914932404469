import React from 'react';
import jsonpath from 'jsonpath';
import { ContainerFieldItem, ILeadData } from 'Services/widgets/interfaces';
import { defaultCurrency, currencyFormatter, invoiceFields } from '../../core-hooks';
import { useLocale } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { isEmpty } from 'lodash';

export interface CoreDynamicFieldProps {
  lead: ILeadData;
  stepItem: ContainerFieldItem;
}

export const CoreDynamicField: React.FC<CoreDynamicFieldProps> = ( { stepItem, lead } ) => {
  const locale = useLocale();
  const lastWord = stepItem.fieldValue ? ( stepItem.fieldValue as string ).split( '.' ).pop() : '';

  const getFieldValue = ( ): string => {
    let fieldValue = jsonpath.query( lead || {}, `$.${stepItem.fieldValue}` )[0];

    if ( typeof fieldValue === 'number' && lastWord && invoiceFields.includes( lastWord ) ) {
      fieldValue = fieldValue / 100;
    }

    if ( isEmpty( fieldValue ) && typeof fieldValue !== 'number' ) {
      fieldValue = stepItem.defaultValue;
    }

    if ( typeof fieldValue === 'number' ) {
      const currency = lead && lead.quote ? lead.quote.currency : defaultCurrency;

      if ( stepItem && stepItem.suffix ) {
        const res = currencyFormatter( fieldValue, currency, locale, false );

        return `${res} ${stepItem.suffix}`;
      }

      return currencyFormatter( fieldValue, currency, locale, true );
    }

    if ( stepItem && stepItem.suffix ) {
      fieldValue = `${fieldValue} ${stepItem.suffix}`;
    }

    return fieldValue;
  };

  const renderClassName = (): string => {
    let classNameField = 'form-control';

    if ( stepItem?.className ) {
      classNameField = stepItem?.className;
    }

    return `${classNameField}`;
  };

  return (
    <div className={ stepItem.label ? 'dynamic-field input-group' : '' }>
      { stepItem.label && (
        <div className="input-group-prepend">
          <span
            className="input-group-text"
            dangerouslySetInnerHTML={ { __html: `${stepItem.label}` } }
          />
        </div>
      ) }
      <input
        type="text"
        readOnly={ true }
        className={ renderClassName() }
        value={ getFieldValue( ) }
      />
    </div>
  );
};
