import React, { Fragment } from 'react';
import { JsonValue } from '@cover42/protobuf-util';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PremiumFormulaUnits } from 'Services/widgets/enums';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { isRequiredField } from '../../booking-funnel/booking-funnel-hooks';

export interface DateUnitFieldProps {
  valueField: string;
  variable: IBFProductVariable;
  isShortName?: boolean;
  stepItem?: ContainerFieldItem;
}

export interface OptionTimeItem {
  value: PremiumFormulaUnits;
  label: string;
}

export const DateUnitField: React.FC<DateUnitFieldProps> = ( { valueField, variable, isShortName, stepItem } ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control } = useFormContext();

  const timeOptions = React.useMemo<OptionTimeItem[]>( () => {
    const options = [
      {
        value: PremiumFormulaUnits.Month,
        label: t( `bookingFunnel.gmTimeOptions.${PremiumFormulaUnits.Month}` ),
      },
      {
        value: PremiumFormulaUnits.Year,
        label: t( `bookingFunnel.gmTimeOptions.${PremiumFormulaUnits.Year}` ),
      },
    ];

    return options;
  }, [ t ] );

  const getDefaultValue = ( stringValue: JsonValue ): string => {
    return stringValue !== null ? stringValue as string : '';
  };

  return (
    <Fragment>
      <Controller
        name={ isShortName ? variable.name :
          `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
        control={ control }
        rules={ { required: isRequiredField( variable, stepItem ) } }
        defaultValue={ valueField ? valueField : getDefaultValue( variable.defaultValue ) }
        render={ ( props ) => (
          <FormGroup controlId={ props.name } className="gm-select-box">
            <Form.Label id={ `${props.name}-label` }>
              { variable.bfLabel ?
                <div dangerouslySetInnerHTML={ { __html: `${variable.bfLabel}` } }></div>
                : variable.label }
            </Form.Label>
            { variable.bfDescription && (
              <span className="gm-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            <Form.Control
              { ...props }
              as="select"
              className="form-select"
              placeholder={ variable.label }
              isInvalid={ errors[props.name] !== undefined }
            >
              { timeOptions.map( ( el ) =>
                <option
                  key={ el.value }
                  value={ el.value }
                >
                  { el.label }
                </option> ) }
            </Form.Control>
            <FormControl.Feedback type="invalid">
              { t( 'base:forms.messages.fieldRequired', { fieldLabel: variable.label } ) }
            </FormControl.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
