import React, { Fragment } from 'react';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { ICFFactorType, ICFFactorTypeOption } from '../factor-service';
import { CFDefaultValueType } from '../bf-hooks';
import { useTranslation } from 'react-i18next';
import { SelectElement } from './SelectElement';
import { IBFProductVariable } from 'Services/widgets/interfaces';
import { JsonValue } from '@cover42/protobuf-util';
import { blankLink } from 'config';
import { ContainerFieldItem } from 'Services/widgets/interfaces';
import { JSONItemUIType } from '../booking-funnel/enum';
import { isRequiredField } from '../booking-funnel/booking-funnel-hooks';
import { FieldDefaultValue } from 'App/widget/types';

export type FieldType = 'radio' | 'select';

export interface FactorElementProps {
  valField: CFDefaultValueType;
  variable: IBFProductVariable;
  factor: ICFFactorType;
  showTooltip: ( isShow: boolean, tooltip: string ) => void;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
}

export const FactorElement: React.FC<FactorElementProps> = ( {
  valField,
  variable,
  factor,
  showTooltip,
  stepItem,
  fieldDefaultValue,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control } = useFormContext();
  const { productFieldValue } = fieldDefaultValue || {};

  const factorValues = React.useMemo<ICFFactorTypeOption[]>( () => {
    if ( factor && factor.values ) {
      return [ ...factor.values ].sort( ( a, b ) => {
        const aKey = parseInt( a.key as string );
        const bKey = parseInt( b.key as string );
        return aKey - bKey;
      } );
    }
    return [];

  }, [ factor ] );

  const getDefaultValue = React.useCallback(
    ( items: ICFFactorTypeOption[], typeField: FieldType, keyItem?: JsonValue ): ICFFactorTypeOption | string => {
      if ( valField ) {
        return valField;
      }

      if ( items.length && productFieldValue ) {
        return productFieldValue as string;
      }

      if ( items.length && keyItem ) {
        const findValue = items.find( ( i ) => i.key === keyItem )!;
        if ( typeField === 'radio' ) {
          return findValue ? String( findValue.key ) : '';
        }
        return findValue ? findValue : '';
      }

      return '';

    }, [ productFieldValue, valField ] );

  const isUITypeRadio = stepItem && stepItem.uiType && stepItem.uiType === JSONItemUIType.Radio;
  const uiTypeNoExists = stepItem && !stepItem.uiType;
  const uiTypeNoExistsOrIsRadio = !stepItem || uiTypeNoExists || isUITypeRadio;

  return (
    <Fragment>
      { factor && (
        <Fragment>
          { uiTypeNoExistsOrIsRadio && factorValues.length > 0 && factorValues.length <= 6 ?
            <Fragment>
              <Form.Label id={ `${variable.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
                { variable.bfLabel ?
                  <div dangerouslySetInnerHTML={ { __html: `${variable.bfLabel}` } }></div>
                  : variable.label }
                { variable.bfTooltip && (
                  <a
                    id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                    href={ blankLink }
                    onClick={ ( e ) => {
                      e.preventDefault();
                      showTooltip( true, variable.bfTooltip! );
                    } }
                  >
                    { t( 'bookingFunnel.tooltipHelp' ) }
                  </a>
                ) }
              </Form.Label>
              { variable.bfDescription && (
                <span className="gm-info f-12 mt-1">
                  { variable.bfDescription }
                </span>
              ) }
              <Controller
                name={ `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
                control={ control }
                rules={ { required: isRequiredField( variable, stepItem ) } }
                defaultValue={ getDefaultValue( factorValues, 'radio', variable.defaultValue ) }
                render={ ( props ) => (
                  <FormGroup className={ `custom-btn-radio mb-2 rdb-${props.name}` +
                    `${factorValues.length === 3 ? ' btn-col-3' : ' btn-col-2'}`
                  }
                  >
                    { factorValues.map( ( f, idx ) => {
                      return (
                        <Fragment key={ idx }>
                          <div className="box-radio">
                            <FormControl
                              { ...props }
                              className="form-check-input"
                              type="radio"
                              id={ `option_${ props.name + '_' + f.key}` }
                              value={ String( f.key ) }
                              checked={ props.value === String( f.key ) }
                              onBlur={ props.onBlur }
                            />
                            <Form.Label
                              className="btn btn-border-radio"
                              htmlFor={ `option_${ props.name + '_' + f.key}` }
                            >
                              { f.name }
                            </Form.Label>
                          </div>
                        </Fragment>
                      );
                    } ) }
                    <Form.Control
                      id={ `error-messages-${props.name}` }
                      type="hidden"
                      isInvalid={ errors[props.name] !== undefined }
                    />
                    <FormControl.Feedback type="invalid">
                      { t( 'base:forms.messages.fieldRequired', {
                        fieldLabel: variable.label,
                      } ) }
                    </FormControl.Feedback>
                  </FormGroup>
                ) }
              />
            </Fragment> : (
              <Controller
                name={ `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
                control={ control }
                rules={ { required: isRequiredField( variable, stepItem ) } }
                defaultValue={ getDefaultValue( factorValues, 'select', variable.defaultValue ) }
                render={ ( props ) => (
                  <FormGroup controlId={ props.name }>
                    <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
                      { variable.bfLabel ?
                        <div dangerouslySetInnerHTML={ { __html: `${variable.bfLabel}` } }></div>
                        : variable.label }
                      { variable.bfTooltip && (
                        <a
                          id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                          href={ blankLink }
                          onClick={ ( e ) => {
                            e.preventDefault();
                            showTooltip( true, variable.bfTooltip! );
                          } }
                        >
                          { t( 'bookingFunnel.tooltipHelp' ) }
                        </a>
                      ) }
                    </Form.Label>
                    { variable.bfDescription && (
                      <span className="gm-info f-12 mt-1">
                        { variable.bfDescription }
                      </span>
                    ) }
                    <SelectElement
                      labelField={ variable.label }
                      nameField={ props.name }
                      selectValue={ props.value }
                      listValues={ factorValues }
                      isShowAllItems={ true }
                    />
                    <Form.Control
                      type="hidden"
                      isInvalid={ errors[props.name] !== undefined }
                    />
                    <FormControl.Feedback type="invalid">
                      { t( 'base:forms.messages.fieldRequired', {
                        fieldLabel: variable.label,
                      } ) }
                    </FormControl.Feedback>
                  </FormGroup>
                ) }
              />
            )
          }
        </Fragment>
      ) }
    </Fragment>
  );
};
