import React from 'react';
import jsonpath from 'jsonpath';
import { ContainerFieldItem, ILeadData } from 'Services/widgets/interfaces';
import { defaultCurrency, currencyFormatter, invoiceFields } from '../../../core-hooks';
import { useLocale } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { isEmpty } from 'lodash';

export interface CoreViewDynamicFieldProps {
  lead: ILeadData;
  stepItem: ContainerFieldItem;
}

export const CoreViewDynamicField: React.FC<CoreViewDynamicFieldProps> = ( { stepItem, lead } ) => {
  const locale = useLocale();
  const lastWord = stepItem.fieldValue ? ( stepItem.fieldValue as string ).split( '.' ).pop() : '';

  const getFieldValue = ( ): string => {
    let fieldValue = jsonpath.query( lead || {}, `$.${stepItem.fieldValue}` )[0];

    if ( typeof fieldValue === 'number' && lastWord && invoiceFields.includes( lastWord ) ) {
      fieldValue = fieldValue / 100;
    }

    if ( isEmpty( fieldValue ) && typeof fieldValue !== 'number' ) {
      fieldValue = stepItem.defaultValue;
    }

    if ( typeof fieldValue === 'number' ) {
      const currency = lead && lead.quote ? lead.quote.currency : defaultCurrency;

      return currencyFormatter( fieldValue, currency, locale, true );
    }

    return fieldValue || '-';
  };

  return (
    <div className="details-section mx-4">
      <p className="bf-selected-val f-14" dangerouslySetInnerHTML={ { __html: `${stepItem.label}` } }></p>
      <p>{ getFieldValue() }</p>
    </div>
  );
};
