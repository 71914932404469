import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'react-bootstrap';
import { PremiumFormulaVisibilities } from '@cover42/ts-contracts';
import { IPremiumCalculationStateGM } from 'Services/widgets/interfaces';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { renderPrice, useLocale } from '../booking-funnel-hooks';
import { ViewLodingTariffResult } from './ViewLodingTariffResult';
import { oneTimePayment } from 'App/components/widgets/bf-hooks';
import { JSONPremiumShowsType } from '../enum';
import dayjs from 'dayjs';
import { PremiumFormulaUnits } from '../../../../../Services/widgets/enums';
import { durationInHours } from '../../../../ui-utils';
export interface IViewTariffResultProps {
  tariffData: IPremiumCalculationStateGM | null;
  isCustomLayout?: boolean;
  productCode: string;
  premiumShowsType?: JSONPremiumShowsType;
}

export const ViewTariffResult: React.FC<IViewTariffResultProps> = ( {
  tariffData,
  isCustomLayout,
  productCode,
  premiumShowsType,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const locale = useLocale();
  if ( tariffData === null ) {
    return isCustomLayout ? <ViewLodingTariffResult /> : <LoadingSpinner />;
  }

  const calculateBillingInterval =
      ( billingIntervalFrom: dayjs.Dayjs, billingIntervalTo: dayjs.Dayjs ): string => {
        const units = [
          { unit: PremiumFormulaUnits.Year, duration: durationInHours[PremiumFormulaUnits.Year] },
          { unit: PremiumFormulaUnits.HalfYear, duration: durationInHours[PremiumFormulaUnits.HalfYear] },
          { unit: PremiumFormulaUnits.Quarter, duration: durationInHours[PremiumFormulaUnits.Quarter] },
          { unit: PremiumFormulaUnits.Month, duration: durationInHours[PremiumFormulaUnits.Month] },
          { unit: PremiumFormulaUnits.Week, duration: durationInHours[PremiumFormulaUnits.Week] },
          { unit: PremiumFormulaUnits.Day, duration: durationInHours[PremiumFormulaUnits.Day] },
          { unit: PremiumFormulaUnits.Hour, duration:durationInHours[PremiumFormulaUnits.Hour] },
        ];

        const difference = dayjs( billingIntervalTo ).diff( billingIntervalFrom, PremiumFormulaUnits.Hour );

        for ( const { unit, duration } of units ) {
          if ( difference >= duration ) {
            return unit;
          }
        }

        return PremiumFormulaUnits.Minute;
      };

  const { premiumData, error } = tariffData;
  if ( error !== null ) {
    return <p>{ error }</p>;
  }

  return (
    <Fragment>
      { premiumData !== null && (
        <Card id="view-tariff" className="sticky-top z-idx-auto">
          { isCustomLayout ? (
            <Card.Body>
              { premiumShowsType === undefined ? (
                <Fragment>
                  { premiumData.invoiceItems.length > 1 && (
                    <Row className="mb-3 bf-tarif-info total-box">
                      <Col sm={ 12 } className="text-center mb-2">
                        <h5 className="mb-0">{ t( 'base:grossAmount' ) }</h5>
                      </Col>
                      <Col sm={ 12 } className="text-center">
                        <p className="mb-0 white-space-nowrap">
                          { `${renderPrice( premiumData.grossAmount, productCode, premiumData.currency, locale )}` }
                          { premiumData.invoiceItems[0].unit !== oneTimePayment && (
                            <sup>/
                              { t( `bookingFunnel.timeOptions.${
                                calculateBillingInterval( premiumData.invoiceItems[0].billingIntervalFrom,
                                  premiumData.invoiceItems[0].billingIntervalTo )}` ) }
                            </sup>
                          ) }
                        </p>
                      </Col>
                    </Row>
                  ) }
                  { premiumData.invoiceItems
                    .sort( ( a, b ) => ( a.premiumFormulaId < b.premiumFormulaId ) ? -1 : 1 )
                    .filter( ( item ) => item.visibility === PremiumFormulaVisibilities.Public )
                    .map( ( item, idx ) => (
                      <Row key={ idx } className="mb-3 bf-tarif-info">
                        <Col sm={ 12 } className="text-center mb-2">
                          <h5 className="mb-0">{ item.name }</h5>
                        </Col>
                        <Col sm={ 12 } className="text-center">
                          <p className="mb-0 white-space-nowrap">
                            { `${renderPrice( item.grossAmount, productCode, premiumData.currency, locale )}` }
                            { item.unit !== oneTimePayment && (
                              <sup>/
                                { t( `bookingFunnel.timeOptions.${
                                  calculateBillingInterval( premiumData.invoiceItems[0].billingIntervalFrom,
                                    premiumData.invoiceItems[0].billingIntervalTo )}` ) }
                              </sup>
                            ) }
                          </p>
                        </Col>
                      </Row>
                    ) ) }
                </Fragment>
              ) : (
                <Fragment>
                  { ( premiumShowsType === JSONPremiumShowsType.ShowAllPremium ||
                  premiumShowsType === JSONPremiumShowsType.ShowOnlyTotalPremium )
                  && (
                    <Row className="mb-3 bf-tarif-info total-box">
                      <Col sm={ 12 } className="text-center mb-2">
                        <h5 className="mb-0">{ t( 'base:grossAmount' ) }</h5>
                      </Col>
                      <Col sm={ 12 } className="text-center">
                        <p className="mb-0 white-space-nowrap">
                          { `${renderPrice( premiumData.grossAmount, productCode, premiumData.currency, locale )}` }
                          { premiumData.invoiceItems[0].unit !== oneTimePayment && (
                            <sup>/
                              { t( `bookingFunnel.timeOptions.${
                                calculateBillingInterval( premiumData.invoiceItems[0].billingIntervalFrom,
                                  premiumData.invoiceItems[0].billingIntervalTo )}` ) }
                            </sup> ) }
                        </p>
                      </Col>
                    </Row>
                  ) }
                  { ( premiumShowsType === JSONPremiumShowsType.ShowAllPremium ||
                  premiumShowsType === JSONPremiumShowsType.ShowOnlyDetailsPremium ) && premiumData.invoiceItems
                    .sort( ( a, b ) => ( a.premiumFormulaId < b.premiumFormulaId ) ? -1 : 1 )
                    .filter( ( item ) => item.visibility === PremiumFormulaVisibilities.Public )
                    .map( ( item, idx ) => (
                      <Row key={ idx } className="mb-3 bf-tarif-info">
                        <Col sm={ 12 } className="text-center mb-2">
                          <h5 className="mb-0">{ item.name }</h5>
                        </Col>
                        <Col sm={ 12 } className="text-center">
                          <p className="mb-0 white-space-nowrap">
                            { `${renderPrice( item.grossAmount, productCode )}` }
                            { item.unit !== oneTimePayment && (
                              <sup>/
                                { t( `bookingFunnel.timeOptions.${
                                  calculateBillingInterval( premiumData.invoiceItems[0].billingIntervalFrom,
                                    premiumData.invoiceItems[0].billingIntervalTo )}` ) }
                              </sup> ) }
                          </p>
                        </Col>
                      </Row>
                    ) ) }
                </Fragment>
              ) }

            </Card.Body>
          ): (
            <Fragment>
              <Card.Header className='px-0 py-3'>
                <h3 className="text-center p-0 m-0">
                  { t( 'bookingFunnel.tariff.cardTariffTitle' ) }
                </h3>
              </Card.Header>
              <Card.Body>
                { premiumData.invoiceItems.sort( ( a, b ) => ( a.premiumFormulaId < b.premiumFormulaId ) ? -1 : 1 )
                  .filter( ( item ) => item.visibility === PremiumFormulaVisibilities.Public )
                  .map( ( item, idx ) => (
                    <Row key={ idx } className="mb-3">
                      <Col md={ 12 } lg={ 8 } className="lg-pr-0">
                        <p className="f-16 l-h-24 mb-0 text-c-black">{ item.name }</p>
                      </Col>
                      <Col md={ 12 } lg={ 4 } className="lg-pl-0 text-right">
                        <p className="l-h-24 mb-0 text-default f-18 white-space-nowrap">
                          { renderPrice( item.grossAmount, productCode, premiumData.currency, locale ) }
                          { item.unit !== oneTimePayment && (
                            <sup>/
                              { t( `bookingFunnel.timeOptions.${
                                calculateBillingInterval( premiumData.invoiceItems[0].billingIntervalFrom,
                                  premiumData.invoiceItems[0].billingIntervalTo )}` ) }
                            </sup> ) }
                        </p>
                      </Col>
                    </Row>
                  ) ) }
                <Row>
                  <Col sm={ 12 }>
                    <hr className="mt-0 mb-3 bf-hr"></hr>
                  </Col>
                  <Col sm={ 12 } md={ 5 } lg={ 6 } className="lg-pr-0">
                    <p className="f-16 font-weight-600 text-c-black l-h-18 mb-0">
                      { t( 'bookingFunnel.summary.total' ) }
                    </p>
                  </Col>
                  <Col sm={ 12 } md={ 7 } lg={ 6 } className="lg-pl-0 text-right">
                    <p className="l-h-18 mb-0 f-18 text-c-black font-weight-600 white-space-nowrap">
                      { renderPrice( premiumData.grossAmount, productCode, premiumData.currency, locale ) }
                      { premiumData.invoiceItems[0].unit !== oneTimePayment && (
                        <sup>/
                          { t( `bookingFunnel.timeOptions.${
                            calculateBillingInterval( premiumData.invoiceItems[0].billingIntervalFrom,
                              premiumData.invoiceItems[0].billingIntervalTo )}` ) }
                        </sup> ) }
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Fragment>
          ) }
        </Card>
      ) }
    </Fragment>
  );
};
