import React, { Fragment } from 'react';
import rightArrowIconUrl from 'assets/bf-images/funk/right_arrow.svg';
import rightArrowGreenIconUrl from 'assets/bf-images/funk/right_arrow_green.svg';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { isEmpty, isEqual } from 'lodash';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { AppAlertUI, useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { IDataFactorsAndVariables } from '../../booking-funnel/BookingFunnel';
import { useCoreActions, ActiveStepCore, RecalculationTariff } from '../DynamicCore';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SavedDataPopup } from '../ui-components/SavedDataPopup';
import { ITariffDataStep, ILeadData, IScreenType } from 'Services/widgets/interfaces';
import { useWidgetService } from 'Services/widget';
import { RegistrationStep } from './personal-sub-steps/RegistrationStep';
import { LoginStep } from './personal-sub-steps/LoginStep';
import { TariffInfo } from '../ui-components/TariffInfo';
import { MobileNavCore } from '../ui-components/MobileNavCore';
import { StepInfoItem } from '../interfaces';
import { isEnabledPremiumCalculation } from '../core-hooks';
import { mainStepName } from '../../booking-funnel/steps/PolicyStep1';

export interface PersonalStep {
  productCode: string;
  subStep: string | null;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  screenType: IScreenType;
  tenantSlug: string;
  recalculationTariff: RecalculationTariff;
  stepData: StepInfoItem;
  policyStepNames: string[];
}

interface ITypeOption {
  value: string;
  label: string;
}

const typeOptions: ITypeOption[] = [
  { value: '1', label: 'Ja' },
  { value: '0', label: 'Noch nicht' },
];

export const PersonalDataStep: React.FC<PersonalStep> = ( propsStep ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { showAlert } = useAppAlertService();
  const service = useWidgetService();
  const previousResultData = React.useRef<ITariffDataStep>( {} );
  const {
    productCode, subStep, lead, productData, screenType, tenantSlug, recalculationTariff, stepData, policyStepNames,
  } = propsStep;
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );
  const steps = productData.stepsConfig?.steps!.find( ( s ) => s.name === mainStepName );
  const keyPersonalData = stepData.nameStep;
  const personalStep = stepData.steps && stepData.steps.find( ( item ) => item.name === keyPersonalData );

  const formContext = useForm( {
    mode: 'onChange',
  } );

  const { errors, control, getValues, formState } = formContext;

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    const result = await service.saveSelectedClient( formData );
    if ( result.errorCode === 0 ) {
      actions.goToPersonalStep( ActiveStepCore.PersonalData, formData['infoSelectClient'], false );
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, service, showAlert, t ] );

  React.useEffect( () => {
    if ( !formState.isValid ) {
      return;
    }

    const formValues = getValues();
    const saveFormValues = async () => {
      try {
        await service.saveSelectedClient( previousResultData.current );
        actions.reloadLead();
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };
    if ( isEmpty( previousResultData.current ) ) {
      previousResultData.current = formValues;
      return;
    }
    if ( !isEqual( formValues, previousResultData.current ) ) {
      previousResultData.current = formValues;
      saveFormValues();
    }
  }, [ actions, formState.isValid, formState.isValidating, getValues, service, showAlert, t ] );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    actions.reloadLead();
    setShowSavedStep( isShow );
  }, [ actions ] );

  const onCloseSavedPopup = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  if ( !lead[stepData.previousStep!] ) {
    actions.goToStep( stepData.previousStep!, false );
  }

  const enabledPremiumCalculation = isEnabledPremiumCalculation( stepData, lead );

  if ( productData === null ) {
    return <LoadingSpinner />;
  }

  if ( subStep !== null && subStep === '0' ) {
    return (
      <RegistrationStep
        productCode={ productCode }
        lead={ lead }
        productData={ productData }
        stepData={ personalStep }
      />
    );
  }

  if ( subStep !== null && subStep === '1' ) {
    return (
      <LoginStep
        productCode={ productCode }
        lead={ lead }
        productData={ productData }
        screenType={ screenType }
        tenantSlug={ tenantSlug }
        policyStepNames={ policyStepNames }
      />
    );
  }

  return (
    <FormProvider { ...formContext }>
      <div id="step-tabpane-personal">
        { enabledPremiumCalculation && (
          <TariffInfo
            lead={ lead }
            productData={ productData }
            productCode={ productCode }
            screenType={ screenType }
            isIgnoreOnRecalculation={ true }
            isShowSavedInfo={ false }
            showSavePopup={ onSaved }
            recalculationTariff={ recalculationTariff }
            subSteps={ steps?.subSteps }
            keyStep={ stepData.nameStep }
            policyStepNames={ policyStepNames }
          />
        ) }
        <div id="alert-tariff-page" className="max-w-744 mx-0 mt-3 row">
          <Col md={ 12 }>
            <AppAlertUI />
          </Col>
        </div>
        <Row className="mt-5 mx-0 bf-custom-padding">
          <Col md={ 12 } className="head-tab mb-2 text-center mx-0">
            <h1 className="f-26">{ t( 'bookingFunnel.funkMesse.personalData.title' ) }</h1>
          </Col>
          <Col className="mt-3 px-0" md={ 12 }>
            <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
              <Row className="max-w-214">
                <Col sm={ 12 } className="mb-0 px-0">
                  <Controller
                    name="infoSelectClient"
                    control={ control }
                    rules={ { required: true } }
                    defaultValue={ lead.saveSelectedClient ? lead.saveSelectedClient['infoSelectClient'] : '' }
                    render={ ( props ) => (
                      <FormGroup className={ `custom-btn-radio mb-2 rdb-${props.name}` }>
                        { typeOptions.map( ( item, idx ) => {
                          return (
                            <Fragment key={ idx }>
                              <div className={ idx === 0 ? 'mb-5' : '' }>
                                <Form.Control
                                  { ...props }
                                  className="form-check-input"
                                  type="radio"
                                  id={ `option-${ props.name + '-' + item.value}` }
                                  value={ item.value }
                                  checked={ props.value === item.value }
                                  onBlur={ props.onBlur }
                                />
                                <Form.Label
                                  className="btn btn-border-radio m-0"
                                  htmlFor={ `option-${ props.name + '-' + item.value}` }
                                >
                                  { item.label }
                                </Form.Label>
                              </div>
                            </Fragment>
                          );
                        } ) }
                        <Form.Control
                          id={ `error-messages-${props.name}` }
                          type="hidden"
                          isInvalid={ errors[props.name] !== undefined }
                        />
                        <Form.Control.Feedback type="invalid">
                          { t( 'base:forms.messages.fieldRequired', {
                            fieldLabel: 'Kundenkonto',
                          } ) }
                        </Form.Control.Feedback>
                      </FormGroup>
                    ) }
                  />
                </Col>
                { personalStep && personalStep.requiredText && (
                  <Col sm={ 12 } className="mb-2 px-0">
                    <p className="f-12">{ personalStep.requiredText }</p>
                  </Col>
                ) }
              </Row>
              <div className="max-w-667">
                <MobileNavCore
                  lead={ lead }
                />
              </div>
              <div className="max-w-667">
                <div className="dynamic-btn-panel d-flex justify-content-space-between">
                  <Button
                    id={ `${stepData.nameStep}_back` }
                    type="button"
                    variant="link"
                    className="mt-2 mb-2 py-0 text-c-black custom-back-button"
                    onClick={ () => actions.goToStep( stepData.previousStep!, false ) }
                  >
                    { t( 'bookingFunnel.previousBtn' ) }
                  </Button>
                  <Button
                    id={ `${stepData.nameStep}_next` }
                    type="submit"
                    variant={ !lead.saveSelectedClient ? 'outline-primary' : 'primary' }
                    className="mr-0 mb-0 bg-btn-primary"
                  >
                    { t( 'bookingFunnel.nextBtn' ) }
                    <img
                      src={ !lead.saveSelectedClient ? rightArrowGreenIconUrl : rightArrowIconUrl }
                      alt={ t( 'bookingFunnel.nextBtn' ) }
                    />
                  </Button>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
        { isShowSavedStep && (
          <SavedDataPopup
            currentStep={ stepData.nameStep }
            productCode={ productCode }
            lead={ lead }
            productData={ productData! }
            screenType={ screenType }
            onClose={ () => onCloseSavedPopup( ) }
            goNextStep={ goNextStep }
            policyStepNames={ policyStepNames }
          />
        ) }
      </div>
    </FormProvider>
  );
};
