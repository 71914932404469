import React, { Fragment } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { JsonValue } from '@cover42/protobuf-util';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { ICFFactorType, ICFFactorTypeOption } from 'App/components/widgets/factor-service';
import { isBooleanVal, noValue, yesValue } from '../../core-hooks';
import { useTranslation } from 'react-i18next';
import { isObject } from '../../../bf-hooks';
import { useCoreActions } from '../../DynamicCore';
import { checkedUnderwriting } from '../../../booking-funnel/booking-funnel-hooks';

export interface FactorCheckButtonProps {
  valueField: string;
  variable: IBFProductVariable;
  factor: ICFFactorType;
  isShortName?: boolean;
  stepItem: ContainerFieldItem;
}

export const FactorCheckButton: React.FC<FactorCheckButtonProps> = ( {
  valueField,
  variable,
  factor,
  isShortName,
  stepItem,
} ) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const actions = useCoreActions();
  const { control } = useFormContext();

  const factorValues = React.useMemo<ICFFactorTypeOption[]>( () => {
    if ( factor && factor.values ) {
      return [ ...factor.values ].sort( ( a, b ) => {
        const aKey = parseInt( a.key as string );
        const bKey = parseInt( b.key as string );
        return aKey - bKey;
      } );
    }
    return [];

  }, [ factor ] );

  const getDefaultValue = React.useCallback(
    ( items: ICFFactorTypeOption[], keyItem?: JsonValue ): ICFFactorTypeOption | string => {
      if ( valueField ) {
        return valueField;
      }

      if ( items.length && keyItem ) {
        const findValue = items.find( ( i ) => i.key === keyItem )!;

        return findValue.key as string;
      }

      return noValue;
    }, [ valueField ] );

  const handleChange = React.useCallback( async (
    e: React.ChangeEvent<HTMLInputElement>,
    renderProps: ControllerRenderProps<Record<string, any>>,
  ) => {
    const checked = e.target.checked;
    const value: string = checked ? yesValue : noValue;

    renderProps.onChange( value );

    const isCheckedUnderwriting = checkedUnderwriting( stepItem );
    actions.recalculationPremium( isCheckedUnderwriting );
  }, [ actions, stepItem ] );

  const classNameToButton = ( valueBtn: JsonValue ): string => {
    let className = 'bf-button-check-box';
    let checkValue = valueBtn;

    if ( checkValue && isObject( checkValue ) ) {
      checkValue = checkValue['key'] ? checkValue['key'] : valueBtn;
    }

    if ( !isBooleanVal( checkValue as string ) ) {
      className = `${className} btn-activated`;
    }

    if ( isBooleanVal( checkValue as string ) ) {
      className = `${className} btn-checked`;
    }

    return className;
  };

  return (
    <Fragment>
      <Controller
        name={ isShortName ? variable.name :
          `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
        control={ control }
        rules={ { required: variable.isRequired } }
        defaultValue={ getDefaultValue( factorValues, variable.defaultValue ) }
        render={ ( props ) => (
          <FormGroup className={ classNameToButton( props.value ) }>
            <Form.Control
              { ...props }
              id={ `check-box-${props.name}` }
              checked={ isBooleanVal( props.value ) }
              className="form-check-input"
              type="checkbox"
              value={ props.value }
              onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => handleChange( e, props ) }
              onBlur={ props.onBlur }
            />
            <Form.Label
              className="btn"
              htmlFor={ `check-box-${props.name}` }
            >
              {
                t( `bookingFunnel.funkReanovo.tariffResult.${
                  !isBooleanVal( props.value ) ? 'addProduct' : 'deleteProduct'
                }` )
              }
            </Form.Label>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
