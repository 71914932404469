import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import DateUtil from '../../../date-util';
import { blankLink } from 'config';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { Separators } from 'Services/widgets/enums';
import {
  checkedUnderwriting,
  formatDefaultDate,
  formatInputDate,
  isRequiredField,
} from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { JsonObject } from '@cover42/protobuf-util';
import { DatePickerComponent } from '../DatePickerComponent';
import { isObject } from 'App/components/widgets/bf-hooks';
import { renderClassNameBox, renderFieldLabel } from '../../../core-hooks';
import { useAppLogger } from 'Services/logger';
import { useCoreActions } from '../../../DynamicCore';

export interface CorePolicyStartDateProps {
  valueField: string;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  isShortName?: Boolean;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
}

export const CorePolicyStartDateField: React.FC<CorePolicyStartDateProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  stepItem,
  isDisabled,
} ) => {
  const { t, i18n } = useTranslation( [ 'widgets', 'base' ] );
  const logger = useAppLogger();
  const actions = useCoreActions();
  const { errors, control, setValue, setError, trigger } = useFormContext();

  const separatorDot = Separators.Dot;
  const lng = i18n.language;
  const formatDateText = lng === 'de' ? 'TT.MM.JJJJ' : 'DD.MM.YYYY';
  const dateField = valueField ? valueField : '';
  const fieldName = isShortName ? variable.name :
    `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;

  const getFromDate = React.useCallback( ( ): string => {
    if ( stepItem && stepItem['minValue'] ) {
      const minValue = DateUtil.convertValueToDateTime( stepItem['minValue'] );
      if ( minValue ) {
        return minValue;
      }
    }

    if ( isObject( variable.minValue ) ) {
      const objectDate = variable.minValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    return '';
  }, [ stepItem, variable.minValue ] );

  const getToDate = React.useCallback( ( ): string => {
    if ( stepItem && stepItem['maxValue'] ) {
      const maxValue = DateUtil.convertValueToDateTime( stepItem['maxValue'] );
      if ( maxValue ) {
        return maxValue;
      }
    }

    if ( isObject( variable.maxValue ) ) {
      const objectDate = variable.maxValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    return '';
  }, [ stepItem, variable.maxValue ] );

  const isValidDateField = React.useCallback( ( dateVal: string ): boolean => {
    if ( isDisabled ) {
      return true;
    }

    const currentVal = dayjs( dateVal, formatDefaultDate, false );
    const fromDate = getFromDate() ? dayjs( getFromDate(), formatDefaultDate, false ) : null;
    const toDate = getToDate() ? dayjs( getToDate(), formatDefaultDate, false ) : null;

    if ( fromDate && fromDate.isAfter( currentVal, 'date' ) ) {
      return false;
    }

    if ( toDate && toDate.isBefore( currentVal, 'date' ) ) {
      return false;
    }

    return true;
  }, [ getFromDate, getToDate, isDisabled ] );

  const datePickerHandler = React.useCallback( ( dateVal: string, nameField: string ) => {
    if ( !isValidDateField( dateVal ) ) {
      const errMessage = t( 'base:forms.messages.startDateError', {
        minDate: dayjs( getFromDate() ).format( formatInputDate ),
        maxDate: dayjs( getToDate() ).format( formatInputDate ),
      } );

      setError( nameField, { shouldFocus: true, message: errMessage } );
    }

    if ( !dateVal && isValidDateField( dateVal ) ) {
      setValue( nameField, dateVal, { shouldValidate: true } );

      const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
      actions.recalculationPremium( isCheckedUnderwriting );

      return;
    }

    const selectedDate = dayjs( dateVal );

    if ( selectedDate.isValid() ) {
      setValue( nameField, selectedDate.format( formatDefaultDate ), { shouldValidate: true } );

      const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
      actions.recalculationPremium( isCheckedUnderwriting );
    }

    trigger();
  }, [ actions, getFromDate, getToDate, isValidDateField, setError, setValue, stepItem, t, trigger ] );

  const isValidFormatDate = React.useCallback( ( inputDate: string ): boolean => {
    if ( inputDate ) {
      const resDate = inputDate.replace( Separators.Underscore, '' );

      if ( resDate.length < 10 ) {
        return false;
      }

      const formatDate = resDate.split( separatorDot ).reverse().join( Separators.Dash );

      return dayjs( formatDate ).isValid();
    }

    return false;
  }, [ separatorDot ] );

  const datePickerBlurHandler = React.useCallback( ( dateVal: string, nameField: string ): void => {
    if ( !isValidFormatDate( dateVal ) ) {
      const errMessage = t( 'base:forms.messages.dateInvalid',
        { fieldLabel: variable.label, fieldFormat: formatDateText },
      );

      setError( nameField, { shouldFocus: true, message: errMessage } );
    }
  }, [ formatDateText, isValidFormatDate, setError, t, variable.label ] );

  const getDefaultValue = React.useCallback( ( valField: string ): string => {
    if ( valField ) {
      const fromDateVal = getFromDate() ? dayjs( getFromDate(), formatDefaultDate, false ) : null;
      const toDateVal = getToDate() ? dayjs( getToDate(), formatDefaultDate, false ) : null;
      const errMessage = t( 'base:forms.messages.dateError', {
        minDate: dayjs( fromDateVal ).format( formatInputDate ),
        maxDate: dayjs( toDateVal ).format( formatInputDate ),
      } );
      const formatDefaultVal = dayjs( valField ).format( formatInputDate );

      if ( fromDateVal && fromDateVal.isAfter( valField, 'date' ) ) {
        logger.error( `${errMessage} Default policy start date: ${formatDefaultVal}` );

        return fromDateVal.format();
      }

      if ( toDateVal && toDateVal.isBefore( valField, 'date' ) ) {
        logger.error( `${errMessage} Default policy start date: ${formatDefaultVal}` );

        return toDateVal.format();
      }

      return valField;
    }

    return '';
  }, [ getFromDate, getToDate, logger, t ] );

  React.useEffect( () => {
    if ( stepItem && isDisabled && stepItem.defaultValue ) {
      setValue( fieldName, stepItem.defaultValue, { shouldValidate: true } );
    }
  }, [ fieldName, isDisabled, setValue, stepItem ] );

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ {
          required: isDisabled ? false : isRequiredField( variable, stepItem ),
          validate: ( value ) => {
            if ( isDisabled ) {
              return true;
            }
            if( !isValidDateField( value ) ) {
              const errMessage = t( 'base:forms.messages.startDateError', {
                minDate: dayjs( getFromDate() ).format( formatInputDate ),
                maxDate: dayjs( getToDate() ).format( formatInputDate ),
              } );
              return errMessage;
            }
          },
        } }
        defaultValue={ getDefaultValue( dateField ) }
        render={ ( props ) => (
          <FormGroup
            className={
              `${renderClassNameBox( stepItem )} core-date-box date-picker-field
              ${errors[props.name] ? ' core-date-error' : ''}`
            }
            controlId={ props.name }
          >
            <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
              <div
                className="d-inline-block"
                dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
              />
              { variable.bfTooltip && (
                <a
                  id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                  href={ blankLink }
                  role='button'
                  onClick={ ( e ) => {
                    e.preventDefault();
                    showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            { variable.bfDescription && (
              <span className="core-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            <DatePickerComponent
              currentValue={ props.value }
              onChangeDate={ ( date: string ) => datePickerHandler( date, props.name ) }
              onBlurDate={ ( date: string ) => datePickerBlurHandler( date, props.name ) }
              fromDate={ getFromDate() }
              toDate={ getToDate() }
              separator={ separatorDot }
              placeholderText={ formatDateText }
              isReadOnly={ false }
              showMonthYearPicker={ false }
              showFullMonthYearPicker={ true }
              isDisabled={ isDisabled }
            />
            <span className="float-right f-12 mt-1">{
              t( 'base:forms.dateFormatInfo',
                { fieldFormat: formatDateText },
              )
            }
            </span>
            <Form.Control
              { ...props }
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired',
                    { fieldLabel: variable.label } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
