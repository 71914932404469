import React, { Fragment, ReactElement } from 'react';
import { useCoreActions } from '../DynamicCore';
import { ILeadData } from 'Services/widgets/interfaces';
import { Link, useLocation } from 'react-router-dom';
import { parseHashUrl } from '../../bf-hooks';
import { blankLink } from 'config';
import { prepareMobilNav } from '../core-hooks';
import { JSONComponentType } from '../../booking-funnel/enum';

export interface CoreNavlDataProps {
  lead: ILeadData;
}

export interface CoreNavItem {
  numberStep: number;
  hashStep: string;
  navigationName?: string;
  componentType?: JSONComponentType;
}

export const MobileNavCore: React.FC<CoreNavlDataProps> = ( { lead } ) => {
  const location = useLocation();
  const urlHash: string = location.hash.substring( 1 );
  const hashParams = parseHashUrl( urlHash );
  const actions = useCoreActions();
  const stepsInfo = actions.getStepsInfo();

  let itemList: CoreNavItem[] = [];

  if ( stepsInfo ) {
    itemList = prepareMobilNav( stepsInfo );
  }

  const onClickItem = React.useCallback( (
    e: React.MouseEvent<HTMLAnchorElement>, stepName: string, disabledLink: boolean ) => {
    e.preventDefault();
    if ( disabledLink ) {
      return;
    }
    actions.goToStep( stepName, true );
  }, [ actions ] );

  const isActiveItem = React.useCallback( ( nameHash: string ) => {
    const params = hashParams.filter( ( param ) => param.value === nameHash );

    if ( params.length > 0 ) {
      return true;
    }

    return false;
  }, [ hashParams ] );

  const mobileNavItems = React.useMemo<ReactElement[] | null>( () => {
    if ( !itemList ) {
      return null;
    }

    return itemList.map( ( item ): ReactElement => {
      let isDisabled: boolean = false;
      if ( lead && !lead[item.hashStep!] ) {
        isDisabled = true;
      }
      const isActive: boolean = isActiveItem( item.hashStep );

      const classDisabled = !isActive && isDisabled ? 'disabled-link' : '';
      return (
        <Link
          to={ blankLink }
          id={ `bf-menu-item-${ item.numberStep}` }
          title={ `Step ${item.numberStep}` }
          key={ item.numberStep }
          onClick={ ( e ) => { onClickItem( e, item.hashStep, isDisabled ); } }
          className={ `${isActive ? 'active' : classDisabled }` }
        >
          <span>{ item.numberStep }</span>
        </Link>
      );
    } );
  }, [ isActiveItem, itemList, lead, onClickItem ] );

  return (
    <Fragment>
      { mobileNavItems && (
        <div id="mobile-steps">
          <div className="d-flex justify-content-center">
            { mobileNavItems }
          </div>
        </div>
      ) }
    </Fragment>
  );
};
