import React, { RefObject } from 'react';
import LocaleDe from 'date-fns/locale/de';
import LocaleEn from 'date-fns/locale/en-GB';
import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';

export interface DatePickerProps {
  popperRef: RefObject<HTMLDivElement>;
  dayPickerRef: RefObject<HTMLDivElement>;
  currentValue: Date | undefined;
  isOpenDatePicker: boolean;
  fromDate: string;
  toDate: string;
  closePopper: ( ) => void;
  onChangeDate: ( dateValue ) => void;
  isDisabled?: boolean;
}

export const DatePickerComponent: React.FC<DatePickerProps> = ( props ) => {
  const { i18n } = useTranslation( [ 'widgets', 'base' ] );
  const {
    popperRef,
    dayPickerRef,
    currentValue,
    isOpenDatePicker,
    fromDate,
    toDate,
    closePopper,
    onChangeDate,
  } = props;
  const [ popperElement, setPopperElement ] = React.useState<HTMLDivElement | null>(
    null,
  );

  const popper = usePopper( popperRef.current, popperElement, {
    placement: 'auto',
  } );

  const lng = i18n.language;
  let locale = LocaleDe;

  if ( lng === 'en' ) {
    locale = LocaleEn;
  }

  const handleChangeDate = ( selectedDate ): void => {
    onChangeDate( selectedDate );
  };

  return (
    <FocusTrap
      active
      focusTrapOptions={ {
        initialFocus: false,
        allowOutsideClick: true,
        clickOutsideDeactivates: true,
        onDeactivate: closePopper,
        fallbackFocus: dayPickerRef.current!,
      } }
    >
      <div
        tabIndex={ -1 }
        style={ popper.styles.popper }
        className="dialog-day-picker"
        { ...popper.attributes.popper }
        ref={ setPopperElement }
        role="dialog"
      >
        <DayPicker
          initialFocus={ isOpenDatePicker }
          mode="single"
          captionLayout="dropdown"
          fromDate={ new Date( fromDate ) }
          toDate={ new Date( toDate ) }
          locale={ locale }
          defaultMonth={ currentValue }
          selected={ currentValue }
          onSelect={ handleChangeDate }
        />
      </div>
    </FocusTrap>
  );
};
