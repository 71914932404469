import React, { Fragment } from 'react';
import { JsonValue } from '@cover42/protobuf-util';
import { blankLink } from 'config';
import { isBoolean } from 'lodash';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { AsteriskRequiredField } from '../../AsteriskRequiredField';
import { isRequiredField } from '../../booking-funnel/booking-funnel-hooks';
import { FieldDefaultValue } from 'App/widget/types';

export interface NumberFieldProps {
  valueField: number;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string ) => void;
  isShortName?: boolean;
  showAsterisk?: boolean;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
}

export const NumberField: React.FC<NumberFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  showAsterisk,
  stepItem,
  fieldDefaultValue,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control } = useFormContext();
  const { productFieldValue, isReadOnly } = fieldDefaultValue || {};

  const getDefaultValue = ( numberValue: JsonValue ): number | string => {
    if ( productFieldValue ) {
      return productFieldValue as number;
    }

    return numberValue !== null ? numberValue as number : '';
  };

  const isValidateValue = ( value: number ): string | boolean => {
    if ( variable.minValue === null || variable.maxValue === null ) {
      return true;
    }

    const minValue = variable.minValue || Number( variable.minValue );
    const maxValue = variable.maxValue || Number( variable.maxValue );

    if( value < minValue ) {
      const errMessage = t( 'bookingFunnel.numberMin',
        { fieldLabel: variable.label, minValue: variable.minValue },
      );
      return errMessage;
    }

    if( value > maxValue ) {
      const errMessage = t( 'bookingFunnel.numberMax',
        { fieldLabel: variable.label, maxValue: variable.maxValue },
      );
      return errMessage;
    }

    return true;
  };

  return (
    <Fragment>
      <Controller
        name={ isShortName ? variable.name :
          `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
        control={ control }
        rules={ {
          required: isRequiredField( variable, stepItem ),
          validate: ( value ) => {
            return isValidateValue( value );
          },
        } }
        defaultValue={ valueField ? valueField : getDefaultValue( variable.defaultValue ) }
        render={ ( props ) => (
          <FormGroup controlId={ props.name }>
            <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
              { variable.bfLabel ?
                <div className="d-inline-block" dangerouslySetInnerHTML={ { __html: `${variable.bfLabel}` } }></div>
                : variable.label }
              { showAsterisk && variable.isRequired && ( <AsteriskRequiredField /> ) }
              { variable.bfTooltip && (
                <a
                  id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                  href={ blankLink }
                  onClick={ ( e ) => {
                    e.preventDefault();
                    showTooltip( true, variable.bfTooltip! );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            { variable.bfDescription && (
              <span className="gm-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            <Form.Control
              { ...props }
              type="number"
              inputMode="numeric"
              readOnly={ isBoolean( isReadOnly ) ? isReadOnly : false }
              placeholder={ variable.label }
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired',
                    { fieldLabel: variable.label } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
