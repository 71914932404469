import { FilterNamedRangeResponse } from 'Services/widgets/interfaces';

export enum FilterInputTypeEnum {
  Int = 'int',
  Text = 'text',
  Var = 'variable',
}

export interface FilterInput {
  columnName: string;
  label: string;
  inputType: FilterInputTypeEnum;
  minCharNo: number;
}

export interface ResultMapping {
  source: string;
  target: string;
}

export const encodeFilterValue = ( s: string ): string => {
  return s
    .replaceAll( '\\', '\\\\' )
    .replaceAll( ',', '\\\\,' )
    .replaceAll( ';', '\\\\;' );
};

export const evalInScope = ( code: string, contextAsScope: object ): any => {
  // eslint-disable-next-line no-new-func
  return new Function( `with (this) { return (${code}); }` ).call( contextAsScope );
};

const getTypeInput = ( input: string[] ): FilterInputTypeEnum => {
  if ( input.length >= 3 ) {
    if ( input[2].toLowerCase() === FilterInputTypeEnum.Int ) {
      return FilterInputTypeEnum.Int;
    }

    if ( input[2].toLowerCase() === FilterInputTypeEnum.Var ) {
      return FilterInputTypeEnum.Var;
    }
  }

  return FilterInputTypeEnum.Text;
};

export const parseInputs = ( input: string ): FilterInput[] => {
  const arr = JSON.parse( input ) as string[][];
  const inputs = arr.map( ( i ) => ( {
    columnName: i[0],
    label: i[1],
    inputType: getTypeInput( i ),
    minCharNo: i.length >= 4 ? parseInt( i[3] ) : 3,
  } ) );

  return inputs;
};

export const parseMappings = ( mapping: string ): ResultMapping[] => {
  if ( !( mapping ?? '' ).trim() ) {
    return [];
  }

  const arr = JSON.parse( mapping ) as string[][];
  const mappings = arr.map( ( i ) => ( {
    source: i[0],
    target: i[1],
  } ) );

  return mappings;
};

export const getEmptyListData = ( pageSize: number ): FilterNamedRangeResponse => ( {
  items: [],
  nextPageToken: '1',
  itemsPerPage: pageSize!,
  totalItems: 0,
} );

export const addListDataPage = (
  listData: FilterNamedRangeResponse,
  newPage: FilterNamedRangeResponse,
): FilterNamedRangeResponse => ( {
  items: [ ...( listData.items ?? [] ), ...( newPage.items ?? [] ) ],
  nextPageToken: newPage.nextPageToken,
  itemsPerPage: newPage.itemsPerPage,
  totalItems: newPage.totalItems,
} );

export const getFilterControlName = ( factorName: string | undefined, i: FilterInput ): string => (
  `list_${factorName}_filter_${i.columnName}`
);

export const getPageNumberControlName = ( factorName: string | undefined ): string => (
  `list_${factorName}_page_no`
);
