import React, { Fragment } from 'react';
import { blankLink } from 'config';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { JsonValue } from '@cover42/protobuf-util';
import { AsteriskRequiredField } from '../../AsteriskRequiredField';
import { isRequiredField } from '../../booking-funnel/booking-funnel-hooks';
import { FieldDefaultValue } from 'App/widget/types';

export interface BooleanFieldProps {
  valueField: boolean | undefined;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string ) => void;
  isShortName?: boolean;
  showAsterisk?: boolean;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
}

export const BooleanField: React.FC<BooleanFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  showAsterisk,
  stepItem,
  fieldDefaultValue,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control } = useFormContext();
  const { productFieldValue } = fieldDefaultValue || {};

  const getDefaultValue = ( boolValue: JsonValue ): boolean => {
    if ( productFieldValue ) {
      return productFieldValue as boolean;
    }

    return boolValue !== null ? boolValue as boolean : false;
  };

  return (
    <Fragment>
      <Controller
        name={ isShortName ? variable.name :
          `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
        control={ control }
        rules={ {
          required: isRequiredField( variable, stepItem ),
          validate: ( value ) => {
            if ( value === null ) {
              return false;
            }
            return true;
          },
        } }
        defaultValue={ valueField ? valueField : getDefaultValue( variable.defaultValue ) }
        render={ ( props ) => (
          <FormGroup>
            <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
              { variable.bfLabel ?
                <div className="d-inline-block" dangerouslySetInnerHTML={ { __html: `${variable.bfLabel}` } }></div>
                : variable.label }
              { showAsterisk && variable.isRequired && ( <AsteriskRequiredField /> ) }
              { variable.bfTooltip && (
                <a
                  id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                  href={ blankLink }
                  onClick={ ( e ) => {
                    e.preventDefault();
                    showTooltip( true, variable.bfTooltip! );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            { variable.bfDescription && (
              <span className="gm-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            <div className="switch switch-primary">
              <Form.Control
                { ...props }
                id={ props.name }
                className="cr"
                type="checkbox"
                onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => props.onChange( e.target.checked ) }
                checked={ props.value }
              />
              <Form.Label className="cr" htmlFor={ props.name }></Form.Label>
            </div>
            <Form.Control
              isInvalid={ errors[props.name] !== undefined }
              type="hidden"
            />
            <FormControl.Feedback type="invalid">
              { t( 'base:forms.messages.fieldRequired', {
                fieldLabel: variable.label,
              } ) }
            </FormControl.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
