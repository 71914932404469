import dayjs from 'dayjs';
import { DateTimeOffsets, DateTimeOperations, DateTimePlaceHolders } from './date-enums';
import { isDateString } from 'class-validator';

export default class DateUtil {
  // 'today+30d' -> { 'today', '+', '30', 'd' }
  private static getSplitDateTime( value: string ): {
    placeHolder: string;
    op?: string;
    offset?: string;
    offsetUnit?: string;
  } | null {
    // '{{today}} +30d' -> 'today+30d'
    const stripedStr = value.replace( /\s|{{|}}/g, '' );
    // 'today+30d' -> ['today', '+', '30d']

    const strs = stripedStr.split( /(\+|-)/g );
    if ( !strs.length ) {
      return null;
    }

    let template: {
      placeHolder: string;
      op?: string;
      offset?: string;
      offsetUnit?: string;
    } = {
      placeHolder: strs[0],
    };

    if ( strs.length === 3 ) {
      // + or -
      const op = strs[1];
      // '30d' -> 30, d
      const offset = strs[2].substr( 0, strs[2].length - 1 );
      const offsetUnit = strs[2].substr( strs[2].length - 1 );

      template = {
        ...template,
        op,
        offset,
        offsetUnit,
      };
    }

    return template;
  }

  private static convertDateTimePlaceholder( date: string ): dayjs.Dayjs {
    let day = dayjs();

    switch ( date ) {
      default:
        day = day.startOf( 'day' );
        break;
      case DateTimePlaceHolders.Tomorrow:
        day = day.add( 1, 'day' ).startOf( 'day' );
        break;
      case DateTimePlaceHolders.StartNextMonth:
        day = day.add( 1, 'month' ).startOf( 'month' );
        break;
      case DateTimePlaceHolders.StartNextYear:
        day = day.add( 1, 'year' ).startOf( 'year' );
        break;
    }

    return day;
  }

  static convertValueToDateTime( value: string ): string | null {
    if ( isDateString( value ) ) {
      return dayjs( value ).format();
    }

    const splitStrs = this.getSplitDateTime( value );
    if ( splitStrs === null ) {
      return null;
    }

    const { placeHolder, op, offset, offsetUnit } = splitStrs;

    let day = this.convertDateTimePlaceholder( placeHolder );

    if ( op && offset && offsetUnit ) {
      const sign = op === DateTimeOperations.Add ? 1 : -1;
      switch ( offsetUnit ) {
        default:
          day = day.add( +offset * sign, 'day' );
          break;
        case DateTimeOffsets.Month:
          day = day.add( +offset * sign, 'month' );
          break;
        case DateTimeOffsets.Year:
          day = day.add( +offset * sign, 'year' );
          break;
      }
    }

    return day.format();
  }
}
