export enum EmploymentStatus {
  Student = '0',
  InternStudent = '1',
  DoctorsAssistant = '2', // Doctor's assistant in training
}

export enum FUNKBFStep {
  TariffSubStep1 = 'step1_tariffSubStep1',
  TariffSubStep2 = 'step1_tariffSubStep2',
  TariffResultStep = 'step2_tariffResult',
  TariffChangeStep = 'step3_tariffChange',
  PersonalDataStep = 'step4_personalData',
  RegistrationStep = 'step4_registration',
  LoginStep = 'step4_login',
  SmsStep = 'step4_smsLogin',
  SummaryStep = 'step5_summarySubStep',
  PaymentStep = 'step5_paymentSubStep',
  PaymentApprovementStep = 'step6_paymentApprovement'
}
