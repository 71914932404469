import React from 'react';
import loginIconUrl from 'assets/bf-images/funk/login_icon.svg';
import loginGreenIconUrl from 'assets/bf-images/funk/login_green_icon.svg';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { useAppAlertService, AppAlertUI } from 'App/components/utils/alerts/AppAlertService';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { ActiveStepCore, useCoreActions } from '../../DynamicCore';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { SavedDataPopup } from '../../ui-components/SavedDataPopup';
import {
  IInitiateAuth,
  ILeadData,
  IRespondAuth,
  IRespondAuthResponse,
  IScreenType,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { useWidgetService } from 'Services/widget';
import { ChallengeNames } from 'Services/widgets/enums';
import { LoginSMSStep } from './LoginSMSStep';
import { FunkBFStep } from 'App/components/widgets/funk-messe/enums';
import { MobileNavCore } from '../../ui-components/MobileNavCore';
import { useLoginDataValidationSchemaFunk } from '../../validation';
import { yupResolver } from '@hookform/resolvers/yup';

export interface LoginStep {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  screenType: IScreenType;
  tenantSlug: string;
  policyStepNames: string[];
}

export interface LoginData {
  username: string;
  password: string;
}

export interface ILoginState {
  responseAuthData: IRespondAuthResponse;
  loginData: IRespondAuth;
}

export const LoginStep: React.FC<LoginStep> = ( propsStep ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { showAlert, hideAlert } = useAppAlertService();
  const service = useWidgetService();
  const loginDataValidationSchema = useLoginDataValidationSchemaFunk();
  const { productCode, lead, productData, screenType, tenantSlug, policyStepNames } = propsStep;
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );
  const [ isLoginSMSStep, setLoginSMSStep ] = React.useState<boolean>( false );
  const [ loginState, setLoginState ] = React.useState<ILoginState | null>( null );
  const [ passwordShown, setPasswordShown ] = React.useState<boolean>( false );

  const togglePassword = React.useCallback ( ( ): void => {
    setPasswordShown( !passwordShown );
  }, [ passwordShown ] );

  const formContext = useForm<LoginData>( {
    mode: 'onChange',
    resolver: yupResolver( loginDataValidationSchema ),
  } );

  const { errors, formState } = formContext;

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    hideAlert();

    const cliendData: ITariffDataStep = {
      username: formData.username,
    };

    const initUser: IInitiateAuth = {
      authFlow: ChallengeNames.VERIFY_USERNAME,
      authParameters: {
        USERNAME: cliendData.username,
      },
      tenantSlug,
    };

    try {
      const initAuth = await service.initiateAuthorization( initUser );
      if( initAuth ) {
        const loginData: IRespondAuth = {
          challengeName: initAuth.challengeName,
          challengeResponses: {
            USERNAME: formData.username,
            PASSWORD: formData.password,
          },
          tenantSlug,
        };

        const responseAuth = await service.respondAuthorization( loginData );

        if ( responseAuth ) {
          const authData: IRespondAuthResponse = {
            ...responseAuth,
            tenantSlug,
          };

          setLoginState( {
            responseAuthData: authData,
            loginData,
          } );
          setLoginSMSStep( true );
        } else {
          showAlert( {
            message: t( 'base:forms.messages.errorSave' ),
            type: 'danger',
          } );
        }
      }
    } catch ( error ) {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ hideAlert, service, showAlert, t, tenantSlug ] );

  const onCloseSavedPopup = React.useCallback ( ( ): void => {
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( ( ): void => {
    setShowSavedStep( false );
  }, [] );

  const addClassToField = ( errorField: string | undefined, valueField: string ): string => {
    if ( errorField !== undefined ) {
      return 'dynamic-input-error';
    }

    if ( errorField === undefined && valueField ) {
      return 'dynamic-checked';
    }

    return '';
  };

  if ( productData === null ) {
    return <div className="bf-loading h-100 my-5"><LoadingSpinner /></div>;
  }

  if ( isLoginSMSStep ) {
    return (
      <LoginSMSStep
        productCode={ productCode }
        lead={ lead }
        productData={ productData }
        screenType={ screenType }
        loginState={ loginState! }
        policyStepNames={ policyStepNames }
      />
    );
  }

  return (
    <FormProvider { ...formContext }>
      <div id="login-data-step">
        <Row className="mt-5 mx-0 bf-custom-padding">
          <Col md={ 12 } className="head-tab mb-2 text-center px-0 mx-0">
            <h1 className="f-26">{ t( 'bookingFunnel.funkMesse.login.titleLogin' ) }</h1>
          </Col>
          <Col className="mt-3 px-0" md={ 12 }>
            <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
              <Row className="max-w-414">
                <Col id="alert-tariff-page">
                  <AppAlertUI />
                </Col>
                <Col sm={ 12 } className="mb-4 px-0">
                  <Controller
                    name="username"
                    rules={ {
                      required: true,
                    } }
                    defaultValue={ '' }
                    render={ ( props ) => (
                      <FormGroup
                        className={ addClassToField( errors[props.name], props.value ) }
                        controlId={ props.name }
                      >
                        <Form.Label>{ t( 'bookingFunnel.funkMesse.login.email' ) }</Form.Label>
                        <Form.Control
                          { ...props }
                          className="dynamic-email-input"
                          type="email"
                          placeholder={ t( 'bookingFunnel.funkMesse.login.emailPlaceholder' ) }
                          isInvalid={ errors[props.name] !== undefined }
                        />
                        <Form.Control.Feedback type="invalid">
                          { errors[props.name]?.message }
                        </Form.Control.Feedback>
                      </FormGroup>
                    ) }
                  />
                </Col>
                <Col sm={ 12 } className="mb-0 px-0">
                  <Controller
                    name="password"
                    rules={ {
                      required: true,
                    } }
                    defaultValue={ '' }
                    render={ ( props ) => (
                      <FormGroup
                        className={ addClassToField( errors[props.name], props.value ) }
                        controlId={ props.name }
                      >
                        <Form.Label>{ t( 'bookingFunnel.funkMesse.login.password' ) }</Form.Label>
                        <Row className="bf-input-container m-0">
                          <div className="input-group dynamic-input-group">
                            <Form.Control
                              { ...props }
                              type={ passwordShown ? 'text' : 'password' }
                              placeholder={ t( 'bookingFunnel.funkMesse.login.passwordPlaceholder' ) }
                              isInvalid={ errors[props.name] !== undefined }
                            />
                            <div className="dynamic-icon-select">
                              <span className="input-group-text">
                                { props.value && (
                                  <Button
                                    type="button"
                                    title={
                                      t( `bookingFunnel.funkMesse.login.${passwordShown ? 'show' : 'hide' }Password` )
                                    }
                                    variant="link"
                                    className="m-0 custom-back-button"
                                    onClick={ togglePassword }
                                  >
                                    { t( `bookingFunnel.funkMesse.login.${passwordShown ? 'show' : 'hide' }Password` ) }
                                  </Button>
                                ) }
                              </span>
                            </div>
                          </div>
                        </Row>
                        <Form.Control.Feedback type="invalid">
                          { errors[props.name]?.message }
                        </Form.Control.Feedback>
                      </FormGroup>
                    ) }
                  />
                </Col>
                <Col sm={ 12 } className="mb-0 px-0">
                  <p className="px-35 f-12">{ t( 'bookingFunnel.funkMesse.required' ) }</p>
                </Col>
              </Row>
              <div className="max-w-667">
                <MobileNavCore
                  lead={ lead }
                />
              </div>
              <div className="max-w-667">
                <div className="dynamic-btn-panel d-flex justify-content-space-between">
                  <Button
                    id={ `${FunkBFStep.LoginStep}_back` }
                    type="button"
                    variant="link"
                    className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
                    onClick={ () => actions.goToPersonalStep( ActiveStepCore.PersonalData, null, true ) }
                  >
                    { t( 'bookingFunnel.previousBtn' ) }
                  </Button>
                  <Button
                    id={ `${FunkBFStep.LoginStep}_next` }
                    type="submit"
                    variant={ !formState.isValid ? 'outline-primary' : 'primary' }
                    className="mr-0 mb-0 bg-btn-primary"
                  >
                    { t( 'bookingFunnel.funkMesse.login.buttonLogin' ) }
                    <img
                      src={ !formState.isValid ? loginGreenIconUrl : loginIconUrl }
                      alt={ t( 'bookingFunnel.funkMesse.login.buttonLogin' ) }
                    />
                  </Button>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
        { isShowSavedStep && (
          <SavedDataPopup
            currentStep={ FunkBFStep.LoginStep }
            productCode={ productCode }
            lead={ lead }
            productData={ productData! }
            screenType={ screenType }
            onClose={ () => onCloseSavedPopup( ) }
            goNextStep={ goNextStep }
            policyStepNames={ policyStepNames }
          />
        ) }
      </div>
    </FormProvider>
  );
};
