import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePickerComponent } from '../booking-funnel/ui/DatePickerComponent';
import { Separators } from 'Services/widgets/enums';

export interface BirthDateFieldProps {
  nameField: string;
  labelField: string;
  valueField: string;
  isRequired: boolean;
  onChangeData?: ( ) => void;
  endDate?: string;
  isDisabled?: boolean;
}

export const BirthDateField: React.FC<BirthDateFieldProps> = ( {
  nameField,
  labelField,
  valueField,
  isRequired,
  endDate,
  isDisabled,
} ) => {
  const { t, i18n } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control, setValue, setError } = useFormContext();
  const separatorDot = Separators.Dot;
  const lng = i18n.language;
  const formatDateText = lng === 'de' ? 'TT.MM.JJJJ' : 'DD.MM.YYYY';
  const fromDate = dayjs().subtract( 120, 'years' ).format();

  const datePickerHandler = React.useCallback( ( dateVal: string ) => {
    if ( !dateVal ) {
      setValue( nameField, dateVal, { shouldValidate: true } );
      return;
    }

    const selectedDate = dayjs( dateVal );

    if ( selectedDate.isValid() ) {
      setValue( nameField, selectedDate.format(), { shouldValidate: true } );
    }
  }, [ nameField, setValue ] );

  const isValidFormatDate = React.useCallback( ( inputDate: string ): boolean => {
    if ( inputDate ) {
      const resDate = inputDate.replace( Separators.Underscore, '' );

      if ( resDate.length < 10 ) {
        return false;
      }

      const formatDate = resDate.split( separatorDot ).reverse().join( Separators.Dash );

      return dayjs( formatDate ).isValid();
    }

    return false;
  }, [ separatorDot ] );

  const datePickerBlurHandler = React.useCallback( ( dateVal: string ): void => {
    if ( !isValidFormatDate( dateVal ) ) {
      const errMessage = t( 'base:forms.messages.dateInvalid',
        { fieldLabel: labelField, fieldFormat: formatDateText },
      );

      setError( nameField, { shouldFocus: true, message: errMessage } );
    }
  }, [ formatDateText, isValidFormatDate, labelField, nameField, setError, t ] );

  const defaultDateValue = React.useCallback( () => {
    if ( valueField && valueField !== 'null' ) {
      return valueField;
    }

    if ( !valueField && isDisabled ) {
      return endDate!;
    }

    return '';
  }, [ endDate, isDisabled, valueField ] );

  return (
    <Fragment>
      <Controller
        name={ nameField }
        control={ control }
        rules={ { required: isRequired } }
        defaultValue={ defaultDateValue }
        render={ ( props ) => (
          <FormGroup
            className={ `core-date-box date-picker-field${errors[props.name] ? ' core-date-error' : ''}` }
            controlId={ props.name }
          >
            <Form.Label id={ `${props.name}-label` }>
              { labelField }
            </Form.Label>
            <DatePickerComponent
              currentValue={ props.value }
              onChangeDate={ ( date: string ) => datePickerHandler( date ) }
              onBlurDate={ ( date: string ) => datePickerBlurHandler( date ) }
              fromDate={ fromDate }
              toDate={ endDate! }
              separator={ Separators.Dot }
              placeholderText={ formatDateText }
              isReadOnly={ false }
              showMonthYearPicker={ false }
              showFullMonthYearPicker={ true }
              isDisabled={ isDisabled }
            />
            <span className="float-right f-12 mt-1">{
              t( 'base:forms.dateFormatInfo',
                { fieldFormat: formatDateText },
              )
            }
            </span>
            <Form.Control
              { ...props }
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message && !errors[props.name]?.type ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired',
                    { fieldLabel: labelField } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
