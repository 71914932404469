import React, { Fragment } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import saveIconUrl from 'assets/bf-images/funk/save_icon.svg';
import savePopupIconUrl from 'assets/bf-images/funk/save_icon_popup.svg';
import closeIconUrl from 'assets/bf-images/funk/close_icon.svg';
import useScreenType from 'react-screentype-hook';
import { Button, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { ILeadData, IProductInvoiceData } from 'Services/widgets/interfaces';
import { keyIsDisabledSaveForLater, renderPrice } from '../../core-hooks';
import { defaultDateFormat } from 'App/ui-utils';
import { useWidgetService } from 'Services/widget';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { oneTimePayment } from 'App/components/widgets/bf-hooks';
import { useLocale } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';

interface InvoiceItemCore {
  unit: string;
}

interface InvoiceCore {
  netAmount: number;
  taxAmount: number;
  grossAmount: number;
  creditAmount: number;
  currency?: string;
  invoiceItems: InvoiceItemCore[];
}

export interface CustomTariffInfoProps {
  lead: ILeadData;
  premiumData: IProductInvoiceData | null;
  isShowSavedInfo?: boolean;
  showSavePopup?: ( isShow: boolean ) => void;
  showSaveForLaterButton?: boolean;
  showWelcomePopup?: ( isShow: boolean ) => void;
  keyStep: string;
  className?: string;
  statusInfo: string;
  titleMessage: string;
}

const defaultResult: InvoiceCore = {
  netAmount: 0,
  taxAmount: 0,
  grossAmount: 0,
  creditAmount: 0,
  invoiceItems: [
    {
      unit: 'year',
    },
  ],
};

export const CustomTariffInfo: React.FC<CustomTariffInfoProps> = ( props ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { showAlert } = useAppAlertService();
  const screenType = useScreenType();
  const locale = useLocale();
  const service = useWidgetService();
  const {
    lead, premiumData,
    isShowSavedInfo, showSavePopup, showSaveForLaterButton, showWelcomePopup,
    keyStep, className = 'max-w-744', statusInfo, titleMessage,
  } = props;

  const [ tariffResultData, setTariffResultData ] = React.useState<IProductInvoiceData | InvoiceCore | null>(
    premiumData );

  const [ isShowWelcome, setShowWelcome ] = React.useState<boolean>(
    lead.isShowWelcome !== undefined ? lead.isShowWelcome : false,
  );
  const [ isDisabledSaveForLater, setIsDisabledSaveForLater ] = React.useState<boolean>( false );

  const triggerTime = React.useRef<Dayjs>( dayjs() );
  const savedAt = lead.savedAt !== undefined ? dayjs( lead.savedAt ).format( defaultDateFormat ) :
    dayjs().format( defaultDateFormat );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    if ( isDisabledSaveForLater ) {
      return;
    }

    if ( showSavePopup ) {
      showSavePopup( isShow );
    }
  }, [ isDisabledSaveForLater, showSavePopup ] );

  const onClose = React.useCallback ( async ( isShow: boolean ): Promise<void> => {
    try {
      const result = await service.savedInfo( 'isShowWelcome', false );
      if ( result ) {
        setShowWelcome( isShow );

        if ( showWelcomePopup ) {
          showWelcomePopup( isShow );
        }
      }
    } catch( e ) {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ service, showAlert, showWelcomePopup, t ] );

  React.useEffect( () => {
    setTariffResultData( premiumData );

    const interval = setInterval( () => {
      const currentTime = dayjs();
      const diffSeconds = currentTime.diff( triggerTime.current, 'seconds' );

      if ( premiumData === null && diffSeconds >= 20 ) {
        setTariffResultData( defaultResult );
        triggerTime.current = currentTime;
      }

    }, 20000 );

    return () => {
      clearInterval( interval );
    };
  }, [ premiumData ] );

  React.useEffect( () => {
    let isMounted = true;

    const checkData = async () => {
      if ( isMounted ) {
        const leadData: ILeadData = await service.getLead();

        if( leadData && leadData[keyIsDisabledSaveForLater] ) {
          setIsDisabledSaveForLater( true );
        }
      }
    };

    checkData();

    return () => {
      isMounted = false;
    };
  }, [ service ] );

  return (
    <Row id="price-panel" className={ `${ className } ${!isShowSavedInfo ? 'sticky-top' : ''}` }>
      <Col md={ 12 } className={
        `dynamic-tariff-info head-tab text-center mx-0 py-3 px-0 ${screenType.isMobile && 'no-border-radius '}`
      }
      >
        { !isShowWelcome && (
          <Fragment>
            { tariffResultData === null && (
              <div className="snippet" data-title="dot-flashing-th1">
                <div className="stage-top">
                  <div className="dot-flashing-th1"></div>
                </div>
              </div>
            ) }
            { tariffResultData !== null && (
              <Fragment>
                { statusInfo && (
                  <span className="tariff-head">{ statusInfo }</span>
                ) }
                <span className={ `tariff-price${statusInfo ? ' add-margin-top' : ''}` }>
                  { renderPrice( tariffResultData.grossAmount, locale, tariffResultData.currency!, true ) }
                </span>
              </Fragment>
            ) }
            <span className="tariff-time">
              { titleMessage }
              { tariffResultData && tariffResultData.invoiceItems[0].unit &&
              tariffResultData.invoiceItems[0].unit !== oneTimePayment && (
                <span
                  className="text-current-color"
                > / { t( `bookingFunnel.dynamicCore.timeOptions.${tariffResultData.invoiceItems[0].unit}` ) }
                </span>
              ) }
            </span>
          </Fragment>
        ) }
        { showSavePopup && showSaveForLaterButton && !isShowWelcome && (
          <Button
            id={ `${keyStep}_save_data` }
            type="button"
            variant="primary"
            disabled={ isDisabledSaveForLater }
            className={ `btn-save ${isShowSavedInfo ? 'btn-save-info' : ''}` }
            onClick={ () => onSaved( true ) }
          >
            <img src={ isShowSavedInfo ? savePopupIconUrl : saveIconUrl } alt="Save Alt" />
          </Button>
        ) }

        { isShowWelcome && (
          <div className="dynamic-welcome-info">
            <h2 className="f-30 text-white mb-2">{ t( 'bookingFunnel.funkReanovo.welcomeTitleSaved' ) }</h2>
            <p>
              <Trans
                i18nKey={ t( 'bookingFunnel.funk.welcomeTextInfo', { savedDate: `${savedAt}` } ) }
                ns="widgets"
                components={ {
                  textBold: <b>textBold</b>,
                } }
              />
            </p>
            <Button
              type="button"
              variant="primary"
              className="btn-close"
              onClick={ () => onClose( false ) }
            >
              <img src={ closeIconUrl } alt="Close Alt" />
            </Button>
          </div>
        ) }
      </Col>
    </Row>
  );
};
