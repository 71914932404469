import React, { useContext } from 'react';
import { ApiContext } from 'Services/api-context';
import { IWidgetService, WidgetServiceContext } from 'Services/widget';
import { AppStorageContext } from './AppStorageCtx';
import { ServiceProviderProps } from './common';

export const WidgetServiceProvider:
React.FC<ServiceProviderProps<IWidgetService>> = ( props, context ) => {
  const storage = React.useContext( AppStorageContext );
  const api = useContext( ApiContext );
  const widgetService: IWidgetService = new props.useClass( api, storage );

  return (
    <WidgetServiceContext.Provider value={ widgetService }>
      { props.children }
    </WidgetServiceContext.Provider>
  );
};
