import React, { Fragment } from 'react';
import errorImgUrl from 'assets/bf-images/funk/error_img.svg';
import backIconUrl from 'assets/bf-images/funk/back_icon.svg';
import { Button, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

export interface InvalidLinkPageProps {
  addedClassName: () => string;
  homePageHandler: ( isGoToPortal: boolean ) => void;
}

export const InvalidLinkPage: React.FC<InvalidLinkPageProps> = ( props ) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const { addedClassName, homePageHandler } = props;

  return (
    <Fragment>
      <div className={ `success-page custom-layout-4 ${ addedClassName() }` }>
        <div className="dynamic-success-page max-w-744">
          <Row className="mx-0 bf-custom-padding">
            <Col md={ 12 } className="mt-4 text-center">
              <img className="mb-3" src={ errorImgUrl } alt="Error icon" />
              <h1 className="dynamic-title-h1 mb-4">{ t( 'bookingFunnel.funkReanovo.invalidLinkMessage' ) }</h1>
            </Col>
            <Col md={ 12 } className="mt-4 text-center ws-contact-box max-w-550">
              <p className="f-16">
                <Trans
                  i18nKey="bookingFunnel.funkReanovo.invalidLinkTextInfo"
                  ns="widgets"
                  components={ {
                    textBold: <b>text</b>,
                  } }
                />
              </p>
            </Col>
          </Row>
          <Row className="dynamic-btn-panel mt-5 mx-0 d-flex justify-content-center">
            <Button
              type="button"
              variant="link"
              onClick={ ( e ) => homePageHandler( false ) }
              className="mt-2 mb-2 mx-0 py-0 btn-green-color bg-btn-primary"
            >
              { t( 'bookingFunnel.funkReanovo.toResetBtn' ) }
              <img src={ backIconUrl } alt={ t( 'bookingFunnel.funkReanovo.toResetBtn' ) } />
            </Button>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};
