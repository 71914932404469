import React from 'react';
import 'react-widgets/styles.css';
import Combobox from 'react-widgets/Combobox';
import debounce from 'lodash/debounce';
import { ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useWidgetService } from 'Services/widget';
import { AddressItem } from 'Services/widgets/interfaces';
import { AddressFields } from 'Services/widgets/enums';

export interface AddressTypeOption {
  label: string;
  countryName?: string;
  city?: string;
  street?: string;
  postalCode?: string;
  houseNumber?: string;
}

export interface SelectState {
  value: ValueType<AddressTypeOption>;
  isLoading?: boolean;
  isSelected: boolean;
}

export interface ValuesProps {
  nameField: string;
  selectValue: AddressTypeOption;
  isDisabled?: boolean;
  placeholder: string;
}

export const CoreAddressSelectElement: React.FC<ValuesProps> = ( {
  nameField,
  selectValue,
  isDisabled,
  placeholder,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const service = useWidgetService();
  const errMessage = t( 'base:forms.messages.addressInvalid' );
  const { setValue, setError, clearErrors, trigger } = useFormContext();
  const stateDefault: SelectState = {
    value: selectValue,
    isSelected: selectValue.label ? true : false,
  };

  const [ selectState, setSelectState ] = React.useState<SelectState>( stateDefault );
  const [ addressItems, setAddressItems ] = React.useState<AddressItem[]>( [] );

  const promiseOptions = React.useCallback( ( inputValue: string ) => {
    return service.getAddressCompletions( inputValue, selectValue.countryName );
  }, [ service, selectValue.countryName ] );

  const debouncedSearch = React.useCallback( debounce(
    ( searchText: string, setter: ( data: AddressItem[] ) => void ) => {
      return promiseOptions( searchText ).then( ( res ) => {
        setSelectState( {
          ...selectState,
          isLoading: false,
          isSelected: false,
        } );
        res = res.map( ( item ) => {
          const labelDestructured = item.label.split( ',' );
          labelDestructured.pop();
          const label = labelDestructured.join( ', ' );
          return {
            ...item,
            label,
          };
        } );

        return setter( res );
      } );
    }, 1000 ), [] );

  const setAddressValues = React.useCallback( ( fieldName: string, item: AddressTypeOption ) => {
    setValue( AddressFields.ZipCode, item.postalCode, { shouldValidate: true } );
    setValue( AddressFields.City, item.city, { shouldValidate: true } );
    setValue( AddressFields.Street, item.street, { shouldValidate: true } );
    setValue( AddressFields.HouseNumber, item.houseNumber, { shouldValidate: true } );

    if ( item.label ) {
      setValue( AddressFields.Country, item.countryName, { shouldValidate: true } );
      setValue( fieldName, item.label, { shouldValidate: true } );
    }
  }, [ setValue ] );

  const handleCloseMenu = React.useCallback( ( ) => {
    if ( !selectState.isSelected ) {
      setError( nameField, { shouldFocus: true, message: errMessage } );
    }
  }, [ errMessage, nameField, selectState.isSelected, setError ] );

  const handleOnChange = React.useCallback( ( searchVal ) => {
    if ( searchVal && typeof searchVal === 'string' ) {
      setSelectState( {
        ...selectState,
        isLoading: true,
        isSelected: false,
      } );

      debouncedSearch( searchVal, setAddressItems );
    }

    if ( !searchVal ) {
      setSelectState( {
        ...selectState,
        value: null,
        isLoading: false,
        isSelected: false,
      } );
      setValue( nameField, '', { shouldValidate: true } );
      setAddressItems( [] );
    }
  }, [ debouncedSearch, nameField, selectState, setValue ] );

  const handleOnSelect = React.useCallback( async ( item: AddressItem ) => {
    const errorItem: AddressTypeOption = {
      postalCode: '',
      city: '',
      countryName: '',
      street: '',
      houseNumber: '',
      label: '',
    };

    try {
      const resValidateAddress = await service.validateAddress( item );

      if ( resValidateAddress.isAddressValid ) {
        setSelectState( {
          value: item,
          isLoading: false,
          isSelected: true,
        } );

        setAddressValues( nameField, item );

        // Needed small pause for rebuild data
        setTimeout( () => {
          clearErrors( nameField );
          trigger( nameField );
        }, 300 );
      } else {
        setAddressValues( nameField, errorItem );
        setError( nameField, { shouldFocus: true, message: errMessage } );
      }
    } catch ( error ) {
      setAddressValues( nameField, errorItem );
      setError( nameField, { shouldFocus: true, message: errMessage } );
    }
  }, [ clearErrors, errMessage, nameField, service, setAddressValues, setError, trigger ] );

  return (
    <Combobox
      filter={ false }
      defaultValue={ selectState.value && selectState.value['label'] ? selectState.value['label'] : null }
      placeholder={ placeholder }
      hideCaret
      hideEmptyPopup
      textField="label"
      className={ `${selectState.isLoading ? 'combobox-loading' : ''}` }
      data={ addressItems }
      onChange={ handleOnChange }
      onSelect={ handleOnSelect }
      disabled={ isDisabled }
      busy={ selectState.isLoading }
      onBlur={ handleCloseMenu }
    />
  );
};
