import React, { Fragment } from 'react';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { PolicySubStep1 } from './tariff-sub-steps/PolicyStep1';
import { PolicySubStep2 } from './tariff-sub-steps/PolicyStep2';
import { IActiveStepType, IDataFactorsAndVariables } from '../BookingFunnel';
import { ILeadData } from 'Services/widgets/interfaces';
import { AppAlertUI } from 'App/components/utils/alerts/AppAlertService';

export interface ISubStep {
  subStep: IActiveStepType;
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
}

export const mainStepName = 'PolicyStep';
export const subStep1Name = 'policy1.1';
export const subStep2Name = 'policy1.2';

export const PolicyStep1: React.FC<ISubStep> = ( props ) => {
  const { subStep, productCode, lead, productData } = props;
  const steps = productData.stepsConfig?.steps!.find( ( s ) => s.name === mainStepName );
  const subStep1Data = steps?.subSteps.find( ( item ) => item.name === subStep1Name );
  const subStep2Data = steps?.subSteps.find( ( item ) => item.name === subStep2Name );

  if ( productData === null ) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      <div id="alert-tariff-page" className="max-w-450 row">
        <AppAlertUI />
      </div>
      { subStep === 'subStep1' && (
        <PolicySubStep1
          stepItems={ subStep1Data! }
          lead={ lead }
          variables={ productData.variables.filter( ( v ) => !v.isHidden ) }
          factors={ productData.factors }
          productData={ productData }
        />
      ) }
      { subStep === 'subStep2' && (
        <PolicySubStep2
          stepItems={ subStep2Data! }
          productCode={ productCode }
          lead={ lead }
          isNotDataSubStep1={ lead.subStep1TariffData === null }
          productData={ productData }
        />
      ) }
    </Fragment>
  );
};
