import React, { Fragment } from 'react';
import { Col, Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { PaymentSubStepItem } from '../../../summary-sub-steps/PaymentSubStep';

export interface CardFieldsProps {
  paymentFields: PaymentSubStepItem;
};

export const CardFields: React.FC<CardFieldsProps> = ( { paymentFields } ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control } = useFormContext();

  return (
    <Fragment>
      <Col sm={ 12 } className="mb-4">
        <Controller
          name="accountFirstName"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountFirstName || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.firstName' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                isInvalid={ errors.accountFirstName !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.firstName' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col sm={ 12 } className="mb-4">
        <Controller
          name="accountLastName"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountLastName || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.lastName' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                isInvalid={ errors.accountLastName !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.lastName' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col sm={ 12 } className="mb-4">
        <Controller
          name="accountEmail"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountEmail || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.email' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="email"
                isInvalid={ errors.accountEmail !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.email' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col sm={ 12 } className="mb-4">
        <FormGroup controlId="cardNumber">
          <Form.Label>
            { t( 'bookingFunnel.paymentData.cardNumber' ) }
          </Form.Label>
          <CardNumberElement options={ { showIcon: true } } />
        </FormGroup>
      </Col>
      <Col sm={ 12 } className="mb-4">
        <FormGroup controlId="expiry">
          <Form.Label>
            { t( 'bookingFunnel.paymentData.expiry' ) }
          </Form.Label>
          <CardExpiryElement />
        </FormGroup>
      </Col>
      <Col sm={ 12 } className="mb-2">
        <FormGroup controlId="cvc">
          <Form.Label>
            { t( 'bookingFunnel.paymentData.cvc' ) }
          </Form.Label>
          <CardCvcElement />
        </FormGroup>
      </Col>
      <Col sm={ 12 } className="mb-2">
        <p className="text-left f-12">{ t( 'bookingFunnel.funk.required' ) }</p>
      </Col>
    </Fragment>
  );
};
