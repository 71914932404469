import React, { Fragment } from 'react';
import { CompleteEmailVerification, ContainerFieldItem, ILeadData } from 'Services/widgets/interfaces';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { useWidgetService } from 'Services/widget';
import jsonpath from 'jsonpath';
import { renderClassNameBox } from '../../core-hooks';

export interface CoreEmailConfirmationProps {
  leadData: ILeadData;
  keyName: string;
  stepItem: ContainerFieldItem;
  enabledNextStep: ( isValidData: boolean ) => void;
}

export const CoreEmailConfirmation: React.FC<CoreEmailConfirmationProps> = ( {
  leadData, keyName, stepItem, enabledNextStep,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const service = useWidgetService();
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const fieldName = 'code';

  const { emailField, buttonText, errorText, successText } = stepItem;
  const email = emailField && emailField.name ? jsonpath.query( leadData, `$.${emailField.name}` )[0] : undefined;

  const [ isLoading, setLoading ] = React.useState<boolean>( false );
  const [ isValidCode, setValidCode ] = React.useState<boolean>( false );

  const { errors, control, setError, setValue, getValues } = useFormContext();

  const getFieldValue = ( ): string => {
    const formData = leadData && leadData[keyName] ? leadData[keyName] : undefined;
    if ( formData && formData[fieldName] ) {
      return formData[fieldName];
    }

    return '';
  };

  const renderClassName = ( ): string => {
    let classNameField = '';
    let readOnly = '';

    if ( stepItem?.className ) {
      classNameField = stepItem?.className;
    }

    if ( stepItem?.isReadOnly ) {
      readOnly = ' read-only';
    }

    return `${classNameField}${readOnly}`;
  };

  const promiseInitiateEmailVerification = React.useCallback( ( ) => {
    return service.initiateEmailVerification( { email } );
  }, [ email, service ] );

  const promiseCompleteEmailVerification = React.useCallback( ( code: string ) => {
    const payload: CompleteEmailVerification = {
      email,
      token: code,
    };

    return service.completeEmailVerification( payload );
  }, [ email, service ] );

  const onValidCode = React.useCallback( async ( ) => {
    setLoading( true );

    const code = getValues( fieldName );

    if ( !code && !email ) {
      setError( fieldName, { shouldFocus: true, message: errorText } );
      return;
    }

    try {
      const response = await promiseCompleteEmailVerification( code );

      if ( response && response.result ) {
        setValidCode( true );
        enabledNextStep( true );
      } else {
        setValue( fieldName, '', { shouldValidate: false } );
        setError( fieldName, { shouldFocus: true, message: errorText } );
        enabledNextStep( false );
      }

      setLoading( false );
    } catch( e ) {
      setError( fieldName, { shouldFocus: true, message: errorText } );
      setLoading( false );
      enabledNextStep( false );
    }
  }, [ email, enabledNextStep, errorText, getValues, promiseCompleteEmailVerification, setError, setValue ] );

  React.useEffect( () => {
    let isMounted = true;
    const emailVerification = async () => {
      try {
        if ( isMounted ) {
          const token = getValues( fieldName );

          if ( !token && email ) {
            const response = await promiseInitiateEmailVerification();

            if ( response && response.result ) {
              setLoading( false );
            }
          }

          if ( token && email ) {
            const response = await promiseCompleteEmailVerification( token );

            if ( response && response.result ) {
              setLoading( false );
              enabledNextStep( true );
            } else {
              setError( fieldName, { shouldFocus: true, message: errorText } );
              const initiateEmailVerification = await promiseInitiateEmailVerification();

              if ( initiateEmailVerification && !initiateEmailVerification.result ) {
                setLoading( false );
              }

              setLoading( false );
            }
          }
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          if ( isMounted ) {
            setLoading( false );
          }
        } else {
          throw e;
        }
      }
    };

    emailVerification();

    return () => {
      isMounted = false;
    };
  }, [ service, getValues, t, email, promiseInitiateEmailVerification,
    promiseCompleteEmailVerification, enabledNextStep, setError, errorText ] );

  return (
    <Fragment>
      { isLoading && loadingOnFullContainer }
      <Controller
        name={ fieldName }
        control={ control }
        rules={ { required: true } }
        defaultValue={ getFieldValue( ) }
        render={ ( props ) => (
          <FormGroup className={ renderClassNameBox( stepItem ) } controlId={ props.name }>
            { emailField && emailField.label && (
              <Form.Label id={ `${props.name}-label` }>
                { emailField.label }
              </Form.Label>
            ) }
            <Form.Control
              { ...props }
              type="text"
              className={ renderClassName() }
              readOnly={ stepItem?.isReadOnly }
              placeholder={ emailField && emailField.label ? emailField.label : '' }
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired',
                    { fieldLabel: emailField && emailField.label ? emailField.label : '' } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
            { successText && (
              <div className="valid-feedback" style={ { display: isValidCode ? 'block' : 'none' } }>
                { successText }
              </div>
            ) }
          </FormGroup>
        ) }
      />
      { buttonText && (
        <div className="justify-content-center align-items-center d-flex">
          <Button
            type="button"
            variant="primary"
            className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
            onClick={ onValidCode }
          >
            { buttonText }
          </Button>
        </div>
      ) }
    </Fragment>
  );
};
