import React from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import { IToken, parseTokenResponse, RefreshTokenRequest, TokenResponse } from 'Services/api/auth/base';
import { BaseApiError, BaseRequest, IApiService, IRequestDescriptor } from 'Services/base';
import { IProcessTrackingProperties, ITrackingProperties, WindowPostMessageProxy } from 'window-post-message-proxy';
import {
  IJQueryResponse,
  WidgetProps,
  LanguageType,
  CoreWidgetProps,
  FunnelConfig,
  SendEmailConfig,
  SelfServiceConfig,
} from './types';
import { useAppLogger } from 'Services/logger';
import { ApiContext } from 'Services/api-context';
import { IAppUserMeta } from 'App/types';
import { AppTranslationProvider } from 'App/components/utils/providers/AppTranslationProvider';
import { useLoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { AppAlertServiceProvider } from 'App/components/utils/alerts/AppAlertService';
import { AppStorageContext } from 'App/components/utils/providers/AppStorageCtx';

interface State {
  api: ProxyApi | null;
  language: LanguageType;
  authError: boolean;
}

const initialState: State = {
  api: null,
  language: 'de',
  authError: false,
};

export type configType = FunnelConfig | SendEmailConfig | SelfServiceConfig;

export const accountsStorageKey: string = 'APP_ACCOUNTS';

export const WidgetProxy: React.FC<WidgetProps | CoreWidgetProps> = ( props ) => {
  const { config } = props;
  const iFrameRef = React.useRef<HTMLIFrameElement>( null );
  const logger = useAppLogger();
  const loadingIndicator = useLoadingSpinner();
  const users = useAppAccounts();
  const [ state, setState ] = React.useState<State>( {
    ...initialState,
    language: config.language,
  } );

  React.useEffect( () => {
    let _api: ProxyApi | null = null;
    const messageHandler = ( ev: MessageEvent ) => {
      if ( ev.data.proxyReady && iFrameRef.current !== null ) {
        // iframe is ready jQuery loaded and proxy script is initialized in iframe
        const accounts: IAppUserMeta[] = users;

        window.removeEventListener( 'message', messageHandler );

        const account = accounts.length > 0 ? accounts[0] : null;
        const iframeEl: HTMLIFrameElement = iFrameRef.current;
        const targetWindow = iframeEl.contentWindow!;

        _api = new ProxyApi( targetWindow, config, account );

        setState( { api: _api, language: config.language, authError: false } );
      }
    };

    window.addEventListener( 'message', messageHandler );

    return () => {

      window.removeEventListener( 'message', messageHandler );

      if ( _api !== null ) {
        _api.stop();
      }
    };
  }, [ logger, config, users ] );

  // During first render api is null, then iframe is mounted and api is created with contentWindow from iframe
  // Then after api is created state is changed and second render occurs which renders children with api context.
  const { api, language, authError } = state;

  return (
    <AppTranslationProvider language={ language }>
      { authError ? ( <div className="col-auto py-4"><p className="text-center">Please login to the app...</p></div> ) :
        api === null ? ( <div className="bf-loading col-auto py-4">{ loadingIndicator }</div> ) : (
          <AppAlertServiceProvider>
            <ApiContext.Provider value={ api }>
              { props.children }
            </ApiContext.Provider>
          </AppAlertServiceProvider>
        ) }
      <iframe
        ref={ iFrameRef }
        title="IFrameProxy"
        src={ `${config.appLocation}/proxy.html` }
        style={ { display: 'none' } }
      />
    </AppTranslationProvider>
  );
};

class ProxyApi implements IApiService {
  private accessTokenResolvingPromise: Promise<IToken> | false = false;
  private targetWindow: Window;
  private proxy: WindowPostMessageProxy;
  private account: IAppUserMeta | null;
  tenantCode: string | null;
  productCode: string | null;

  constructor( targetWindow: Window, config: configType, account: IAppUserMeta | null ) {
    this.targetWindow = targetWindow;
    this.account = account;
    this.tenantCode = config.tenantCode;
    if ( 'productCode' in config ) {
      this.productCode = config.productCode;
    } else {
      this.productCode = null;
    }

    const proxyTrackingProperties: IProcessTrackingProperties = {
      addTrackingProperties( message: any, trackingProperties: ITrackingProperties ) {
        message.proxyMeta = {
          messageId: trackingProperties.id,
          apiLocation: config.apiLocation,
        };
        return message;
      },
      getTrackingProperties( message ): ITrackingProperties {
        if ( message.proxyMeta !== undefined ) {
          return {
            id: message.proxyMeta['messageId'],
          };
        } else {
          return { id: 'missingMessageId' };
        }
      },
    };
    this.proxy = new WindowPostMessageProxy( {
      name: 'ProxyApi',
      processTrackingProperties: proxyTrackingProperties,
      suppressWarnings: true,
    } );
  }

  async request<T>( request: BaseRequest ): Promise<T> {
    const requestDescriptor: IRequestDescriptor = { ...request.descriptor };

    let bearerToken: string = '';
    let preRequestPromise: Promise<IRequestDescriptor>;

    if ( requestDescriptor.accessTokenRequired &&
      this.accessToken.current !== null && !this.isPublicApiUrl( requestDescriptor.url ) ) {
      const newToken = await this.accessTokenResolver(
        this.accessToken.current.tenantSlug,
        this.accessToken.current.userName,
      );

      bearerToken = newToken.value;
      this.updateAccessToken( newToken );
    } else {
      if ( this.tenantCode && this.isPublicApiUrl( requestDescriptor.url ) ) {
        bearerToken = this.tenantCode;
      } else {
        if ( requestDescriptor.accessTokenRequired && this.account !== null && this.accessToken.current === null ) {
          const newToken = await this.accessTokenResolver(
            this.account.tenantSlug,
            this.account.userName,
          );

          bearerToken = newToken.value;
          this.updateAccessToken( newToken );
        }
      }
    }

    if ( bearerToken ) {
      const headers = requestDescriptor.headers;

      // Modify request and arm it with token from resolver
      requestDescriptor.headers = {
        ...requestDescriptor.headers,
        Authorization: headers && headers.Authorization ? headers.Authorization : `Bearer ${bearerToken}`,
      };
    }

    preRequestPromise = Promise.resolve ( requestDescriptor );

    const requestPromise = preRequestPromise
      .then( ( requestMessage ) => {
        const message = JSON.parse( JSON.stringify( requestMessage ) );
        return this.proxy.postMessage( this.targetWindow, message ) as Promise<IJQueryResponse<T>>;
      } );

    const responsePromise = requestPromise
      .then( ( responseMessage ) => {
        return responseMessage.data;
      } )
      .catch( ( error ) => {
        const apiError = new BaseApiError( error, false );
        return Promise.reject( apiError );
      } );

    return responsePromise;
  }

  requestToken<T>( request: BaseRequest ): Promise<T> {
    const requestDescriptor: IRequestDescriptor = { ...request.descriptor };
    const preRequestPromise: Promise<IRequestDescriptor> = Promise.resolve ( requestDescriptor );

    const requestPromise = preRequestPromise
      .then( ( requestMessage ) => {
        const message = JSON.parse( JSON.stringify( requestMessage ) );
        return this.proxy.postMessage( this.targetWindow, message ) as Promise<IJQueryResponse<T>>;
      } );

    const responsePromise = requestPromise
      .then( ( responseMessage ) => {
        return responseMessage.data;
      } )
      .catch( ( error ) => {
        const apiError = new BaseApiError( error, false );
        return Promise.reject( apiError );
      } );

    return responsePromise;
  }

  requestS3<T>( request: BaseRequest ): Promise<T> {
    const requestDescriptor: IRequestDescriptor = { ...request.descriptor };
    const preRequestPromise: Promise<IRequestDescriptor> = Promise.resolve ( requestDescriptor );

    const requestPromise = preRequestPromise.then( ( requestMessage ) => {
      return axios.request( requestMessage );
    } );

    const responsePromise = requestPromise
      .then( ( responseMessage ) => {
        return responseMessage.data;
      } )
      .catch( ( error ) => {
        const apiError = new BaseApiError( error, false );
        return Promise.reject( apiError );
      } );

    return responsePromise;
  }

  // Below logic is for checking and resolving access token.

  /**
   * Token need to be refreshed if:
   * - 10 seconds left to expiration time of the current token
   * - we don't have a token.
   *
   * In this implementation and architecture it is impossible to refresh token if
   * this access token is null because we need at least userName to refresh token.
   */

  private accessTokenRefreshRequired(): boolean {
    if ( this.accessToken.current !== null ) {
      const now = dayjs().utc();
      const timeToInvalidateToken = this.accessToken.current.exp.diff ( now ) - 10 * 1000;
      // we can reuse existing token only if currect token is valid at least for 10 seconds.
      // if less than 10 seconds we will need to refresh token before calling any api request.
      return timeToInvalidateToken < 0;
    } else {
      return true;
    }
  }

  private accessTokenResolver( tenantSlug: string, userName: string ): Promise<IToken> {
    if ( this.accessTokenRefreshRequired() ) {
      if ( !this.accessTokenResolvingPromise ) {
        // create access token resolving and start resolving access token
        this.accessTokenResolvingPromise = new Promise<IToken>( ( resolve, reject ) => {
          this.requestToken<TokenResponse>( new RefreshTokenRequest( userName, tenantSlug ) )
            .then( ( token ) => {
              const auth = parseTokenResponse( token );
              // other waiting requests can reuse this (fresh) access token
              resolve( auth.token );
            } )
            .catch( ( reason ) => {
              reject( reason );
            } )
            .finally( () => {
              // Promise resolved or rejected we can set it to null
              this.accessTokenResolvingPromise = false;
            } );
        } );
      }
      return this.accessTokenResolvingPromise;
    } else {
      return Promise.resolve( this.accessToken.current as IToken );
    }
  }

  private updateAccessToken( token: IToken ): void {
    this.accessToken.current = token;
  }

  private isPublicApiUrl( urlApi: string | undefined ): boolean {
    if( urlApi ) {
      const splitUrl = urlApi.split( '/' );
      if( splitUrl.length >= 1 && splitUrl[1] === 'publicapi' ) {
        return true;
      }
    }
    return false;
  }

  accessToken: React.MutableRefObject<IToken | null> = { current: null };

  stop() {
    this.proxy.stop();
  }
}

export const useAppAccounts = (): IAppUserMeta[] => {
  const storage = React.useContext( AppStorageContext );
  const accounts = React.useMemo( () => {
    const result = storage.get<IAppUserMeta[]>( accountsStorageKey );
    return result !== null ? result : [];
  }, [ storage ] );
  return accounts;
};
