import 'assets/core-main.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import CoreBookingFunnel from 'App/components/widgets/dynamic-core/CoreBookingFunnel';
import { FunnelConfig, WidgetConfig, BaseConfig, Configuration } from 'App/widget/types';
import { WidgetContainer } from 'App/widget/WidgetContainer';
import { defaultFactoryConfig } from './config';

class Funnel {
  readonly config: FunnelConfig;

  constructor( config: FunnelConfig ) {
    this.config = config;
  }

  init(): Promise<void> {
    const { productCode, productName, tenantCode, configuration } = this.config;
    const bfWidget: JSX.Element = (
      <WidgetContainer config={ this.config }>
        <CoreBookingFunnel
          productCode={ productCode }
          productName={ productName }
          tenantSlug={ tenantCode }
          configuration={ configuration }
        />
      </WidgetContainer>
    );
    const result = new Promise<void>( ( resolve ) => {
      ReactDOM.render(
        bfWidget,
        document.getElementById( this.config.containerId ),
        resolve,
      );
    } );

    return result;
  }
}

class WidgetFactory {
  private config: BaseConfig;
  constructor( tenantCode: string, config: Omit<Partial<BaseConfig>, 'tenantCode'> ) {
    this.config = {
      ...defaultFactoryConfig,
      ...config,
      tenantCode: tenantCode,
    };
  }

  funnel(
    productCode: string,
    productName: string,
    containerId: string,
    configuration: Configuration,
    config: Omit<Partial<WidgetConfig>,
    'containerId'|'productCode'|'productName'|'configuration'> = {},
  ): Promise<Funnel> {
    const funnelConfig: FunnelConfig = {
      ...this.config,
      ...config,
      containerId: containerId,
      productCode: productCode,
      productName: productName,
      configuration: configuration,
    };

    const funnel = new Funnel( funnelConfig );

    return funnel.init().then( () => funnel );
  }
}

const init = ( tenantCode: string, config: Partial<BaseConfig> = {} ): WidgetFactory => {
  const factory = new WidgetFactory( tenantCode, config );
  return factory;
};

export default init;
