import React, { Fragment } from 'react';
import { IBFProductVariable } from 'Services/widgets/interfaces';

export interface DescriptionFieldProps {
  variable: IBFProductVariable;
}

export const DescriptionField: React.FC<DescriptionFieldProps> = ( { variable } ) => {

  return (
    <Fragment>
      <div className="bf-description" dangerouslySetInnerHTML={ { __html: `${variable.bfTooltip}` } }></div>
    </Fragment>
  );
};
