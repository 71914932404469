export interface GenderOption {
  value: string;
}

const GenderOptions: GenderOption[] = [
  { value: 'male' },
  { value: 'female' },
  { value: 'unspecified' },
];

export default GenderOptions;
