import React, { Fragment } from 'react';
import addButtonIconUrl from 'assets/bf-images/funk/add_button.svg';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ContainerFieldItem,
  IBFInsuredObjectItem,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { cloneDeep } from 'lodash';
import { useWidgetService } from 'Services/widget';
import { addFullAddressField, fullAddressField, keyMultiInsuredObjects } from '../core-hooks';
import { AddressElement } from './AddressElement';

export interface AddressPanelProps {
  stepItem?: ContainerFieldItem;
  formData: ITariffDataStep;
  insuredObjects: IBFInsuredObjectItem[];
  multiInsuredObjects?: IBFInsuredObjectItem[];
}

export interface FieldItem {
  name: string;
}

export const AddressPanel: React.FC<AddressPanelProps> = ( {
  stepItem,
  formData,
  insuredObjects,
  multiInsuredObjects,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const service = useWidgetService();
  const nameCloneObject = stepItem && stepItem.insuredObjectName ? stepItem.insuredObjectName : '';
  const configObject = stepItem && stepItem.config ? stepItem.config : '';
  const foundCloneObject = insuredObjects.find( ( object ) => object.name === nameCloneObject );
  const minInsuredObjectsCount = foundCloneObject?.minInsuredObjectsCount
    ? foundCloneObject?.minInsuredObjectsCount : 0;
  const maxInsuredObjectsCount = foundCloneObject?.maxInsuredObjectsCount
    ? foundCloneObject?.maxInsuredObjectsCount : 0;
  const prepareFields = addFullAddressField( multiInsuredObjects! );
  const [ addressFields, setAddressFields ] = React.useState<IBFInsuredObjectItem[]>( prepareFields || [] );
  const fieldName = 'objectsCount';
  const objectsCount: number = addressFields.length;
  const isFirstRender = React.useRef<boolean | null>( null );

  const { control, errors, setValue } = useFormContext();

  const getDefaultValue = ( ): boolean => {
    return true;
  };

  const isValidateValue = ( value: string ): string | boolean => {
    const converValue = value ? Number( value ) : 0;

    if ( converValue < minInsuredObjectsCount ) {
      const errMessage = t( 'bookingFunnel.numberMin',
        { fieldLabel: configObject['addMessageTitle'], minValue: minInsuredObjectsCount },
      );
      return errMessage;
    }

    return true;
  };

  const addAddressField = React.useCallback( async (): Promise<void> => {
    if ( !foundCloneObject ) {
      return;
    }

    const indexObject: number = addressFields.length + 1;
    let nameObject = `${foundCloneObject?.name}${indexObject}`;

    const foundItem = addressFields.find( ( item ) => item.name === nameObject );

    if ( foundItem ) {
      nameObject = `${foundCloneObject?.name}${indexObject+2}`;
    }

    if ( !foundCloneObject.productFields ) {
      return;
    }

    const newObject = {
      ...foundCloneObject,
      isMultiInsuredObject: false,
      minInsuredObjectsCount: null,
      maxInsuredObjectsCount: null,
      name: nameObject,
      productFields: [
        ...foundCloneObject?.productFields!,
        fullAddressField,
      ],
    };

    let newAddressList = cloneDeep( addressFields );

    newAddressList.push( newObject );

    await service.savedInfo( keyMultiInsuredObjects, newAddressList );

    setValue( fieldName, newAddressList.length, { shouldValidate: true } );

    setAddressFields( newAddressList );
  }, [ addressFields, foundCloneObject, service, setValue ] );

  const onDeleteAddress = React.useCallback ( async ( nameObject: string ): Promise<void> => {
    const addressList = cloneDeep( addressFields );
    const newAddressList = addressList.filter( ( item ) => item.name !== nameObject );

    await service.savedInfo( keyMultiInsuredObjects, newAddressList );

    setValue( fieldName, newAddressList.length, { shouldValidate: true } );
    setAddressFields( newAddressList );
  }, [ addressFields, service, setValue ] );

  if ( addressFields.length === 0 && isFirstRender.current === null ) {
    addAddressField();
    isFirstRender.current = true;
  }

  if ( !foundCloneObject ) {
    return <></>;
  }

  return (
    <Fragment>
      { addressFields.length > 0 && (
        <Fragment>
          { configObject['addressTitle'] && (
            <label className="form-label">
              <div className="d-inline-block"
                dangerouslySetInnerHTML={ { __html: `${configObject['addressTitle']}` } }
              >
              </div>
            </label>
          ) }
          { addressFields.map( ( item, idx ) => (
            <AddressElement
              key={ idx }
              addressItem={ item }
              formData={ formData }
              onDeleteAddress={ onDeleteAddress }
              stepItem={ stepItem }
            />
          ) ) }
        </Fragment>
      ) }
      { foundCloneObject && foundCloneObject.isMultiInsuredObject &&
      maxInsuredObjectsCount > addressFields.length && (
        <Controller
          name="addAddress"
          control={ control }
          rules={ {
            required: false,
          } }
          defaultValue={ getDefaultValue() }
          render={ ( props ) => (
            <FormGroup>
              <Form.Label id={ `${props.name}-label` }
                className={
                  `d-flex justify-content-space-evenly 
                  align-items-center${configObject['addMessageTitle'] ? ' tooltip-label' : '' }`
                }
              >
                { configObject['addMessageTitle'] && (
                  <div
                    className="d-inline-block"
                    dangerouslySetInnerHTML={ { __html: `${configObject['addMessageTitle'] }` } }
                  >
                  </div>
                )
                }
                <Button
                  type="button"
                  variant="primary"
                  className="add-address ml-2 mr-0 mb-0 bg-btn-primary"
                  onClick={ addAddressField }
                >
                  <img src={ addButtonIconUrl } alt="Add" />
                </Button>
              </Form.Label>
            </FormGroup>
          ) }
        />
      ) }
      { foundCloneObject && foundCloneObject.isMultiInsuredObject && (
        <Fragment>
          <Controller
            name="objectsCount"
            control={ control }
            rules={ {
              required: true,
              validate: ( value ) => {
                return isValidateValue( value );
              },
            } }
            defaultValue={ objectsCount }
            render={ ( props ) => (
              <FormGroup controlId={ props.name }>
                <Form.Control
                  { ...props }
                  isInvalid={ errors[props.name] !== undefined }
                  type="hidden"
                />
                <Form.Control.Feedback type="invalid">
                  { errors[props.name]?.message ? ( errors[props.name]?.message ) : (
                    <Fragment>
                      { t( 'base:forms.messages.fieldRequired',
                        { fieldLabel: configObject['addMessageTitle'] } ) }
                    </Fragment>
                  ) }
                </Form.Control.Feedback>
              </FormGroup>
            ) }
          />
        </Fragment>
      ) }
    </Fragment>
  );
};
