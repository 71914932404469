import React from 'react';

export interface AsteriskProps {
  classOptions?: string;
}

export const AsteriskRequiredField: React.FC<AsteriskProps> = ( { classOptions } ) => {
  const defaultClassName = 'd-inline-block ws-required ml-1';

  return (
    <span className={ classOptions ? classOptions : defaultClassName }>*</span>
  );
};
