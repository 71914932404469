import React, { Fragment } from 'react';
import contactIcon from 'assets/bf-images/contact_icon.svg';
import { TariffStepInfo } from './summary-views/TariffStepInfo';
import { ILeadData } from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from '../../booking-funnel/BookingFunnel';
import { PersonalDataStepCore } from '../steps/personal-sub-steps/RegistrationStep';
import { PersonalInfo } from './summary-views/PersonalInfo';

interface ViewSummaryPageProps {
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  policyStepNames: string[];
}

export const ViewSummaryPage: React.FC<ViewSummaryPageProps> = (
  {
    lead,
    productData,
    policyStepNames,
  },
) => {

  const slicePolicySteps = policyStepNames.slice( 1 );

  return (
    <Fragment>
      { slicePolicySteps && slicePolicySteps.map( ( nameStep, idx ) => (
        <TariffStepInfo
          key={ nameStep }
          index={ idx + 1 }
          isOpened={ idx === 0 ? true : false }
          isRenderedProduct={ idx === 0 ? true : false }
          nameStep={ nameStep }
          productData={ productData }
          lead={ lead! }
        />
      ) ) }
      <PersonalInfo
        isOpened={ false }
        index={ slicePolicySteps.length + 1 }
        iconCard={ contactIcon }
        personalData={ lead.personalData as PersonalDataStepCore }
      />
    </Fragment>
  );
};
