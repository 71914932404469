import React, { Fragment } from 'react';
import { Col, Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ContainerFieldItem,
  IBFInsuredObjectItem,
  ILeadData,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { useWidgetService } from 'Services/widget';
import { prepareProductFields, keyMultiInsuredObjects } from '../core-hooks';
import { ICFFactorType } from '../../factor-service';
import { FieldPanel } from './FieldPanel';

export interface AutoRenderObjectPanelProps {
  lead: ILeadData;
  stepItem?: ContainerFieldItem;
  factors: ICFFactorType[];
  formData: ITariffDataStep;
  insuredObjects: IBFInsuredObjectItem[];
  multiInsuredObjects?: IBFInsuredObjectItem[];
}

export interface FieldItem {
  name: string;
}

export const AutoRenderObjectPanel: React.FC<AutoRenderObjectPanelProps> = ( {
  lead,
  stepItem,
  factors,
  formData,
  insuredObjects,
  multiInsuredObjects,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const service = useWidgetService();
  const nameCloneObject = stepItem && stepItem.insuredObjectName ? stepItem.insuredObjectName : '';
  const configObject = stepItem && stepItem.config ? stepItem.config : '';
  const foundCloneObject = insuredObjects.find( ( object ) => object.name === nameCloneObject );
  const minInsuredObjectsCount = foundCloneObject?.minInsuredObjectsCount
    ? foundCloneObject?.minInsuredObjectsCount : 0;
  const [ objectData, setObjectData ] = React.useState<IBFInsuredObjectItem[]>( multiInsuredObjects || [] );
  const fieldName = 'objectsCount';
  const objectsCount: number = objectData.length;
  const isFirstRender = React.useRef<boolean | null>( null );

  const { control, errors, setValue } = useFormContext();

  const isValidateValue = ( value: string ): string | boolean => {
    const converValue = value ? Number( value ) : 0;

    if ( converValue < minInsuredObjectsCount ) {
      const errMessage = t( 'bookingFunnel.numberMin',
        { fieldLabel: configObject['addMessageTitle'], minValue: minInsuredObjectsCount },
      );
      return errMessage;
    }

    return true;
  };

  const getCountObject = ( ): number => {
    let fieldCountObject = 0;

    if ( configObject && configObject['linkToFieldCount'] ) {
      const linkToFieldCount = configObject['linkToFieldCount'] as string;
      const splitFieldCount = linkToFieldCount.split( '.' );

      if ( splitFieldCount.length >= 2 ) {
        const fountVal = lead[splitFieldCount[0]] ? lead[splitFieldCount[0]][splitFieldCount[1]] : undefined;

        if ( fountVal ) {
          fieldCountObject = Number( fountVal );
        }
      }
    }

    return fieldCountObject;
  };

  const countRenderObject = getCountObject();

  const renderObjects = React.useCallback( async ( countObject: number ): Promise<void> => {
    if ( countObject <= 0 ) {
      return;
    }

    const newObjectList: IBFInsuredObjectItem[] = [];

    for ( let i = 0; i < countObject; i++ ) {
      const indexObject: number = i + 1;
      const nameObject = `${foundCloneObject?.name}${indexObject}`;
      const productFields = prepareProductFields( foundCloneObject?.productFields!, nameObject );

      const newObject = {
        ...foundCloneObject!,
        isMultiInsuredObject: false,
        minInsuredObjectsCount: null,
        maxInsuredObjectsCount: null,
        name: nameObject,
        groupName: nameObject,
        productFields: [
          ...productFields,
        ],
      };

      newObjectList.push( newObject );
    }

    await service.savedInfo( keyMultiInsuredObjects, newObjectList );

    setValue( fieldName, newObjectList.length, { shouldValidate: true } );

    setObjectData( newObjectList );
  }, [ foundCloneObject, service, setValue ] );

  if ( countRenderObject !== objectData.length && isFirstRender.current === null ) {
    renderObjects( countRenderObject );
    isFirstRender.current = true;
  }

  return (
    <Fragment>
      { configObject['objectTitle'] && (
        <label className="form-label">
          <div className="d-inline-block"
            dangerouslySetInnerHTML={ { __html: `${configObject['objectTitle']}` } }
          >
          </div>
        </label>
      ) }
      { configObject['objectDescription'] && (
        <span className="gm-info f-12 mt-1">
          { configObject['objectDescription'] }
        </span>
      ) }
      { objectData.length > 0 && objectData.map( ( item, idx ) => (
        <Col key={ idx } sm={ 12 } className="p-0 mb-5">
          { configObject['groupTitle'] && (
            <div className={ `mb-2 ${ stepItem && stepItem.classNameBox ? stepItem.classNameBox : ''}` }>
              <span className="f-16 text-current-color">
                { `${configObject['groupTitle']} (${idx + 1})` }
              </span>
            </div>
          ) }
          { item && item.productFields && item.productFields.map( ( variable, itemIdx ) => (
            <Col key={ itemIdx } sm={ 12 } className="p-0 mb-4">
              <FieldPanel
                formData={ formData }
                factors={ factors }
                insuredObjects={ insuredObjects }
                variable={ variable }
                stepItem={ stepItem }
                multiInsuredObjects={ multiInsuredObjects }
              />
            </Col>
          ) ) }
        </Col>
      ) ) }
      { foundCloneObject && foundCloneObject.isMultiInsuredObject && (
        <Fragment>
          <Controller
            name={ fieldName }
            control={ control }
            rules={ {
              required: true,
              validate: ( value ) => {
                return isValidateValue( value );
              },
            } }
            defaultValue={ objectsCount }
            render={ ( props ) => (
              <FormGroup controlId={ props.name }>
                <Form.Control
                  { ...props }
                  isInvalid={ errors[props.name] !== undefined }
                  type="hidden"
                />
                <Form.Control.Feedback type="invalid">
                  { errors[props.name]?.message ? ( errors[props.name]?.message ) : (
                    <Fragment>
                      { t( 'base:forms.messages.fieldRequired',
                        { fieldLabel: configObject['addMessageTitle'] } ) }
                    </Fragment>
                  ) }
                </Form.Control.Feedback>
              </FormGroup>
            ) }
          />
        </Fragment>
      ) }
    </Fragment>
  );
};
